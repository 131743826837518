import React, { useState } from "react";
import { Controller, useForm, UseFormReturn } from "react-hook-form";
import { StyleSheet, ViewProps } from "react-native";
import { EvaProp, Input as InputBase, Text } from "@ui-kitten/components";
import styled from "rn-css";
import useEffectOnce from "react-use/lib/useEffectOnce";
import useListener from "../../hooks/useListener";
import { FlexCol } from "../../styles/containers";
import Button from "../Button";
import HorizontalRule from "../HorizontalRule";

const styles = StyleSheet.create({
  inputText: {
    fontFamily: "Jost_500Medium",
    fontWeight: "500",
    fontSize: 15,
    lineHeight: 22,
    color: "#27a563",
  },
});

const FieldSet = styled(FlexCol)`
  gap: 8px;
`;

const Input = styled(InputBase)`
  border-radius: 999px;
`;

const InputLabel = styled(Text)`
  font-family: Jost_400Regular;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.02em;
  color: #27a563;
`;

const InputAccessory = styled(InputLabel)`
  font-family: Jost_500Medium;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  padding: 0px 12px;
`;

const Container = styled(FlexCol)`
  gap: 24px;
`;

export interface DonateFormData {
  amount: string;
}

export interface DonateFormCleanedData {
  amount: number;
}

export interface DonateFormProps extends ViewProps {
  eva?: EvaProp;
  formMethods?: UseFormReturn<DonateFormData>;
  onChange?: (data?: DonateFormCleanedData) => void;
  onSubmit?: (data?: DonateFormCleanedData) => void;
}

export const defaultValues: DonateFormData = {
  amount: "1.00",
};

export const getCleanedData = (data: DonateFormData): DonateFormCleanedData => {
  const cleanedData = {
    amount: Number.parseFloat(data.amount),
  };

  return cleanedData;
};

const DonateForm: React.FC<DonateFormProps> = ({
  eva,
  formMethods,
  onChange,
  onSubmit,
  ...props
}) => {
  const builtinFormMethods = useForm({ defaultValues });
  const {
    control,
    handleSubmit,
    formState: { errors },
    setError,
    setValue,
    getValues,
  } = {
    ...builtinFormMethods,
    ...formMethods,
  };
  const [isLoading, setIsLoading] = useState(false);

  const onFormDataValidate = useListener(async (formData: DonateFormData) => {
    setIsLoading(true);
    await onSubmit?.(getCleanedData(formData));
    setIsLoading(false);
  });

  const onAmountChange = useListener((text: string) => {
    setValue("amount", text, { shouldValidate: true });
    onChange?.(getCleanedData(getValues()));
  });

  useEffectOnce(() => {
    onChange?.(getCleanedData(defaultValues));
  });

  const amountInputRenderer = useListener(({ onBlur, value }: any) => (
    <FieldSet>
      <InputLabel>Donation Amount</InputLabel>
      <Input
        accessoryRight={() => <InputAccessory>cUSD</InputAccessory>}
        caption={errors.amount?.message}
        disabled={isLoading}
        onBlur={onBlur}
        onChangeText={onAmountChange}
        placeholder="Amount*"
        size="large"
        status={errors.amount ? "danger" : "basic"}
        value={value}
        textStyle={styles.inputText}
        defaultValue={defaultValues.amount}
      />
    </FieldSet>
  ));

  return (
    <Container {...props}>
      <Controller
        name="amount"
        control={control}
        rules={{
          required: true,
          min: { value: 0.1, message: "Amount must be at least 0.1 cUSD." },
          pattern: {
            value: /^(?:0|[1-9][0-9]*)(?:[.][0-9]+)?$/,
            message: "You must enter a valid amount",
          },
        }}
        render={amountInputRenderer}
      />
      {onSubmit && (
        <>
          <HorizontalRule />
          <Button
            disabled={isLoading || !!errors.amount}
            type="greenPrimary"
            text="Continue"
            onPress={handleSubmit(onFormDataValidate)}
          />
        </>
      )}
    </Container>
  );
};

export default DonateForm;

import { registerRootStore } from "mobx-keystone";
import { persist } from "mobx-keystone-persist";
import React from "react";
import RootStore from "./RootStore";
import CampaignStore from "./CampaignStore";
import api from "../api";
import storage from "../services/storage";
import CategoryStore from "./CategoryStore";
import UserStore from "./UserStore";

const StoreContext = React.createContext<RootStore>({} as RootStore);

const useStore = () => React.useContext(StoreContext);
const { Provider: StoreProvider } = StoreContext;

const PERSIST_KEY_NAME = "DoniRootStore";

async function ensureCanPersistStore() {
  try {
    await storage.getItem(PERSIST_KEY_NAME);
  } catch (e) {
    console.log(`Could not set ${PERSIST_KEY_NAME}, trying to delete`);
    try {
      await storage.removeItem(PERSIST_KEY_NAME);
    } catch {
      console.log(`Could not read ${PERSIST_KEY_NAME}, trying to reset`);
      await storage.setItem(PERSIST_KEY_NAME, "{}");
    }
  }
}

async function createRootStore() {
  const rootStore = new RootStore({
    campaigns: new CampaignStore({}),
    categories: new CategoryStore({}),
    users: new UserStore({}),
  });

  await ensureCanPersistStore();
  await persist(PERSIST_KEY_NAME, rootStore, {
    storage,
    jsonify: true,
    whitelist: ["address", "token", "phoneNumber"],
  });

  api.config.token = rootStore.token || "";

  registerRootStore(rootStore);

  return rootStore;
}

export { RootStore, StoreContext, StoreProvider, createRootStore, useStore };

import React from "react";
import { Platform, StyleProp, StyleSheet, TextStyle, View, ViewStyle } from "react-native";
import { Text } from "@ui-kitten/components";
import MarkdownBase, {
  MarkdownProps as MarkdownPropsBase,
  hasParents,
} from "react-native-markdown-display";
import useWindowSize from "../hooks/useWindowSize";

/*
 *  Custom rules for generating list item indices (only applies to ordered lists)
 *  The last rule specified in this array will be applied to succeeding (deeper) list levels
 */
const LIST_ITEM_INDEX_RULES = [
  /*
   *  Level 1 lists: Numeric Lists
   *  <index> is 1-based
   */
  (index: number): string => `${index}`,
  /*
   *  Level 2 lists: Lower Latin Alphabetic Lists
   */
  (index: number): string => {
    return index < 1 || index > 26 ? ".." : String.fromCharCode("a".charCodeAt(0) + (index - 1));
  },
  /*
   *  Level 3 lists: Parent list item alphabetic index, appended by numeric indices
   */
  (index: number, parents: any[] = []): string => {
    const parentItem = parents.find((el: any) => el.type === "list_item");
    const parentItemIndex = LIST_ITEM_INDEX_RULES[1](parentItem.index + 1);

    return `${parentItemIndex}.${index}`;
  },
];

const rules = {
  // Template copied from https://github.com/iamacup/react-native-markdown-display/blob/master/src/lib/renderRules.js
  /* eslint-disable no-underscore-dangle */
  list_item: (
    node: any,
    children: React.ReactNode,
    parents: any[],
    styles: { [index: string]: StyleProp<ViewStyle | TextStyle> },
    inheritedStyles: { [index: string]: StyleProp<ViewStyle | TextStyle> } = {}
  ) => {
    const refStyle = {
      ...inheritedStyles,
      ...StyleSheet.flatten(styles.list_item),
    };

    if (hasParents(parents, "bullet_list")) {
      return (
        <View key={node.key} style={styles._VIEW_SAFE_list_item}>
          <Text style={[refStyle, styles.bullet_list_icon]} accessible={false}>
            {Platform.select({
              android: "\u2022",
              ios: "\u00B7",
              default: "\u2022",
            })}
          </Text>
          <View style={styles._VIEW_SAFE_bullet_list_content}>{children}</View>
        </View>
      );
    }

    if (hasParents(parents, "ordered_list")) {
      let listLevel = -1;
      parents.forEach((el: any) => {
        if (el.type === "ordered_list") {
          listLevel += 1;
        }
      });

      const orderedListIndex = parents.findIndex((el: any) => el.type === "ordered_list");
      const orderedList = parents[orderedListIndex];
      const listItemIndex =
        orderedList.attributes && orderedList.attributes.start
          ? orderedList.attributes.start + node.index
          : node.index + 1;
      const indexRules = LIST_ITEM_INDEX_RULES;
      const itemIndex = indexRules ? indexRules[listLevel](listItemIndex, parents) : listItemIndex;

      return (
        <View key={node.key} style={styles._VIEW_SAFE_list_item}>
          <Text style={[refStyle, styles.ordered_list_icon]}>
            {itemIndex}
            {node.markup}
          </Text>
          <View style={styles._VIEW_SAFE_ordered_list_content}>{children}</View>
        </View>
      );
    }

    return (
      <View key={node.key} style={styles._VIEW_SAFE_list_item}>
        {children}
      </View>
    );
  },
};

const styles = StyleSheet.create({
  body: {
    flexShrink: 1,
  },
  heading1: {
    fontFamily: "Jost_500Medium",
    fontWeight: "500",
    fontSize: 48,
    lineHeight: 69,
  },
  heading2: {
    fontFamily: "Jost_500Medium",
    fontWeight: "500",
    fontSize: 24,
    lineHeight: 35,
  },
  heading3: {},
  heading4: {},
  heading5: {},
  heading6: {},
  hr: {},
  strong: {
    fontFamily: "Jost_400Regular",
    fontWeight: "400",
    color: "#1d7c4a",
  },
  em: {
    fontStyle: "italic",
  },
  s: {},
  blockquote: {},
  bullet_list: {},
  ordered_list: {
    marginTop: 16,
    gap: 16,
  },
  list_item: {
    fontFamily: "Jost_400Regular",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: 16,
    lineHeight: 23,
    color: "#2e3338",
  },
  code_inline: {},
  code_block: {},
  fence: {},
  table: {},
  thead: {},
  tbody: {},
  th: {},
  tr: {},
  td: {},
  link: {
    fontFamily: "Jost_600SemiBold",
    fontWeight: "600",
    color: "#3488ec",
  },
  blocklink: {},
  image: {},
  text: {},
  textgroup: {},
  paragraph: {
    fontFamily: "Jost_400Regular",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: 16,
    lineHeight: 23,
    color: "#2e3338",
  },
  hardbreak: {},
  softbreak: {},
  pre: {},
  inline: {},
  span: {},
});

const mobileStyles = StyleSheet.create({
  body: {
    flexShrink: 1,
  },
  heading1: {
    fontFamily: "Jost_500Medium",
    fontWeight: "500",
    fontSize: 24,
    lineHeight: 35,
  },
  heading2: {
    fontFamily: "Jost_500Medium",
    fontWeight: "500",
    fontSize: 18,
    lineHeight: 26,
  },
  heading3: {},
  heading4: {},
  heading5: {},
  heading6: {},
  hr: {},
  strong: {
    fontFamily: "Jost_400Regular",
    fontWeight: "400",
    color: "#1d7c4a",
  },
  em: {
    fontStyle: "italic",
  },
  s: {},
  blockquote: {},
  bullet_list: {},
  ordered_list: {
    marginTop: 16,
    gap: 16,
  },
  list_item: {
    fontFamily: "Jost_400Regular",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: 16,
    lineHeight: 23,
    color: "#2e3338",
  },
  code_inline: {},
  code_block: {},
  fence: {},
  table: {},
  thead: {},
  tbody: {},
  th: {},
  tr: {},
  td: {},
  link: {
    fontFamily: "Jost_600SemiBold",
    fontWeight: "600",
    color: "#3488ec",
  },
  blocklink: {},
  image: {},
  text: {},
  textgroup: {},
  paragraph: {
    fontFamily: "Jost_400Regular",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: 16,
    lineHeight: 23,
    color: "#2e3338",
  },
  hardbreak: {},
  softbreak: {},
  pre: {},
  inline: {},
  span: {},
});

export interface MarkdownProps extends Omit<MarkdownPropsBase, "style" | "mergeStyle"> {}

const Markdown: React.FC<MarkdownProps> = ({ children, ...props }) => {
  const { isMobile } = useWindowSize();

  return (
    <MarkdownBase {...props} rules={rules} style={isMobile ? mobileStyles : styles} mergeStyle>
      {children}
    </MarkdownBase>
  );
};

export default Markdown;

import { EvaProp, Icon, Input, Layout, useTheme, withStyles } from "@ui-kitten/components";
import React, { useCallback, useState } from "react";
import { StyleProp, TextStyle, TouchableWithoutFeedback, ViewStyle } from "react-native";

export interface SearchBarProps {
  eva?: EvaProp;
  clearOnBlur?: boolean;
  defaultValue?: string;
  onClear: () => void;
  onSubmit: (arg0: string) => void;
  placeholder?: string;
  showClearButton?: boolean;
  showIcon?: boolean;
  style?: StyleProp<ViewStyle>;
  containerStyle?: StyleProp<ViewStyle>;
  textStyle?: StyleProp<TextStyle>;
}

const SearchBar: React.FC<SearchBarProps> = ({
  eva,
  clearOnBlur = true,
  defaultValue,
  onClear,
  onSubmit,
  placeholder,
  showIcon = true,
  showClearButton = true,
  style,
  containerStyle,
  textStyle,
}) => {
  const [searchPattern, setSearchPattern] = useState<string>(defaultValue ?? "");

  const theme = useTheme();

  const handleOnBlur = useCallback(() => {
    if (clearOnBlur) {
      setSearchPattern("");
      onClear();
    }
  }, [clearOnBlur, onClear, setSearchPattern]);

  const handleOnChangeText = useCallback(
    (text: string) => {
      setSearchPattern(text);
    },
    [setSearchPattern]
  );

  const handleOnClearText = useCallback(() => {
    setSearchPattern("");
    onClear();
  }, [onClear, setSearchPattern]);

  const handleOnSubmitEditing = useCallback(
    ({ nativeEvent }: any) => {
      const { text }: { text: string } = nativeEvent;
      setSearchPattern(text);
      onSubmit(text);
    },
    [onSubmit, setSearchPattern]
  );

  const renderClearButton = useCallback(
    (props) => {
      return (
        <TouchableWithoutFeedback onPress={handleOnClearText}>
          <Icon {...props} name="close-outline" />
        </TouchableWithoutFeedback>
      );
    },
    [handleOnClearText]
  );

  const renderSearchIcon = useCallback(() => {
    return <Icon fill={theme!["text-hint-color"]} name="search" style={eva?.style?.icon} />;
  }, [eva, theme]);

  const renderSearchInput = useCallback(() => {
    return (
      <Input
        accessoryLeft={showIcon ? renderSearchIcon : undefined}
        accessoryRight={searchPattern !== "" && showClearButton ? renderClearButton : undefined}
        autoFocus
        defaultValue={defaultValue}
        onBlur={handleOnBlur}
        onChangeText={handleOnChangeText}
        onSubmitEditing={handleOnSubmitEditing}
        placeholder={placeholder || "Search for Campaigns in Doni"}
        style={[eva?.style?.searchBar, style]}
        textStyle={[eva?.style?.searchBarText, textStyle]}
        value={searchPattern}
      />
    );
  }, [
    defaultValue,
    eva,
    handleOnBlur,
    handleOnChangeText,
    handleOnSubmitEditing,
    placeholder,
    renderClearButton,
    renderSearchIcon,
    searchPattern,
    showClearButton,
    showIcon,
    style,
    textStyle,
  ]);

  return <Layout style={[eva?.style?.navigation, containerStyle]}>{renderSearchInput()}</Layout>;
};

export default withStyles(SearchBar, () => ({
  button: {
    height: 32,
    width: 32,
  },
  icon: {
    height: 20,
    width: 20,
  },
  navigation: {
    height: 62,
  },
  searchBar: {
    flex: 1,
    flexDirection: "row",
    marginVertical: 10,
    marginHorizontal: 10,
  },
  searchBarText: {
    width: "100%",
  },
}));

import React from "react";
import { View, ViewProps } from "react-native";
import QRCode from "react-native-qrcode-svg";
import { LinearGradient } from "expo-linear-gradient";
import styled from "rn-css";

// Taken from the QR code at https://valoraapp.com/
const VALORA_DOWNLOAD_LINK = "https://app.adjust.com/rbp5nws";

const QRCodeContainer = styled(LinearGradient)`
  border-radius: 12px;
  padding: 10px;
  overflow: hidden;
`;
const QRCodeInnerContainer = styled(View)`
  border-radius: 10px;
  padding: 10px;
  overflow: hidden;
  background-color: white;
`;

interface Props extends ViewProps {}

const ValoraQRCode: React.FC<Props> = (props) => (
  <QRCodeContainer
    colors={["#35D07F", "#FBCC5C"]}
    locations={[0.128667, 1]}
    start={{ x: 1, y: 0 }}
    end={{ x: 0, y: 1 }}
  >
    <QRCodeInnerContainer>
      <QRCode size={190} value={VALORA_DOWNLOAD_LINK} />
    </QRCodeInnerContainer>
  </QRCodeContainer>
);

export default ValoraQRCode;

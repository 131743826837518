import { Text as TextBase } from "@ui-kitten/components";
import { observer } from "mobx-react-lite";
import React from "react";
import { View, ViewProps } from "react-native";
import styled from "styled-components/native";
import useWindowSize from "../hooks/useWindowSize";
import Category from "../store/models/Category";
import { FlexCol } from "../styles/containers";
import CategoryListItemBase from "./CategoryListItem";
import { responsive } from "../utils/responsive";

const CategoryListItem = styled(CategoryListItemBase)`
  width: 100%;
`;

const List = styled(View)`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(${responsive(158, 398)}px, 1fr));
  gap: ${responsive(12, 24)}px;
`;

const Text = styled(TextBase)`
  font-style: normal;
  font-feature-settings: "pnum" on, "lnum" on;
`;

const Header = styled(FlexCol)`
  gap: ${responsive(8, 13)}px;
`;

const Heading = styled(Text)`
  font-family: "Jost_500Medium";
  font-weight: 500;
  font-size: ${responsive(20, 24)}px;
  line-height: ${responsive(29, 35)}px;
  color: #2e3338;
`;

const SubHeading = styled(Text)`
  font-family: "Jost_400Regular";
  font-weight: 400;
  font-size: ${responsive(14, 18)}px;
  line-height: ${responsive(20, 26)}px;
  color: #2e3338;
`;

const Container = styled(FlexCol)`
  gap: ${responsive(24, 43)}px;
`;

interface Props extends ViewProps {
  items: Category[];
  onItemSelect: (category: Category) => void;
}

const CategoryList: React.FC<Props> = ({ items, onItemSelect, ...props }) => {
  const { isMobile } = useWindowSize();

  return (
    <Container isMobile={isMobile} {...props}>
      <Header isMobile={isMobile}>
        <Heading isMobile={isMobile}>Explore campaigns</Heading>
        <SubHeading isMobile={isMobile}>
          Select a category you want to browse campaigns from
        </SubHeading>
      </Header>
      <List isMobile={isMobile}>
        {items.map((item) => (
          <CategoryListItem key={item.id} category={item} onPress={onItemSelect} />
        ))}
      </List>
    </Container>
  );
};

export default observer(CategoryList);

import BigNumber from "bignumber.js";
import {
  model,
  Model,
  modelAction,
  modelFlow,
  prop,
  prop_dateString,
  prop_mapObject,
  _async,
  _await,
  getRootStore,
} from "mobx-keystone";
import api from "../api";
import { APICampaign, APICampaignInput } from "../api/campaigns";
import Campaign from "./models/Campaign";
import { APISearchParams } from "../api/base";

@model("doni/CampaignStore")
class CampaignStore extends Model({
  listItems: prop_mapObject(() => new Map<number, Campaign>()),
  preview: prop<Campaign | null>(() => null),
  updatedAt: prop_dateString<Date | null>(null),
}) {
  @modelAction
  saveListItem(item: APICampaign) {
    if (this.listItems.has(item.id)) {
      const listItem = this.listItems.get(item.id);
      listItem?.update(item);
      return listItem;
    }

    const listItem = new Campaign(item);
    this.listItems.set(item.id, listItem);
    this.updatedAt = new Date();
    return listItem;
  }

  @modelFlow
  list = _async(function* (
    this: CampaignStore,
    page?: number,
    limit?: number,
    searchParams?: APISearchParams
  ) {
    const { count, next, previous, results: resultsRaw } = yield* _await(
      api.campaigns.list(page, limit, searchParams)
    );
    if (previous === null) {
      this.listItems.clear();
    }
    const results = resultsRaw.map((item) => this.saveListItem(item));
    return { count, next: !!next, previous: !!previous, results };
  });

  @modelFlow
  listMine = _async(function* (
    this: CampaignStore,
    page?: number,
    limit?: number,
    searchParams?: APISearchParams
  ) {
    const { count, next, previous, results: resultsRaw } = yield* _await(
      api.campaigns.listMine(page, limit, searchParams)
    );
    if (previous === null) {
      this.listItems.clear();
    }
    const results = resultsRaw.map((item) => this.saveListItem(item));
    return { count, next: !!next, previous: !!previous, results };
  });

  @modelAction
  setPreview(previewData: APICampaignInput) {
    const data = previewData as any;

    data.goalWei = data.goal.multipliedBy(new BigNumber(10).pow(18)).toString();
    delete data.goal;

    const rootStore = getRootStore(this) as any;
    const category = rootStore && rootStore.categories.listItems.get(data.category);
    data.categoryName = category?.name || "";

    const item = new Campaign(previewData);
    this.preview = item;
    return item;
  }

  @modelFlow
  get = _async(function* (this: CampaignStore, campaignId: number) {
    try {
      const result = yield* _await(api.campaigns.detail(campaignId));
      this.saveListItem(result);
      return result;
    } catch (e) {
      throw new Error(e);
    }
  });

  @modelFlow
  put = _async(function* (this: CampaignStore, campaignId: number, apiData: APICampaignInput) {
    try {
      const result = yield* _await(api.campaigns.update(campaignId, apiData));
      this.saveListItem(result);
      return result;
    } catch (e) {
      throw new Error(e);
    }
  });

  @modelFlow
  approval = _async(function* (this: CampaignStore, campaignId: number, apiData: APICampaignInput) {
    try {
      const result = yield* _await(api.campaigns.approval(campaignId, apiData));
      this.saveListItem(result);
      return result;
    } catch (e) {
      throw new Error(e);
    }
  });

  @modelFlow
  listForReview = _async(function* (
    this: CampaignStore,
    page?: number,
    limit?: number,
    searchParams?: APISearchParams
  ) {
    const { count, next, previous, results: resultsRaw } = yield* _await(
      api.campaigns.listForReview(page, limit, searchParams)
    );
    if (previous === null) {
      this.listItems.clear();
    }
    const results = resultsRaw.map((item) => this.saveListItem(item));
    return { count, next: !!next, previous: !!previous, results };
  });
}

export default CampaignStore;

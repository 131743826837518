import React, { useCallback, useState } from "react";
import { Controller, Control, DeepMap, FieldError } from "react-hook-form";
import {
  Input,
  Text,
  withStyles,
  EvaProp,
  Datepicker,
  Icon,
  Select,
  SelectItem,
  IndexPath,
  Layout,
  CheckBox,
} from "@ui-kitten/components";
import { observer } from "mobx-react-lite";
import { TouchableOpacity } from "react-native-gesture-handler";
import useAsyncFn from "react-use/lib/useAsyncFn";
import { useFocusEffect } from "@react-navigation/native";
import { APICampaignInput } from "../../api/campaigns";
import { useStore } from "../../store";
import TermsConditionsModal from "../TermsConditionsModal";

interface Props {
  control: Control;
  errors: DeepMap<Record<string, any>, FieldError>;
  eva?: EvaProp;
  goalEditable?: boolean;
  nameEditable?: boolean;
  state: APICampaignInput;
  termsAccepted?: boolean;
  handleAcceptTerms?: (arg0: boolean) => void;
  showLabels?: boolean;
}

const defaultDate = new Date();

const CampaignStep1Form: React.FC<Props> = ({
  control,
  errors,
  eva,
  goalEditable = true,
  nameEditable = true,
  state,
  termsAccepted = false,
  handleAcceptTerms = () => {},
  showLabels = false,
}) => {
  const store = useStore();

  const [modalVisible, setModalVisible] = useState(false);

  const fetchCategoryList = async () => {
    return store.categories.list(undefined, undefined);
  };

  const [{ loading, value: categories }, fetch] = useAsyncFn(async () => {
    await fetchCategoryList();
    return Array.from(store.categories.listItems.values());
  }, []);

  useFocusEffect(
    useCallback(() => {
      fetch();
    }, [fetch])
  );

  const handleTermsConditions = () => {
    setModalVisible(true);
  };

  return (
    <Layout style={eva?.style?.wrapper}>
      <Controller
        name="goal"
        control={control}
        defaultValue={state.goal?.toFixed(2)}
        rules={{
          required: true,
          min: { value: 10, message: "Your goal must be at least 10 cUSD." },
          pattern: {
            value: /^[0-9]+(\.[0-9]+)*$/,
            message: "Your goal must be an amount.",
          },
        }}
        render={({ field: { onChange, onBlur, value } }) => (
          <Input
            accessoryRight={() => (
              <Text status={errors.goal ? "danger" : "primary"} style={eva?.style?.cUSD}>
                cUSD
              </Text>
            )}
            caption={errors.goal?.message}
            disabled={!goalEditable}
            label={() => (
              <>
                <Text
                  style={[
                    eva?.style?.goalLabel,
                    showLabels ? eva?.style?.labelBigText : eva?.style?.goalLabelNotEditable,
                  ]}
                >
                  {showLabels ? "Campaign Goal" : "What's your campaign goal?"}
                </Text>
                {goalEditable && (
                  <Text style={eva?.style?.goalWarningLabel} appearance="hint">
                    Once set, the goal amount cannot be changed.
                  </Text>
                )}
              </>
            )}
            onBlur={onBlur}
            onChangeText={(next) => onChange(next)}
            size="large"
            status={errors.goal ? "danger" : "primary"}
            style={[eva?.style?.input, eva?.style?.goal, !goalEditable && eva?.style?.disabled]}
            textStyle={[eva?.style?.inputInner, eva?.style?.goalInner]}
            value={value || ""}
          />
        )}
      />
      <Controller
        name="name"
        control={control}
        defaultValue={state.name}
        rules={{
          required: "This field is required.",
        }}
        render={({ field: { onChange, onBlur, value } }) => (
          <Input
            caption={errors.name?.message}
            onBlur={onBlur}
            onChangeText={(next) => onChange(next)}
            placeholder="Campaign name*"
            size="large"
            status={errors.name ? "danger" : "basic"}
            style={[eva?.style?.input, !nameEditable && eva?.style?.disabled]}
            value={value || ""}
            textStyle={eva?.style?.inputInner}
            disabled={!nameEditable}
            label={() => (
              <>{showLabels && <Text style={eva?.style?.labelText}>Campaign Name</Text>}</>
            )}
          />
        )}
      />
      {/* Purpose field is not included in Figma layout */}
      {/* <Controller
        name="purpose"
        control={control}
        defaultValue={state.purpose}
        rules={{
          required: "This field is required.",
        }}
        render={({ field: { onChange, onBlur, value } }) => (
          <Input
            appearance="rounded"
            caption={errors.purpose?.message}
            onBlur={onBlur}
            onChangeText={(next) => onChange(next)}
            placeholder="Who are you raising money for?*"
            size="large"
            status={errors.purpose ? "danger" : "basic"}
            style={[eva?.style?.input]}
            value={value || ""}
            textStyle={eva?.style?.inputInner}
          />
        )}
      /> */}
      <Controller
        name="location"
        control={control}
        defaultValue={state.location}
        render={({ field: { onChange, onBlur, value } }) => (
          <Input
            onBlur={onBlur}
            onChangeText={(next) => onChange(next)}
            placeholder="Location"
            size="large"
            style={[eva?.style?.input]}
            value={value || ""}
            textStyle={eva?.style?.inputInner}
            label={() => <>{showLabels && <Text style={eva?.style?.labelText}>Location</Text>}</>}
          />
        )}
      />
      <Controller
        name="endDate"
        control={control}
        defaultValue={state.endDate}
        render={({ field: { onChange, onBlur, value } }) => {
          return (
            <Datepicker
              accessoryRight={(iconProps) => <Icon {...iconProps} name="calendar" />}
              date={value ? new Date(value) : undefined}
              min={defaultDate}
              max={
                new Date(
                  defaultDate.getFullYear() + 100,
                  defaultDate.getMonth(),
                  defaultDate.getDate()
                )
              }
              onBlur={onBlur}
              onSelect={(nextDate) => {
                const offset = nextDate.getTimezoneOffset();
                const endDate = new Date(nextDate.getTime() - offset * 60 * 1000)
                  .toISOString()
                  .split("T")[0];
                onChange(endDate);
              }}
              placeholder="End date"
              placement="bottom end"
              size="large"
              style={[eva?.style?.input]}
              controlStyle={[eva?.style?.datePicker]}
              label={() => (
                <>{showLabels && <Text style={eva?.style?.labelText}>Target Date</Text>}</>
              )}
            />
          );
        }}
      />
      <Controller
        name="category"
        control={control}
        defaultValue={state.category || null}
        rules={{
          required: "This field is required.",
        }}
        render={({ field: { onChange, onBlur, value } }) => (
          <Select
            appearance="rounded"
            caption={errors.category?.message}
            onBlur={onBlur}
            onSelect={(index) => {
              if (categories) {
                onChange(categories[(index as IndexPath).row].id);
              }
            }}
            placeholder={loading ? "Loading categories..." : "Category"}
            size="large"
            status={errors.category ? "danger" : "basic"}
            style={[eva?.style?.input, eva?.style?.category]}
            value={categories?.find((category) => category.id === value)?.name || undefined}
            label={() => (
              <>{showLabels && <Text style={eva?.style?.labelText}>Campaign Category</Text>}</>
            )}
          >
            {categories?.map((category) => (
              <SelectItem
                key={category.id}
                title={(evaProps) => <Text {...evaProps}>{category.name || undefined}</Text>}
              />
            ))}
          </Select>
        )}
      />
      {goalEditable && (
        <>
          <Text style={eva?.style?.smallTitleText}>Terms and conditions</Text>
          <Layout style={eva?.style?.horizontalLayout}>
            <CheckBox
              checked={termsAccepted}
              onChange={(nextChecked: boolean) => {
                handleAcceptTerms(nextChecked);
              }}
            />
            <Text style={eva?.style?.subtitleText}>
              You have read and agree to our campaign{" "}
              <TouchableOpacity onPress={handleTermsConditions}>
                <Text style={eva?.style?.linkText}>Terms and Conditions</Text>
              </TouchableOpacity>
              .
            </Text>
          </Layout>
        </>
      )}

      <TermsConditionsModal visible={modalVisible} setVisible={setModalVisible} />
    </Layout>
  );
};

export default withStyles(observer(CampaignStep1Form), (theme) => ({
  wrapper: {
    flex: 1,
    rowGap: 12,
  },
  horizontalLayout: {
    flex: 1,
    flexDirection: "row",
    columnGap: 12,
  },
  input: {
    width: "100%",
    borderRadius: 100,
    paddingVertical: 0,
    paddingHorizontal: 0,
    backgroundColor: "#ffffff",
  },
  inputInner: {
    marginLeft: 16,
    marginRight: 16,
    marginTop: 6,
    marginBottom: 6,
    height: 22,
    fontSize: 15,
    fontFamily: "Jost_500Medium",
  },
  datePicker: {
    width: "100%",
    borderRadius: 100,
    paddingVertical: 0,
    paddingHorizontal: 16,
    backgroundColor: "#ffffff",
    height: 22,
    minHeight: 48,
    fontSize: 15,
    fontFamily: "Jost_500Medium",
  },
  smallTitleText: {
    fontFamily: "Jost_500Medium",
    fontWeight: "500",
    fontSize: 16,
    lineHeight: 23,
  },
  linkText: {
    fontFamily: "Jost_600SemiBold",
    fontWeight: "600",
    fontSize: 16,
    lineHeight: 23,
    color: "#3488EC",
  },
  goal: {
    marginBottom: 8,
  },
  goalLabel: {
    fontFamily: "Jost_500Medium",
    fontWeight: "500",
    fontSize: 22,
    lineHeight: 32,
  },
  goalLabelNotEditable: {},
  goalWarningLabel: {
    fontFamily: "Jost_400Regular",
    fontWeight: "400",
    marginBottom: 12,
  },
  goalText: {
    color: theme["color-primary-500"],
    fontSize: 22,
    lineHeight: 32,
  },
  goalInner: {
    color: "#27a563",
  },
  category: {
    marginBottom: 8,
  },
  cUSD: {
    color: "#27a563",
    fontFamily: "Jost_500Medium",
    fontWeight: "500",
    fontSize: 15,
    marginRight: 16,
  },
  labelText: {
    fontFamily: "Jost_400Regular",
    fontWeight: "400",
    fontSize: 14,
    lineHeight: 20,
    color: "#757f87",
    marginBottom: 8,
    marginLeft: 8,
  },
  labelBigText: {
    fontFamily: "Jost_500Medium",
    fontWeight: "500",
    fontSize: 18,
    lineHeight: 26,
    color: "#757f87",
    marginBottom: 12,
  },
  disabled: {
    opacity: 0.6,
  },
}));

import { Avatar, EvaProp, Icon, Input, Layout, Text, withStyles } from "@ui-kitten/components";
import * as ImagePicker from "expo-image-picker";
import { observer } from "mobx-react-lite";
import React, { useCallback, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { Pressable, View } from "react-native";
import styled from "rn-css";
import { useNavigation } from "@react-navigation/native";
import LocationPin from "../svgs/LocationPin";
import TimezoneAutocomplete from "./TimezoneAutocomplete";
import Button from "../Button";
import timezones from "../../utils/timezones";
import { handleValidateProfile } from "../../utils/handleValidateProfile";
import { useStore } from "../../store";
import CancelConfirmButtons from "../CancelConfirmButtons";

/* Based on ProfileForm, but modified for the Settings page */

interface Props {
  eva?: EvaProp;
  loading?: boolean;
  setLoading?: (value: React.SetStateAction<boolean>) => void;
  isTablet?: boolean;
}

const Divider = styled(View)`
  background-color: #edeeef;
  height: 1px;
  width: 100%;
`;

// TODO: Fix form not showing updated values when navigated to again without refreshing
const SettingsProfileForm: React.FC<Props> = ({
  eva,
  loading = false,
  setLoading = () => {},
  isTablet = false,
}) => {
  const methods = useFormContext();
  const formIsDirty = methods.formState.isDirty;
  const navigation = useNavigation();
  const store = useStore();
  const [canUseCamera, setCanUseCamera] = useState<boolean | null>(null);

  const handleSetImagePress = useCallback(async (onChange: (...event: any[]) => void) => {
    const { status } = await ImagePicker.requestMediaLibraryPermissionsAsync();
    setCanUseCamera(status === "granted");
    if (status === "granted") {
      const result = await ImagePicker.launchImageLibraryAsync({
        mediaTypes: ImagePicker.MediaTypeOptions.Images,
        allowsEditing: true,
        aspect: [1, 1],
        quality: 1,
      });
      if (!result.cancelled) {
        onChange(result.uri);
      }
    }
  }, []);

  const handleClearImagePress = useCallback((onChange: (...event: any[]) => void) => {
    onChange(null);
  }, []);

  /* Special handling for timezone picker */

  // This creates a "hidden" form parameter for the IANA timezone
  methods.register("timezone", { required: "Timezone is required." });

  // helper function: IANA -> UTC
  const ianaToUtc = (iana: string) => {
    const tz = Object.entries(timezones.all).filter(([, ianaCode]) =>
      ianaCode.toLowerCase().includes((iana || "").toLowerCase())
    );
    return tz.length > 0 ? tz[0][0] : "";
  };

  // helper function: UTC -> IANA
  const utcToIana = (utc: string) => {
    const tz = Object.entries(timezones.all).filter(([utcName]) =>
      utcName.toLowerCase().includes((utc || "").toLowerCase())
    );
    return tz.length > 0 ? tz[0][1] : "";
  };

  // state storing the timezone picker's current value
  const [utcTimezone, setUtcTimezone] = useState<string>(ianaToUtc(methods.getValues("timezone")));

  // handler function: picking/typing a new value
  const handleTimezoneChange = useCallback(
    (value: string) => {
      setUtcTimezone(value);
      methods.setValue("timezone", utcToIana(value));
    },
    [methods]
  );

  // handler function: blurring the picker
  const handleTimezoneBlur = useCallback(() => {
    setUtcTimezone(ianaToUtc(methods.getValues("timezone")));
  }, [methods]);
  /* End of timezone picker handling */

  const handleViewPress = useCallback(() => {
    navigation.navigate("FundraiserDetail", { id: store.me?.id });
  }, [navigation, store]);

  return (
    <Layout style={eva?.style?.container}>
      <Text style={eva?.style?.title}>Profile Info</Text>
      <View
        style={[
          eva?.style?.splitSections,
          isTablet ? eva?.style?.splitSectionsNarrow : eva?.style?.splitSectionsWide,
        ]}
      >
        <View style={[eva?.style?.leftSection, isTablet && eva?.style?.leftSectionNarrow]}>
          <Controller
            name="photo"
            control={methods.control}
            render={({ field: { onChange, value } }) => (
              <>
                <Pressable
                  style={[eva?.style?.avatarWrapper, isTablet && eva?.style?.avatarWrapperNarrow]}
                  disabled={canUseCamera === false || loading}
                  onPress={() => handleSetImagePress(onChange)}
                >
                  <Avatar
                    height={90}
                    source={{ uri: value, cache: "force-cache" }}
                    size="giant"
                    style={eva?.style?.avatar}
                  />
                  {value ? <View style={eva?.style?.avatarTint} /> : null}
                  {!value && (
                    <Icon
                      width={24}
                      height={24}
                      fill="#abadaf"
                      name="camera"
                      style={eva?.style?.avatarIcon}
                    />
                  )}
                </Pressable>

                <Button
                  appearance="ghost"
                  status="basic"
                  type="blueSecondary"
                  disabled={canUseCamera === false || loading}
                  onPress={() => handleSetImagePress(onChange)}
                  containerStyle={[eva?.style?.button, isTablet && eva?.style?.buttonNarrow]}
                  text={isTablet ? "Upload New Photo" : "Upload"}
                  accessoryRight={() => (
                    <Icon style={eva?.style?.icon} fill="#3488EC" name="upload-outline" />
                  )}
                  removePadding
                />

                {!isTablet && (
                  <Button
                    appearance="ghost"
                    status="basic"
                    type="greenTertiary"
                    disabled={loading}
                    onPress={handleViewPress}
                    containerStyle={eva?.style?.button}
                    text="View Profile"
                    removePadding
                  />
                )}

                {canUseCamera === false && (
                  <Text appearance="hint" status="danger" category="c1">
                    Permission to access media library was not granted.
                  </Text>
                )}
              </>
            )}
          />
        </View>
        <View style={eva?.style?.rightSection}>
          <Controller
            name="name"
            control={methods.control}
            rules={{
              required: "This field is required.",
            }}
            render={({ field: { onChange, onBlur, value } }) => (
              <Input
                caption={methods.formState.errors.name?.message}
                disabled={loading}
                onBlur={onBlur}
                onChangeText={(next) => onChange(next)}
                placeholder="Name*"
                size="large"
                status={methods.formState.errors.name ? "danger" : "basic"}
                style={eva?.style?.input}
                value={value || ""}
                textStyle={eva?.style?.inputInner}
                label={() => <Text style={eva?.style?.label}>First Name and Last Name</Text>}
              />
            )}
          />
          <Controller
            name="location"
            control={methods.control}
            render={({ field: { onChange, onBlur, value } }) => (
              <Input
                caption={methods.formState.errors.location?.message}
                disabled={loading}
                onBlur={onBlur}
                onChangeText={(next) => onChange(next)}
                placeholder="Location"
                size="large"
                status={methods.formState.errors.location ? "danger" : "basic"}
                style={eva?.style?.input}
                value={value || ""}
                accessoryRight={() => <LocationPin style={eva?.style?.locationPin} />}
                textStyle={eva?.style?.inputInner}
                label={() => <Text style={eva?.style?.label}>Location</Text>}
              />
            )}
          />
          <Layout style={eva?.style?.fullWidth}>
            <TimezoneAutocomplete
              caption={methods.formState.errors.timezone?.message}
              disabled={loading}
              onBlur={handleTimezoneBlur}
              onFormChange={handleTimezoneChange}
              placeholder="Timezone"
              size="large"
              status={methods.formState.errors.timezone ? "danger" : "basic"}
              style={eva?.style?.input}
              value={utcTimezone}
              textStyle={eva?.style?.inputInner}
              label={() => <Text style={eva?.style?.label}>Timezone</Text>}
            />
          </Layout>
          <Controller
            name="bio"
            control={methods.control}
            render={({ field: { onChange, onBlur, value } }) => (
              <Input
                textAlignVertical="top"
                caption={methods.formState.errors.bio?.message}
                disabled={loading}
                onBlur={onBlur}
                onChangeText={(next) => onChange(next)}
                multiline
                numberOfLines={7}
                placeholder="About Yourself"
                size="large"
                status={methods.formState.errors.bio ? "danger" : "basic"}
                style={eva?.style?.bioInput}
                textStyle={eva?.style?.bio}
                value={value || ""}
                label={() => <Text style={eva?.style?.label}>Bio</Text>}
              />
            )}
          />
          {isTablet && (
            <Button
              appearance="ghost"
              status="basic"
              type="greenTertiary"
              disabled={loading}
              onPress={handleViewPress}
              containerStyle={[
                eva?.style?.button,
                eva?.style?.buttonNarrow,
                eva?.style?.viewProfileButtonNarrow,
              ]}
              text="View Profile"
              removePadding
            />
          )}
        </View>
      </View>
      {!isTablet && <Divider />}
      <CancelConfirmButtons
        disableCancel={loading}
        disableConfirm={loading || !formIsDirty}
        onConfirmPress={methods.handleSubmit(
          handleValidateProfile(store, navigation, methods, loading, setLoading)
        )}
        cancelText="Cancel"
        confirmText="Save"
      />
    </Layout>
  );
};

export default withStyles(observer(SettingsProfileForm), (theme) => ({
  container: {
    display: "flex",
    alignItems: "center",
    rowGap: 24,
  },
  splitSections: {
    width: "100%",
    display: "flex",
  },
  splitSectionsNarrow: {
    flexDirection: "column",
    rowGap: 24,
  },
  splitSectionsWide: {
    flexDirection: "row",
    columnGap: 24,
  },
  leftSection: {
    width: 130,
    paddingTop: 24,
    rowGap: 12,
  },
  leftSectionNarrow: {
    marginHorizontal: "auto",
    paddingTop: 0,
    width: "100%",
  },
  rightSection: {
    flex: 1,
    rowGap: 8,
  },
  title: {
    fontFamily: "Jost_500Medium",
    fontWeight: "500",
    fontSize: 16,
    lineHeight: 23,
    color: "#2E3338",
    width: "100%",
    textAlign: "left",
  },
  label: {
    fontFamily: "Jost_400Regular",
    fontWeight: "400",
    fontSize: 14,
    lineHeight: 20,
    color: "#757f87",
    marginBottom: 8,
    marginLeft: 10,
  },
  avatarWrapper: {
    width: 90,
    height: 90,
    position: "relative",
    backgroundColor: theme["background-basic-color-3"],
    borderRadius: 45,
    marginLeft: "auto",
    marginRight: "auto",
  },
  avatarWrapperNarrow: {
    marginBottom: 12,
  },
  avatar: {
    width: 90,
    height: 90,
    position: "absolute",
  },
  avatarIcon: {
    position: "absolute",
    top: 32,
    left: 32,
  },
  avatarTint: {
    backgroundColor: "rgba(0, 0, 0, 0.35)",
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    borderRadius: 45,
  },
  button: {
    height: 40,
    flexShrink: 1,
    flexGrow: 0,
  },
  buttonNarrow: {
    width: 220,
    marginHorizontal: "auto",
  },
  viewProfileButtonNarrow: {
    marginTop: 12,
  },
  icon: {
    width: 20,
    height: 20,
  },
  input: {
    width: "100%",
    borderRadius: 100,
    paddingVertical: 0,
    paddingHorizontal: 0,
    backgroundColor: "#ffffff",
    marginBottom: 4,
  },
  inputInner: {
    marginLeft: 16,
    marginRight: 16,
    marginTop: 6,
    marginBottom: 6,
    height: 22,
    fontSize: 15,
    fontFamily: "Jost_500Medium",
  },
  bioInput: {
    width: "100%",
    borderRadius: 10,
    paddingVertical: 0,
    paddingHorizontal: 0,
    backgroundColor: "#ffffff",
  },
  bio: {
    textAlign: "auto",
    marginBottom: 9,
    marginTop: 9,
    marginLeft: 8,
    marginRight: 8,
    height: 150,
    fontSize: 16,
    fontFamily: "Jost_400Regular",
  },
  locationPin: {
    marginRight: 16,
    marginTop: 5,
    marginBottom: 5,
  },
  fullWidth: {
    width: "100%",
  },
}));

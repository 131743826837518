import React from "react";
import WalletConnectProvider from "@walletconnect/web3-provider";
import { ContractKit } from "@celo/contractkit";

export interface ConnectWalletObject {
  contractKit: ContractKit | null;
  error: string;
}

export interface WalletConnectParams {
  provider: WalletConnectProvider | null;
  kit: ContractKit | null;
  setProvider: React.Dispatch<WalletConnectProvider | null>;
  setKit: React.Dispatch<ContractKit | null>;
  connectWallet: (
    keepWalletOpen?: boolean,
    allowedAddress?: string | null
  ) => Promise<ConnectWalletObject>;
  disconnectWallet: () => Promise<void>;
  busy: boolean;
  closeQRCodeModal: () => void;
}

export default React.createContext({} as WalletConnectParams);

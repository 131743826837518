import React, { useState } from "react";
import { LayoutChangeEvent, View, ViewProps } from "react-native";
import styled from "styled-components/native";
import useListener from "../hooks/useListener";
import { propPlaceCenter } from "../styles/containers";

const Wrapper = styled(View)<{
  iMinWidth: number;
  iMaxWidth: number;
  containerWidth: number;
  cols: number;
  gap?: number;
}>`
  display: grid;
  grid-template-columns: repeat(
    ${({ cols: columns }) => columns},
    minmax(
      ${({ iMinWidth, cols }) => (cols === 1 ? 0 : iMinWidth)}px,
      ${({ iMaxWidth }) => iMaxWidth}px
    )
  );
  width: 100%;
  max-width: ${({ iMaxWidth, cols, gap }) => cols * iMaxWidth + (gap ?? 0) * (cols - 1)}px;
  ${({ gap }) => (gap ? `gap: ${gap}px;` : "")}
`;

const Container = styled(View)`
  ${propPlaceCenter};
`;

export interface GalleryProps extends ViewProps {
  gap?: number;
  itemMinWidth?: number;
  itemMaxWidth?: number;
}

const Gallery: React.FC<GalleryProps> = ({
  children,
  gap,
  itemMinWidth,
  itemMaxWidth,
  ...props
}) => {
  const [containerWidth, setContainerWidth] = useState<number>(0);

  const onContainerLayout = useListener((e: LayoutChangeEvent) => {
    const {
      nativeEvent: {
        layout: { width },
      },
    } = e;

    setContainerWidth(width);
  });

  const iMinWidth = itemMinWidth ?? 400;
  const iMaxWidth = itemMaxWidth ?? 520;
  const divMinWidth = iMinWidth + (gap ?? 0);
  const divMaxWidth = iMaxWidth + (gap ?? 0);
  const minWidthCols = Math.max(Math.floor(containerWidth / divMinWidth), 1);
  const maxWidthCols = Math.max(Math.ceil(containerWidth / divMaxWidth), 1);
  const cols = Math.min(minWidthCols, maxWidthCols);

  return (
    <Container onLayout={onContainerLayout}>
      <Wrapper
        containerWidth={containerWidth}
        iMinWidth={iMinWidth}
        iMaxWidth={iMaxWidth}
        cols={cols}
        gap={gap}
        {...props}
      >
        {children}
      </Wrapper>
    </Container>
  );
};

export default Gallery;

import { useNavigation, useRoute } from "@react-navigation/native";
import { Layout, withStyles, EvaProp } from "@ui-kitten/components";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import FundraiserDetail from "../components/FundraiserDetail";
import BackButton from "../components/navigation/BackButton";
import useUser from "../hooks/useUser";
import useWindowSize from "../hooks/useWindowSize";
import MainLayout from "../layouts/MainLayout";
import CampaignQRCodeDialog from "../components/CampaignQRCodeDialog";
import Campaign from "../store/models/Campaign";
import useListener from "../hooks/useListener";

interface Props {
  eva?: EvaProp;
}

const FundraiserScreen: React.FC<Props> = ({ eva }) => {
  const route = useRoute();
  const { id } = route.params as { id: number };
  const user = useUser(id);
  const navigation = useNavigation();
  const { isTablet } = useWindowSize();
  const [contextCampaign, setContextCampaign] = useState<Campaign>();
  const [qrCodeModalVisible, setQrCodeModalVisible] = useState(false);

  useEffect(() => {
    if (user) {
      navigation.setOptions({ title: `Doni | ${user.name}` });
    }
  }, [navigation, user]);

  const onCampaignSharePress = useListener((campaign) => {
    setContextCampaign(campaign);
    setQrCodeModalVisible(true);
  });

  const onQrCodeModalHide = useListener(() => {
    setQrCodeModalVisible(false);
  });

  return (
    <MainLayout
      modalDialog={
        <CampaignQRCodeDialog campaign={contextCampaign as Campaign} onClose={onQrCodeModalHide} />
      }
      showModalDialog={qrCodeModalVisible}
      onModalBackdropPress={onQrCodeModalHide}
    >
      <Layout style={eva?.style?.mainContent}>
        <Layout style={isTablet ? eva?.style?.mainMobile : eva?.style?.mainCard}>
          <BackButton text="Back" style={eva?.style?.back} />
          {user && <FundraiserDetail user={user} onCampaignSharePress={onCampaignSharePress} />}
        </Layout>
      </Layout>
    </MainLayout>
  );
};

export default withStyles(observer(FundraiserScreen), () => ({
  mainContent: {
    backgroundColor: "#f8f9f9",
    flex: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  mainCard: {
    flexShrink: 1,
    width: "80%",
    maxWidth: 1100,
    paddingHorizontal: 128,
    paddingVertical: 64,
    paddingTop: 48,
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: 64,
    marginBottom: 64,
    boxShadow: "0px 4px 11px 0px #0000000F",
    border: 0,
    borderRadius: 10,
    overflow: "hidden",
    rowGap: 12,
  },
  mainMobile: {
    flex: 1,
    width: "100%",
    paddingHorizontal: 20,
    paddingVertical: 48,
    paddingTop: 68,
    paddingBottom: 25,
    rowGap: 12,
  },
  back: {
    height: 20,
    maxHeight: 20,
    width: "100%",
    justifyContent: "flex-start",
    flex: 1,
    paddingHorizontal: 0,
    paddingVertical: 0,
  },
}));

import { EvaProp, Icon, Layout, Modal, Text, withStyles } from "@ui-kitten/components";
import React from "react";
import { ScrollView } from "react-native";
import { TouchableOpacity } from "react-native-gesture-handler";
import Markdown from "./Markdown";
import termsOfService from "../assets/data/terms-and-condition.json";

const termsOfServiceMarkdown = termsOfService.articles.reduce(
  (acc, { title, body }) => `${acc}${title}\n${body}\n`,
  "#"
);

interface Props {
  eva?: EvaProp;
  visible?: boolean;
  setVisible?: React.Dispatch<React.SetStateAction<boolean>>;
}

const TermsConditionsModal: React.FC<Props> = ({ eva, visible, setVisible }) => {
  return (
    <Modal
      visible={visible}
      backdropStyle={eva?.style?.backdrop}
      onBackdropPress={() => {
        if (setVisible) setVisible(false);
      }}
    >
      <Layout style={eva?.style?.agreementModal}>
        <Layout style={eva?.style?.closeButton}>
          <TouchableOpacity
            onPress={() => {
              if (setVisible) setVisible(false);
            }}
          >
            <Icon style={eva?.style?.closeButtonIcon} fill="#2E3338" name="close-outline" />
          </TouchableOpacity>
        </Layout>
        <ScrollView style={eva?.style?.agreementModalScrollWrapper}>
          <Markdown>{termsOfServiceMarkdown}</Markdown>
        </ScrollView>
      </Layout>
    </Modal>
  );
};

export default withStyles(TermsConditionsModal, () => ({
  agreementModal: {
    paddingTop: 24,
    paddingBottom: 36,
    borderRadius: 10,
    boxShadow: "0px 4px 11px 0px #0000000F",
    marginLeft: 24,
    marginRight: 24,
    marginTop: 24,
    marginBottom: 24,
    maxWidth: 600,
  },
  backdrop: {
    backgroundColor: "rgba(138, 138, 138, 0.6)",
  },
  closeButton: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    flexDirection: "row",
    marginBottom: 14,
    paddingRight: 24,
  },
  closeButtonIcon: {
    width: 24,
    height: 24,
  },
  agreementModalHeaderTitle: {
    fontSize: 24,
    fontFamily: "Jost_500Medium",
    textAlign: "center",
    marginBottom: 24,
  },
  agreementModalText: {
    textAlign: "left",
    fontSize: 14,
    fontFamily: "Jost_400Regular",
    color: "#757F87",
  },
  agreementModalScrollWrapper: {
    maxHeight: "50vh",
    paddingHorizontal: 24,
  },
}));

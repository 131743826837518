import { useEffect, useState } from "react";
import Toast, { SHORT } from "../components/Toast";
import { useStore } from "../store";
import User from "../store/models/User";

export default function useUser(id: number, onPress?: () => void) {
  const store = useStore();

  const [user, setUser] = useState<User>();

  useEffect(() => {
    if (id > 0) {
      store.users
        .get(id)
        .then((userDetails) => setUser(new User(userDetails)))
        .catch(() => {
          Toast.show({ type: "error", text1: "User not found", visibilityTime: SHORT });
        });
    }
  }, [id, onPress, store, setUser]);

  return user;
}

import React from "react";
import InlineSVG, { InlineSVGProps } from "svg-inline-react";
import LocationPinSVG from "../../assets/location_pin.svg";

interface Props extends Omit<InlineSVGProps, "src" | "raw"> {}

const LocationPin = (props: Props) => <InlineSVG src={LocationPinSVG} raw {...props} />;

LocationPin.defaultProps = {
  style: undefined,
};

export default LocationPin;

import React from "react";
import InlineSVG, { InlineSVGProps } from "svg-inline-react";
import HomeSVG from "../../assets/home.svg";

interface Props extends Omit<InlineSVGProps, "src" | "raw"> {}

const Home = (props: Props) => <InlineSVG src={HomeSVG} raw {...props} />;

Home.defaultProps = {
  style: undefined,
};

export default Home;

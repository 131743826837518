import { useState, useEffect } from "react";
import {
  useFonts,
  Jost_100Thin,
  Jost_200ExtraLight,
  Jost_300Light,
  Jost_400Regular,
  Jost_500Medium,
  Jost_600SemiBold,
  Jost_700Bold,
  Jost_800ExtraBold,
  Jost_900Black,
  Jost_100Thin_Italic,
  Jost_200ExtraLight_Italic,
  Jost_300Light_Italic,
  Jost_400Regular_Italic,
  Jost_500Medium_Italic,
  Jost_600SemiBold_Italic,
  Jost_700Bold_Italic,
  Jost_800ExtraBold_Italic,
  Jost_900Black_Italic,
} from "@expo-google-fonts/jost";
import { createRootStore, RootStore } from "../store";
import api from "../api";

export const useInit = () => {
  const [rootStore, setRootStore] = useState<RootStore>();
  const [fontsLoaded] = useFonts({
    Jost_100Thin,
    Jost_200ExtraLight,
    Jost_300Light,
    Jost_400Regular,
    Jost_500Medium,
    Jost_600SemiBold,
    Jost_700Bold,
    Jost_800ExtraBold,
    Jost_900Black,
    Jost_100Thin_Italic,
    Jost_200ExtraLight_Italic,
    Jost_300Light_Italic,
    Jost_400Regular_Italic,
    Jost_500Medium_Italic,
    Jost_600SemiBold_Italic,
    Jost_700Bold_Italic,
    Jost_800ExtraBold_Italic,
    Jost_900Black_Italic,
  });

  const [ready, setReady] = useState(false);

  const initialized = rootStore && ready && fontsLoaded;

  useEffect(() => {
    if (ready) {
      return;
    }
    (async () => {
      const newStore = await createRootStore();
      setRootStore(newStore);

      try {
        await newStore.init();
        await newStore.ping();
      } catch (e) {
        await api.handleError(e);
        newStore.reset();
      }

      setReady(true);
    })();
  }, [ready]);

  return {
    ready: initialized,
    store: rootStore,
  };
};

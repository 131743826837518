import { useNavigation, useRoute } from "@react-navigation/native";
import { observer } from "mobx-react-lite";
import React from "react";
import styled from "rn-css";
import { StyleSheet, View } from "react-native";
import Category from "../store/models/Category";
import { toKebabCase } from "../utils/toKebabCase";
import MainLayoutBase, { MainLayoutProps } from "../layouts/MainLayout";
import useListener from "../hooks/useListener";
import CategoryList from "../components/CategoryList";
import ButtonBase from "../components/Button";
import { FlexRow, placeCenter } from "../styles/containers";
import useWindowSize from "../hooks/useWindowSize";
import CampaignSearchBar from "../components/CampaignSearchBar";
import useCategoryList from "../hooks/useCategoryList";
import { useStore } from "../store";

const styles = StyleSheet.create({
  button: {
    width: 240,
    height: 40,
  },
  buttonMobile: {
    width: "100%",
    maxWidth: 343,
    height: 40,
  },
});

const Button = styled(ButtonBase)`
  align-self: flex-end;
`;

const TopBar = styled(FlexRow)`
  justify-content: flex-end;
  align-items: center;
`;

const SearchBar = styled(CampaignSearchBar)`
  height: 64px;
  max-height: 64px;
  width: 100%;
  padding-left: 24px;
  padding-right: 24px;

  @media (max-width: 768px) {
    height: 48px;
    max-height: 48px;
  }
`;

const StickyFooter = styled(View)`
  z-index: 10;
  position: sticky;
  bottom: 0px;
  width: 100%;
  padding: 20px;
  background: #ffffff;
  border: 1px solid #edeeef;
  box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.05);
`;

const MainLayout = styled(MainLayoutBase)`
  gap: 36px;
  padding: 44px 128px;

  @media (max-width: 768px) {
    gap: 24px;
    padding: 36px 20px;
  }
`;

interface Props extends MainLayoutProps {}

const CategoryListScreen: React.FC<Props> = () => {
  const { isMobile } = useWindowSize();
  const route = useRoute();
  const navigation = useNavigation();
  const { results: categories } = useCategoryList();
  const { me } = useStore();

  const params = (route.params || {}) as { searchPattern: string };

  const onCategorySelect = useListener((category: Category) => {
    navigation.navigate("CampaignCategoryExplore", {
      categoryName: toKebabCase(category.name ?? ""),
      searchPattern: undefined,
    });
  });

  const onSearchSubmit = useListener((submittedPattern: string) => {
    if (submittedPattern.length > 0) {
      navigation.setParams({ searchPattern: submittedPattern });
      navigation.navigate("CampaignCategoryExplore", {
        categoryName: "all",
        searchPattern: submittedPattern,
      });
    }
  });

  const onStartCampaignPress = useListener(() => {
    if (me) {
      navigation.navigate("CampaignCreate");
    } else {
      navigation.navigate("SignIn", { next: "campaign-create" });
    }
  });

  return (
    <MainLayout
      footer={
        isMobile && (
          <StickyFooter style={placeCenter}>
            <Button
              text="Start a Campaign"
              type="greenPrimary"
              containerStyle={styles.buttonMobile}
              onPress={onStartCampaignPress}
            />
          </StickyFooter>
        )
      }
    >
      {!isMobile && (
        <TopBar>
          <Button
            text="Start a Campaign"
            type="greenPrimary"
            containerStyle={styles.button}
            onPress={onStartCampaignPress}
          />
        </TopBar>
      )}
      <SearchBar
        clearOnBlur={false}
        defaultValue={params.searchPattern}
        onSubmit={onSearchSubmit}
      />
      <CategoryList items={categories || []} onItemSelect={onCategorySelect} />
    </MainLayout>
  );
};

export default observer(CategoryListScreen);

import React, { useState } from "react";
import { Modal as ModalBase, ModalProps as ModalPropsBase } from "@ui-kitten/components";
import { LayoutChangeEvent, StyleProp, StyleSheet, View, ViewStyle } from "react-native";
import styled from "styled-components/native";
import useListener from "../hooks/useListener";
import { propPlaceCenter } from "../styles/containers";

const styles = StyleSheet.create({
  backdrop: {
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: "rgba(138, 138, 138, 0.6)",
  },
});

const Container = styled(ModalBase)<{ dialogWidth: string; dialogHeight: string }>`
  ${propPlaceCenter};
  width: max-content;
  height: max-content;
  position: absolute;
  left: calc((100vw - ${({ dialogWidth }) => dialogWidth}) / 2);
  top: calc((100vh - ${({ dialogHeight }) => dialogHeight}) / 2);
`;

export interface ModalProps extends ModalPropsBase {
  styleTree?: {
    container?: StyleProp<ViewStyle>;
    backdrop?: StyleProp<ViewStyle>;
    dialog?: StyleProp<ViewStyle>;
  };
}

const Modal: React.FC<ModalProps> = ({ children, visible, styleTree, style, ...props }) => {
  const [size, setSize] = useState(["", ""]);

  const onDialogWrapperLayout = useListener((e: LayoutChangeEvent) => {
    const {
      nativeEvent: {
        layout: { width, height },
      },
    } = e;

    setSize([`${width}px`, `${height}px`]);
  });

  return (
    <Container
      dialogWidth={size[0]}
      dialogHeight={size[1]}
      visible={visible}
      backdropStyle={[styles.backdrop, styleTree?.backdrop]}
      style={[style, styleTree?.container]}
      {...props}
    >
      <View onLayout={onDialogWrapperLayout} style={styleTree?.dialog}>
        {children}
      </View>
    </Container>
  );
};

export default Modal;

import {
  model,
  Model,
  prop_mapObject,
  prop_dateString,
  modelAction,
  _await,
  modelFlow,
  _async,
} from "mobx-keystone";
import api from "../api";
import { APICategory } from "../api/categories";
import Category from "./models/Category";

@model("doni/CategoryStore")
class CategoryStore extends Model({
  listItems: prop_mapObject(() => new Map<number, Category>()),
  updatedAt: prop_dateString<Date | null>(null),
}) {
  onAttachedToRootStore() {
    this.list();
  }

  @modelAction
  saveListItem(item: APICategory) {
    const listItem = new Category(item);
    this.listItems.set(item.id, listItem);
    this.updatedAt = new Date();
    return listItem;
  }

  @modelFlow
  list = _async(function* (this: CategoryStore, page?: number, limit?: number) {
    const { count, next, previous, results: resultsRaw } = yield* _await(
      api.categories.list(page, limit)
    );
    this.listItems.clear();
    const results = resultsRaw.map((item) => this.saveListItem(item));
    return { count, next: !!next, previous: !!previous, results };
  });
}

export default CategoryStore;

import { Text } from "@ui-kitten/components";
import { observer } from "mobx-react-lite";
import React from "react";
import { useNavigation } from "@react-navigation/native";
import { ViewProps } from "react-native";
import styled from "rn-css";
import Campaign from "../store/models/Campaign";
import { FlexCol, FlexRow } from "../styles/containers";
import Onboarding4 from "./svgs/Onboarding4";
import Button from "./Button";
import Divider from "./Divider";
import HorizontalRule from "./HorizontalRule";

const SVGContainer = styled(FlexRow)`
  justify-content: center;
`;

const Header = styled(Text)`
  font-family: Jost_500Medium;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 35px;
  font-feature-settings: "pnum" on, "lnum" on;
  color: #2e3338;
`;

const Subheader = styled(Text)`
  font-family: Jost_400Regular;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  font-feature-settings: "pnum" on, "lnum" on;
  color: #757f87;
`;

const InfoText = styled(Text)<{ color?: string; semibold?: boolean }>`
  padding: 0px 8px;
  font-family: ${({ semibold = false }) => (semibold ? "Jost_500Medium" : "Jost_400Regular")};
  font-style: normal;
  font-weight: ${({ semibold = false }) => (semibold ? 500 : 400)};
  font-size: ${({ semibold = false }) => (semibold ? 18 : 16)}px;
  line-height: 23px;
  font-feature-settings: "pnum" on, "lnum" on;
  color: ${({ color = "#757f87" }) => color};
`;

const TransactionDetails = styled(FlexCol)`
  gap: 12px;
`;

const SpacedBetween = styled(FlexRow)`
  justify-content: space-between;
  gap: 12px;
`;

const Container = styled(FlexCol)`
  padding: 64px;
  gap: 24px;
  width: 538px;
  background: #ffffff;
  box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.06);
  border-radius: 10px;

  @media (max-width: 768px) {
    padding: 48px;
    width: 100%;
    height: 100%;
    border-radius: 0;
  }
`;

export interface CampaignWithdrawConfirmDialogProps extends ViewProps {
  campaign: Campaign;
  blockNumber: number;
  fundsWithdrawn: string;
}

const CampaignWithdrawConfirmDialog: React.FC<CampaignWithdrawConfirmDialogProps> = ({
  campaign,
  fundsWithdrawn,
  blockNumber,
  ...props
}) => {
  const navigation = useNavigation();

  const onBackToCampaign = () =>
    navigation.navigate("CampaignDetail", {
      id: campaign.id,
    });

  return (
    <Container {...props}>
      <SVGContainer>
        <Onboarding4 />
      </SVGContainer>
      <Header>Congrats!</Header>
      <Subheader>
        Your funds have been withdrawn! We look forward to seeing the changes these funds can do for
        your cause.
      </Subheader>
      <Divider />
      <TransactionDetails>
        <SpacedBetween>
          <InfoText color="#2e3338">Sent to:</InfoText>
          <InfoText color="#2e3338">Valora Wallet</InfoText>
        </SpacedBetween>
        <SpacedBetween>
          <InfoText color="#2e3338">Block number:</InfoText>
          <InfoText color="#2e3338">{blockNumber}</InfoText>
        </SpacedBetween>
        <SpacedBetween>
          <InfoText color="#2e3338" semibold>
            Total Withdrew
          </InfoText>
          <InfoText color="#27a563" semibold>
            cUSD {fundsWithdrawn}
          </InfoText>
        </SpacedBetween>
      </TransactionDetails>
      <HorizontalRule />
      <Button type="bluePrimary" text="Back to Campaign" onPress={onBackToCampaign} />
    </Container>
  );
};

export default observer(CampaignWithdrawConfirmDialog);

import React from "react";
import { Pressable, PressableProps, StyleSheet, View, ViewProps } from "react-native";
import { Icon, Text } from "@ui-kitten/components";
import styled from "rn-css";

const styles = StyleSheet.create({
  caption: {
    marginLeft: "auto",
    userSelect: "none",
  },
  connectedStatusCaption: {
    color: "#27A563",
  },
  connectedStatusIcon: {
    width: 24,
    height: 20,
    fill: "#27A563",
  },
  linkedStatusCaption: {
    color: "#EF5555",
  },
  infoContainer: {
    maxWidth: "calc(100% - 56px)",
  },
});

interface ContainerProps extends PressableProps {
  selectable?: boolean;
  caption?: string;
}

const Container = styled(Pressable)<ContainerProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px;
  gap: 24px;
  height: ${({ caption = "" }) => (caption !== "" ? "87" : "67")}px;
  border-radius: 10px;
  cursor: ${({ selectable = false }) => (selectable ? "pointer" : "default")}

  &:hover {
    ${({ selectable = false }) => (selectable ? "background: #F8F9F9;" : "")}
  }
`;

interface InfoContainerProps extends ViewProps {
  caption?: string;
}

const InfoContainer = styled(View)<InfoContainerProps>`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 0px;
  height: 63px;
  flex: 1;
  flex: auto;
  max-width: calc(100% - 56px);
  justify-content: ${({ caption = "" }) => (caption !== "" ? "flex-start" : "center")};
`;

const Header = styled(View)`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
`;

const Heading = styled(Text)`
  font-family: "Jost_500Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 23px;
  display: flex;
  align-items: center;
  color: #2e3338;
  flex: auto;
  user-select: none;
`;

const Caption = styled(Text)`
  font-family: "Jost_400Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #757f87;
  user-select: none;
`;

interface Props extends PressableProps {
  icon: JSX.Element;
  name: string;
  caption?: string;
  status?: "connected" | "linked" | "";
  selectable?: boolean;
  onUnlinkPress?: (...args: any[]) => void;
}

const WalletCard: React.FC<Props> = ({
  icon,
  name,
  caption,
  status = "",
  onUnlinkPress,
  ...props
}) => (
  <Container {...props} caption={caption}>
    {React.cloneElement(icon, {
      style: {
        ...icon.props?.style,
        width: 44,
        height: 44,
      },
    })}
    <InfoContainer style={styles.infoContainer}>
      <Header>
        <Heading>{name}</Heading>
        {
          {
            connected: (
              <>
                <Caption style={[styles.caption, styles.connectedStatusCaption]}>Connected</Caption>
                <Icon name="checkmark-outline" style={styles.connectedStatusIcon} />
              </>
            ),
            linked: (
              <Caption style={[styles.caption, styles.linkedStatusCaption]} onPress={onUnlinkPress}>
                Unlink
              </Caption>
            ),
          }[status as string]
        }
      </Header>
      {caption && <Caption>{caption}</Caption>}
    </InfoContainer>
  </Container>
);

export default WalletCard;

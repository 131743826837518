import { Modal as BaseModal, ModalProps as BaseModalProps, Text } from "@ui-kitten/components";
import { StyleSheet } from "react-native";
import React from "react";
import styled from "rn-css";
import QRCode from "react-native-qrcode-svg";
import * as Linking from "expo-linking";
import Button from "./Button";

interface ModalProps extends BaseModalProps {}

const Modal = styled(BaseModal)<ModalProps>`
  background: #ffffff;
  padding: 36px;
  border-radius: 10px;
  width: 420px;
  box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.06);
  align-items: center;
  display: flex;

  @media (max-width: 768px) {
    width: 335px;
    padding: 36px 16px;
  }
`;

const Heading = styled(Text)`
  font-family: "Jost_500Medium";
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  display: flex;
  font-feature-settings: "pnum" on, "lnum" on;
  color: #2e3338;
  justify-content: center;
`;

const HelpText = styled(Text)`
  color: #757f87;
  font-family: Jost_400Regular;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  margin: 24px 0;
`;

const styles = StyleSheet.create({
  modalBackdrop: {
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: "rgba(138, 138, 138, 0.6)",
  },
  button: {
    height: 40,
    marginTop: 24,
  },
});

interface QRCodeModalProps extends ModalProps {
  qrCodeUri: string;
}

const QRCodeModal = ({ qrCodeUri, ...props }: QRCodeModalProps) => (
  <Modal backdropStyle={styles.modalBackdrop} {...props}>
    <Heading>Valora</Heading>
    <HelpText>
      Opening Valora Wallet. If it doesn&apos;t open, you can scan this QR code or if on a mobile
      device press Open
    </HelpText>
    <QRCode value={qrCodeUri} size={190} />
    <Button
      text="Open Valora"
      onPress={() => Linking.openURL(qrCodeUri)}
      containerStyle={styles.button}
    />
  </Modal>
);

export default QRCodeModal;

import React from "react";
import {
  Icon as IconBase,
  IndexPath,
  Menu as MenuBase,
  MenuItem as MenuItemBase,
  Popover,
  PopoverProps,
  Text,
} from "@ui-kitten/components";
import styled from "rn-css";
import { View, ViewStyle } from "react-native";
import useListener from "../hooks/useListener";
import LinkedIn from "./svgs/LinkedIn";
import QRCode from "./svgs/QRCode";
import WhatsApp from "./svgs/WhatsApp";
import { propFlexRow } from "../styles/containers";

const iconStyle = {
  width: 20,
  height: 20,
  fill: "#35d07f",
  color: "#35d07f",
};

const optionsContainerStyle = {
  gap: 2,
} as ViewStyle;

const Icon = styled(IconBase)`
  width: 20px;
  height: 20px;
  fill: #35d07f;
  border-radius: 2.8px;
`;

const Header = styled(View)`
  padding: 12px 12px 8px 16px;
`;

const Heading = styled(Text)`
  font-family: "Jost_500Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 23px;
  font-feature-settings: "pnum" on, "lnum" on;
  color: #2e3338;
`;

const MenuText = styled(Text)`
  ${propFlexRow};
  gap: 8px;
  font-family: "Jost_400Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  font-feature-settings: "pnum" on, "lnum" on;
  color: #2e3338;
`;

const Menu = styled(MenuBase)`
  gap: 2px;
  padding: 0px;
  width: 100%;
  border-radius: 4px;
`;

const MenuItem = styled(MenuItemBase)`
  border-width: 0px;
  padding: 8px 12px;
  gap: 8px;
  background: #ffffff;
`;

const Container = styled(Popover)`
  gap: 2px;
  padding: 0px;
  width: 200px;
  border: 1px solid #edeeef;
  filter: drop-shadow(0px 0px 11px rgba(0, 0, 0, 0.06));
  border-radius: 4px;
`;

const options = [
  // @ts-ignore
  { key: "qrcode", label: "QR Code", icon: <QRCode style={iconStyle} /> },
  {
    key: "link",
    label: (linkCopied: boolean) => (linkCopied ? "Link Copied!" : "Copy Link"),
    icon: <Icon name="link-2" style={iconStyle} />,
  },
  { key: "email", label: "Email", icon: <Icon name="email-outline" style={iconStyle} /> },
  { key: "facebook", label: "Facebook", icon: <Icon name="facebook" style={iconStyle} /> },
  { key: "twitter", label: "Twitter", icon: <Icon name="twitter" style={iconStyle} /> },
  // @ts-ignore
  { key: "linkedin", label: "LinkedIn", icon: <LinkedIn style={iconStyle} /> },
  // @ts-ignore
  { key: "whatsapp", label: "WhatsApp", icon: <WhatsApp style={iconStyle} /> },
];

export interface CampaignShareOptionsProps extends PopoverProps {
  linkCopied: boolean;
  onOptionSelect?: (option: string) => void;
}

const CampaignShareOptions: React.FC<CampaignShareOptionsProps> = ({
  linkCopied,
  onOptionSelect,
  ...props
}) => {
  const onSelectIndex = useListener((indexPath: IndexPath) => {
    const { key } = options.at(indexPath.row) ?? {};
    if (key && (!linkCopied || key !== "link")) {
      onOptionSelect?.(key);
    }
  });

  return (
    <Container {...props}>
      <>
        <Header>
          <Heading>Share</Heading>
        </Header>
        <Menu
          appearance="noDivider"
          onSelect={onSelectIndex}
          contentContainerStyle={optionsContainerStyle}
        >
          {options.map(({ key, label, icon }) => (
            <MenuItem
              key={key}
              title={() => (
                <MenuText>
                  {icon}
                  {typeof label === "function" ? label(linkCopied) : label}
                </MenuText>
              )}
            />
          ))}
        </Menu>
      </>
    </Container>
  );
};

export default CampaignShareOptions;

import moment from "moment";
import React from "react";
import { Image as ImageBase, View, ViewProps } from "react-native";
import { Text as TextBase } from "@ui-kitten/components";
import styled from "styled-components/native";
import ProfileChip from "./svgs/ProfileChip";

const Container = styled(View)`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`;

const ImageWrapper = styled(View)`
  height: 50px;
  width: 50px;
  border-radius: 999999px;
  overflow: hidden;
`;

const Image = styled(ImageBase)`
  width: auto;
  height: 100%;
  object-fit: cover;
`;

const InfoContainer = styled(View)`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex: auto;
`;

const Text = styled(TextBase)`
  font-family: "Jost_400Regular";
  font-style: normal;
  font-weight: 400;
`;

const AuthorText = styled(Text)`
  font-family: "Jost_500Medium";
  font-weight: 500;
  font-size: 16px;
  line-height: 23px;
  font-feature-settings: "pnum" on, "lnum" on;
  color: #2e3338;
`;

const DateText = styled(Text)`
  font-size: 12px;
  line-height: 17px;
  color: #757f87;
`;

interface Author {
  name: string;
  photo: string | null;
}

export interface CreationChipProps extends Omit<ViewProps, "children"> {
  author: Author;
  createdAt: string;
}

const CreationChip: React.FC<CreationChipProps> = ({ author, createdAt, ...props }) => {
  return (
    <Container {...props}>
      <ImageWrapper>
        {author.photo ? (
          <Image source={{ uri: author.photo }} />
        ) : (
          <ProfileChip height={50} width={50} />
        )}
      </ImageWrapper>
      <InfoContainer>
        <Text>
          By <AuthorText>{author.name}</AuthorText>
        </Text>
        <DateText>{moment(createdAt).format("LL")}</DateText>
      </InfoContainer>
    </Container>
  );
};

export default CreationChip;

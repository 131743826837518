import React from "react";
import { View, StyleSheet } from "react-native";
import { Text } from "@ui-kitten/components";
import Logo from "./svgs/Logo";

interface Props {
  visible?: boolean;
  text?: string;
}

const styles = StyleSheet.create({
  overlay: {
    flex: 1,
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, 0.45)",
    display: "flex",
    zIndex: 999,
    alignItems: "center",
    justifyContent: "center",
  },
  modal: {
    backgroundColor: "#ffffff",
    alignItems: "center",
    justifyContent: "center",
    maxWidth: 440,
    width: "90%",
    paddingTop: 54,
    paddingBottom: 40,
    paddingHorizontal: 16,
    borderRadius: 10,
    textAlign: "center",
  },
  text: {
    color: "#27a563",
    fontSize: 24,
    lineHeight: 35,
    fontFamily: "Jost_500Medium",
    marginTop: 38,
  },
});

const LoadingOverlay: React.FC<Props> = ({ visible = true, text = "Loading..." }) => {
  if (!visible) {
    return null;
  }
  return (
    <View style={styles.overlay}>
      <View style={styles.modal}>
        <Logo height={85} width={87.5} />
        <Text style={styles.text}>{text}</Text>
      </View>
    </View>
  );
};

export default LoadingOverlay;

import React from "react";
import { View, ViewProps } from "react-native";

export interface TabProps extends ViewProps {
  title: string;
  children?: React.ReactNode | React.ReactNodeArray;
}

const Tab: React.FC<TabProps> = ({ title, children }) => {
  return <View key={title}>{children}</View>;
};

export default Tab;

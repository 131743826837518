import { Text } from "@ui-kitten/components";
import React from "react";
import { Image as ImageBase, StyleSheet, View, ViewProps } from "react-native";
import styled from "rn-css";
import Campaign from "../store/models/Campaign";
import { FlexCol, FlexRow } from "../styles/containers";

const styles = StyleSheet.create({
  bold: {
    fontFamily: "Jost_600SemiBold",
    fontWeight: "600",
  },
});

const Image = styled(ImageBase)`
  width: 120px;
  height: 100px;
`;

const Info = styled(FlexCol)`
  padding: 16px;
  flex: 1 1 auto;
`;

const Header = styled(Text)`
  font-family: Jost_500Medium;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 23px;
  font-feature-settings: "pnum" on, "lnum" on;
  color: #2e3338;
`;

const Caption = styled(Text)`
  font-family: Jost_400Regular;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  font-feature-settings: "pnum" on, "lnum" on;
  color: #757f87;
`;

const Container = styled(FlexRow)`
  overflow: hidden;
  background: #ffffff;
  box-shadow: 0px 4px 20px rgba(229, 229, 229, 0.5);
  border-radius: 4px;
`;

export interface CampaignThumbnailProps extends ViewProps {
  campaign: Campaign;
  caption?: React.ReactNode;
}

const CampaignThumbnail: React.FC<CampaignThumbnailProps> = ({ campaign, caption, ...props }) => {
  return (
    <Container {...props}>
      <View>
        <Image source={{ uri: campaign.cover as string }} />
      </View>
      <Info>
        <Header numberOfLines={1} ellipsizeMode="tail">
          {campaign.name ?? ""}
        </Header>
        {caption ?? (
          <Caption numberOfLines={1}>
            organized by <Caption style={styles.bold}>{campaign.organizerName ?? ""}</Caption>
          </Caption>
        )}
      </Info>
    </Container>
  );
};

export default CampaignThumbnail;

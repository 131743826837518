import React from "react";
import { Text, View, ViewProps } from "react-native";
import styled from "styled-components/native";
import { FlexCol, FlexRow } from "../styles/containers";
import CampaignShareOptionItem from "./CampaignShareSlideInOptionItem";
import PressableIcon from "./PressableIcon";
import LinkedIn from "./svgs/LinkedIn";
import QRCode from "./svgs/QRCode";
import WhatsApp from "./svgs/WhatsApp";

const Container = styled(FlexCol)`
  padding: 0px 0px 24px;
  gap: 2px;
  width: 100%;
  border: 1px solid #edeeef;
  filter: drop-shadow(0px 0px 11px rgba(0, 0, 0, 0.06));
  border-radius: 20px 20px 0px 0px;
`;

const Header = styled(FlexRow)`
  justify-content: space-between;
  padding: 12px 12px 8px 16px;
  gap: 10px;
  height: 43px;
`;

const Heading = styled(Text)`
  font-family: "Jost_500Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 23px;
  font-feature-settings: "pnum" on, "lnum" on;
  color: #2e3338;
`;

const CloseIcon = styled(PressableIcon)`
  width: 24px;
  height: 24px;
  fill: #2e3338;
`;

const SwipeContainer = styled(FlexCol)`
  align-items: center;
  height: 21;
  padding: 12px 0px 4px;
  border-radius: 20px 20px 0px 0px;
`;

const SwipeIndicator = styled(View)`
  width: 40px;
  height: 5px;
  background: #dddddd;
  border-radius: 999999px;
`;

export interface CampaignShareSlideInOptionsProps extends ViewProps {
  linkCopied: boolean;
  onClosePress?: () => void;
}

const CampaignShareSlideInOptions: React.FC<CampaignShareSlideInOptionsProps> = ({
  linkCopied,
  onClosePress,
  ...props
}) => {
  return (
    <Container {...props}>
      <SwipeContainer>
        <SwipeIndicator />
      </SwipeContainer>
      <Header>
        <Heading>Share</Heading>
        <CloseIcon iconName="close" onPress={onClosePress} />
      </Header>
      <CampaignShareOptionItem value="qrcode" icon={<QRCode />} text="QR Code" />
      <CampaignShareOptionItem
        value="link"
        iconName="link-2"
        text={linkCopied ? "Link Copied!" : "Copy Link"}
      />
      <CampaignShareOptionItem value="email" iconName="email-outline" text="Email" />
      <CampaignShareOptionItem value="facebook" iconName="facebook" text="Facebook" />
      <CampaignShareOptionItem value="twitter" iconName="twitter" text="Twitter" />
      <CampaignShareOptionItem value="linkedin" icon={<LinkedIn />} text="LinkedIn" />
      <CampaignShareOptionItem value="whatsapp" icon={<WhatsApp />} text="WhatsApp" />
    </Container>
  );
};

export default CampaignShareSlideInOptions;

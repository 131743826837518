import React, { useContext, useState } from "react";
import styled from "rn-css";
import { useNavigation, useRoute } from "@react-navigation/native";
import { observer } from "mobx-react-lite";
import { valueToBigNumber } from "@celo/contractkit/lib/wrappers/BaseWrapper";
import { useStore } from "../store";
import useCampaign from "../hooks/useCampaign";
import MainLayoutBase, { MainLayoutProps } from "../layouts/MainLayout";
import useListener from "../hooks/useListener";
import CampaignDonateDialogBase from "../components/CampaignDonateDialog";
import CampaignDonateConfirmDialogBase from "../components/CampaignDonateConfirmDialog";
import CampaignQRCodeDialog from "../components/CampaignQRCodeDialog";
import Toast, { SHORT } from "../components/Toast";
import WalletConnectContext from "../components/contexts/WalletConnectContext";
import { donate } from "../utils/contract";

const CampaignDonateDialog = styled(CampaignDonateDialogBase)`
  @media (max-width: 768px) {
    border-radius: 0px;
    height: 100%;
  }
`;

const CampaignDonateConfirmDialog = styled(CampaignDonateConfirmDialogBase)`
  @media (max-width: 768px) {
    border-radius: 0px;
    height: 100%;
  }
`;

const MainLayout = styled(MainLayoutBase)`
  justify-content: center;
  padding: 43px 0px;

  @media (max-width: 768px) {
    padding: 0px;
  }
`;

const USER_DENIED_FAIL = "User denied";
const BALANCE_EXCEEDED_FAIL = "exceeded balance of sender";

const fail = (errorMessage: string) => {
  if (errorMessage && errorMessage?.indexOf(USER_DENIED_FAIL) >= 0) {
    Toast.show({
      type: "error",
      text1: "You denied the transaction",
      visibilityTime: SHORT,
    });
  } else if (errorMessage && errorMessage?.indexOf(BALANCE_EXCEEDED_FAIL) >= 0) {
    Toast.show({
      type: "error",
      text1: "Not enough funds",
      visibilityTime: SHORT,
    });
  } else {
    Toast.show({
      type: "error",
      text1: errorMessage,
      visibilityTime: SHORT,
    });
  }
};

interface Props extends MainLayoutProps {}

const CampaignDonateScreen: React.FC<Props> = (props) => {
  const navigation = useNavigation();
  const route = useRoute();
  const { id } = (route?.params || {}) as { id: string };
  const [submitting, setSubmitting] = useState(false);
  const [loadingText, setLoadingText] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [submittedAmount, setSubmittedAmount] = useState<number>(0);
  const [showQrCodeDialog, setShowQrCodeDialog] = useState(false);
  const store = useStore();
  const { connectWallet } = useContext(WalletConnectContext);

  const { campaign, loading } = useCampaign(id);

  const onDonateSubmit = useListener(async ({ amount }: { amount: number }) => {
    if (!store.address || store.address === "") {
      fail("Please link a wallet first");
      return;
    }

    const { contractKit, error } = await connectWallet(false, store.address);

    if (!contractKit) {
      fail(error || "Uninitialized contract kit");
      return;
    }

    try {
      setLoadingText("Waiting for wallet confirmation...");
      setSubmitting(true);
      await donate(contractKit, campaign?.contractAddress || "", valueToBigNumber(amount));
      setIsSubmitted(true);
      setSubmittedAmount(amount);
    } catch (e) {
      if ("") console.log(e.code);
      console.log(e);
      fail(e.message);
      return;
    } finally {
      setLoadingText("");
      setSubmitting(false);
    }
  });

  const onCampaignShare = useListener(() => {
    setShowQrCodeDialog(true);
  });

  const onCampaignsExplore = useListener(() => {
    setIsSubmitted(false);
    navigation.navigate("Categories");
  });

  const onQrCodeDialogClose = useListener(() => {
    setShowQrCodeDialog(false);
  });

  return (
    <MainLayout
      alignStretch={false}
      modalDialog={
        campaign && <CampaignQRCodeDialog campaign={campaign} onClose={onQrCodeDialogClose} />
      }
      showModalDialog={showQrCodeDialog}
      onModalBackdropPress={onQrCodeDialogClose}
      loading={submitting || loading}
      loadingText={loading ? "" : loadingText}
      {...props}
    >
      {campaign &&
        (isSubmitted ? (
          <CampaignDonateConfirmDialog
            campaign={campaign}
            amount={submittedAmount}
            onCampaignShare={onCampaignShare}
            onCampaignsExplore={onCampaignsExplore}
          />
        ) : (
          <CampaignDonateDialog
            campaign={campaign}
            onSubmit={onDonateSubmit}
            submitDisabled={submitting}
          />
        ))}
    </MainLayout>
  );
};

export default observer(CampaignDonateScreen);

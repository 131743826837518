import React, { useContext, useState } from "react";
import { Image, StyleSheet, TouchableOpacity, View, ViewProps } from "react-native";
import { EvaProp, Text, withStyles } from "@ui-kitten/components";
import styled from "rn-css";
import Button from "./Button";
import useListener from "../hooks/useListener";
import useWindowSize from "../hooks/useWindowSize";
import WalletCardBase from "./WalletCard";
import CeloWalletWhite from "./svgs/CeloWalletWhite";
import ValoraWallet from "../assets/valora_wallet.png";
import Toast, { SHORT } from "./Toast";
import { useStore } from "../store";
import WalletConnectContext from "./contexts/WalletConnectContext";
import MainLayout from "../layouts/MainLayout";
import ValoraConnectDialog from "./ValoraConnectDialog";
import WalletSelectDialog from "./WalletSelectDialog";

const styles = StyleSheet.create({
  button: {
    height: 40,
  },
  containerMobile: {
    paddingTop: 48,
    paddingBottom: 48,
    paddingLeft: 20,
    paddingRight: 20,
    width: "100%",
    minHeight: "100%",
    borderRadius: 0,
  },
  walletInput: {
    borderWidth: 0,
  },
  walletInputText: {
    fontFamily: "Jost_500Medium",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: 15,
    lineHeight: 22,
    letterSpacing: 0.02,
    color: "#abadaf",
  },
});

const WalletCard = styled(WalletCardBase)`
  background-color: #f8f9f9;
  border-radius: 10px;
`;

const LinkedWalletsList = styled(View)`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 0px;
  gap: 12px;
`;

const AddWalletButton = styled(TouchableOpacity)`
  background: #f8f9f9;
  border-radius: 10px;
  padding: 16px;
  user-select: none;
  cursor: pointer;

  &:hover {
    background: #edeeef;
  }
`;

const AddWalletText = styled(Text)`
  color: #abadaf;
  font-family: Jost_500Medium;
`;

interface Props extends ViewProps {
  eva?: EvaProp;
}

const WalletConnectDialog: React.FC<Props> = ({ style, eva, ...props }) => {
  const { isMobile } = useWindowSize();
  const { connectWallet, closeQRCodeModal, disconnectWallet } = useContext(WalletConnectContext);
  const store = useStore();
  const hasValoraWallet = !!store.address;

  const [walletSelectionVisible, setWalletSelectionVisible] = useState(false);
  const [selectedWalletName, setSelectedWalletName] = useState("");

  const onConnectWallet = useListener(() => {
    setWalletSelectionVisible(true);
  });
  const onCloseWalletSelection = useListener(() => {
    setWalletSelectionVisible(false);
    setSelectedWalletName("");
  });
  const onSelectWallet = useListener(({ name }: { name: string }) => {
    setSelectedWalletName(name.toLowerCase());
  });
  const onGotoSelectWallet = useListener(() => {
    setSelectedWalletName("");
  });
  const onOpenValoraApp = useListener(() => {});
  const handleWalletLink = async () => {
    const { contractKit: kit, error } = await connectWallet(true, store.address);
    const address = kit?.defaultAccount;

    let type = "error";
    let message = error;

    if (address) {
      try {
        await store.updateAddress(address);
        type = "success";
        message = "Successfully linked wallet";
      } catch (e) {
        console.error(e);
        message = "Invalid address";
      }
    }

    closeQRCodeModal();
    Toast.show({
      type,
      text1: message,
      visibilityTime: SHORT,
    });
  };

  const handleWalletUnlink = async () => {
    await disconnectWallet();

    try {
      await store.updateAddress(null);
      Toast.show({
        type: "success",
        text1: "Successfully unlinked wallet",
        visibilityTime: SHORT,
      });
    } catch (e) {
      console.error(e);
      Toast.show({
        type: "error",
        text1: "An error occurred",
        visibilityTime: SHORT,
      });
    }
  };

  const linkedWallets = [
    ...(hasValoraWallet
      ? [
          {
            icon: <Image source={ValoraWallet} />,
            name: "Valora",
            caption: "Connect to Valora, a mobile payment app that works worldwide",
          },
        ]
      : []),
    // {
    //   icon: <CeloWallet />,
    //   name: "Celo Wallet",
    //   caption: "Connect to a Celo Wallet for web or desktop",
    // },
  ];

  const modalDialog = {
    valora: (
      <ValoraConnectDialog
        isMobile={isMobile}
        onClose={onCloseWalletSelection}
        onBack={onGotoSelectWallet}
        onOpenValoraApp={onOpenValoraApp}
      />
    ),
    "": (
      <WalletSelectDialog
        isMobile={isMobile}
        onClose={onCloseWalletSelection}
        onSelectWalletPress={onSelectWallet}
        hasValoraWallet={hasValoraWallet}
      />
    ),
  }[selectedWalletName];

  return (
    <MainLayout
      onlyBody
      style={eva?.style?.container}
      modalDialog={modalDialog}
      showModalDialog={walletSelectionVisible}
      onModalBackdropPress={onCloseWalletSelection}
      {...props}
    >
      <Text style={eva?.style?.title}>Wallets</Text>
      {linkedWallets && linkedWallets.length > 0 && (
        <LinkedWalletsList style={eva?.style?.walletButton}>
          {linkedWallets.map(({ icon, name }) => (
            <WalletCard
              key={name}
              icon={icon}
              name={name}
              status="linked"
              onUnlinkPress={handleWalletUnlink}
            />
          ))}
        </LinkedWalletsList>
      )}
      {!hasValoraWallet && (
        <AddWalletButton onPress={handleWalletLink} style={eva?.style?.walletButton}>
          <AddWalletText>Add Wallet</AddWalletText>
        </AddWalletButton>
      )}
      <Button
        onPress={handleWalletLink}
        type="bluePrimary"
        containerStyle={styles.button}
        disabled={hasValoraWallet}
      >
        {(evaProps) => (
          <>
            <CeloWalletWhite width={20} height={20} />
            <Text {...evaProps} style={eva?.style?.connectWalletText}>
              Connect Wallet
            </Text>
          </>
        )}
      </Button>
    </MainLayout>
  );
};

export default withStyles(WalletConnectDialog, () => ({
  container: {
    display: "flex",
    alignItems: "center",
    rowGap: 24,
  },
  connectWalletText: {
    fontSize: 15,
    fontFamily: "Jost_500Medium",
    color: "inherit",
    marginLeft: 12,
  },
  title: {
    fontFamily: "Jost_500Medium",
    fontWeight: "500",
    fontSize: 16,
    lineHeight: 23,
    color: "#2E3338",
    width: "100%",
    textAlign: "left",
  },
  walletButton: {
    width: "100%",
    minHeight: 70,
    justifyContent: "center",
  },
}));

import { useNavigation, useRoute } from "@react-navigation/native";
import {
  Icon,
  Layout,
  OverflowMenu,
  MenuItem,
  withStyles,
  EvaProp,
  Text,
  Divider,
} from "@ui-kitten/components";
import { observer } from "mobx-react-lite";
import React, { useCallback, useEffect, useState } from "react";
import { useForm, useFormState } from "react-hook-form";
import { APICampaignCreate, APICampaignInput } from "../api/campaigns";
import CampaignStep1Form from "../components/forms/CampaignStep1Form";
import CampaignStep2Form from "../components/forms/CampaignStep2Form";
import CampaignStep3Form from "../components/forms/CampaignStep3Form";
import BackButton from "../components/navigation/BackButton";
import useCampaign from "../hooks/useCampaign";
import { useStore } from "../store";
import Toast, { SHORT } from "../components/Toast";
import useWindowSize from "../hooks/useWindowSize";
import Button from "../components/Button";
import CancelConfirmButtons from "../components/CancelConfirmButtons";
import MainLayout, { MainLayoutProps } from "../layouts/MainLayout";
import showErrorPage from "../utils/showErrorPage";

interface Props extends MainLayoutProps {
  eva?: EvaProp;
}

const fail = (message?: string) =>
  Toast.show({
    type: "error",
    text1: message || "Could not update campaign.",
    visibilityTime: SHORT,
  });

const CampaignEditScreen: React.FC<Props> = ({ eva, ...props }) => {
  const navigation = useNavigation();
  const route = useRoute();
  const store = useStore();

  const [loading, setLoading] = useState(false);
  const [showOverflowMenu, setShowOverflowMenu] = useState(false);
  const [text, setText] = useState("Loading campaign...");

  const { isTablet } = useWindowSize();

  const { id } = route.params as { id: number };

  const { campaign, error: campaignError } = useCampaign(id);

  const [formState, setFormState] = useState<APICampaignInput>({
    ...campaign?.asAPI,
  });

  const {
    control,
    handleSubmit,
    formState: { errors, ...fs },
    reset,
  } = useForm();

  const { isDirty: formIsDirty } = useFormState({ control });

  // Once useCampaign returns, perform actions on the campaign data
  useEffect(() => {
    if (campaign) {
      // Redirect to error page if a logged-in user tries to
      // edit someone else's campaign
      if (campaign.organizer !== store.me?.id) {
        showErrorPage(navigation, "Cannot edit campaign.");
      }

      // Calling watch() is required to fetch balance+goal from the contract
      campaign.watch().then(() => {
        const newFormState = {
          ...campaign.asAPI,
        };
        setFormState(newFormState);
        reset(newFormState);
      });
    } else if (campaignError) {
      // Redirect to error page if user tries to
      // edit non-existent campaign
      showErrorPage(navigation, "Failed to load campaign.");
    } else {
      // Campaign is still loading, do nothing
    }
  }, [campaign, campaignError, navigation, reset, store.me]);

  useEffect(
    () =>
      navigation.addListener("beforeRemove", (e) => {
        setShowOverflowMenu(false);
        if (!fs.isDirty || fs.isSubmitted || fs.isSubmitting) {
          return;
        }

        e.preventDefault();

        // TODO: Bring back confirmation modal here
        navigation.dispatch(e.data.action);
        /*
        Alert.alert(
          "Discard changes?",
          "You have unsaved changes. Are you sure to discard them and leave the screen?",
          [
            { text: "Cancel", style: "cancel", onPress: () => {} },
            {
              text: "Discard",
              style: "destructive",
              // If the user confirmed, then we dispatch the action we blocked earlier
              // This will continue the action that had triggered the removal of the screen
              onPress: () => navigation.dispatch(e.data.action),
            },
          ]
        );
        */
      }),
    [navigation, fs.isDirty, fs.isSubmitted, fs.isSubmitting]
  );

  const doSave = useCallback(
    async (data: APICampaignCreate, draft: boolean) => {
      setLoading(true);

      try {
        await store.campaigns.put(id, {
          ...campaign?.asAPI,
          ...formState,
          ...data,
          isDraft: draft,
        });

        // Success
        setLoading(false);
        Toast.show({
          type: "success",
          text1: "The campaign has been saved.",
          visibilityTime: SHORT,
        });
        if (navigation.canGoBack()) {
          navigation.goBack();
        } else if (campaign) {
          navigation.navigate("CampaignDetail", { id: campaign.id });
        } else {
          navigation.navigate("Dashboard");
        }
        return;
      } catch (e) {
        setLoading(false);
        console.error({ response: e });
        fail();
      }
    },
    [campaign, formState, id, navigation, store.campaigns]
  );

  const save = useCallback(
    async (data: APICampaignCreate, draft: boolean) => {
      if (draft) {
        setText("Saving campaign...");
        await doSave(data, draft);
      } else if (campaign?.id) {
        // TODO: Bring back confirmation modal here
        setText("Saving campaign...");
        await doSave(data, false);
        /*
        Alert.alert(
          "Make changes to this campaign?",
          "Changes made to a publish campaign requires staff approval before they are visible to everyone.",
          [
            { text: "No", style: "cancel", onPress: () => {} },
            {
              text: "Yes",
              style: "destructive",
              onPress: async () => {
                setText("Saving campaign...");
                await doSave(data, false);
              },
            },
          ]
        );
        */
      } else {
        setText("Publishing campaign...");
        await doSave(data, draft);
      }
    },
    [campaign, doSave]
  );

  const handleSavePress = useCallback(
    async (data: APICampaignCreate) => {
      await save(data, Boolean(campaign?.isDraft));
    },
    [campaign, save]
  );

  const handlePublishPress = useCallback(
    async (data: APICampaignCreate) => {
      // TODO: Bring back confirmation modal here
      save(data, false);
      /*
      Alert.alert(
        "Publish campaign?",
        "Are you sure you want to publish this campaign?\n\nDoni staff needs to review your campaign before it becomes visible to everyone.",
        [
          { text: "No", style: "cancel", onPress: () => {} },
          {
            text: "Yes",
            style: "destructive",
            onPress: async () => save(data, false),
          },
        ],
        { cancelable: true }
      );
      */
    },
    [save]
  );

  const handleUnpublishPress = useCallback(
    async (data: APICampaignCreate) => {
      setShowOverflowMenu(false);
      // TODO: Bring back confirmation modal here
      save(data, true);
      /*
      Alert.alert(
        "Unpublish campaign?",
        "This campaign will no longer be visible to anyone but you, but its QR code will remain functional.",
        [
          { text: "No", style: "cancel", onPress: () => {} },
          {
            text: "Yes",
            style: "destructive",
            // If the user confirmed, then we dispatch the action we blocked earlier
            // This will continue the action that had triggered the removal of the screen
            onPress: async () => save(data, true),
          },
        ],
        { cancelable: true }
      );
      */
    },
    [save]
  );

  const accessoryRight = () => (
    <>
      <Button
        appearance="secondary"
        status="info"
        size="small"
        disabled={loading}
        onPress={handleSubmit(handleSavePress)}
      >
        Save
      </Button>
      {campaign && !campaign.isDraft ? (
        <OverflowMenu
          visible={showOverflowMenu}
          onBackdropPress={() => setShowOverflowMenu(false)}
          placement="bottom end"
          anchor={() => (
            <Button
              accessoryLeft={(iconProps) => <Icon {...iconProps} name="more-vertical" />}
              appearance="ghost"
              onPress={() => setShowOverflowMenu(true)}
              status="basic"
              size="small"
            />
          )}
        >
          <MenuItem title="Unpublish..." onPress={() => handleSubmit(handleUnpublishPress)()} />
        </OverflowMenu>
      ) : null}
    </>
  );

  const handleClosePress = useCallback(async () => {
    if (navigation.canGoBack()) {
      navigation.goBack();
    } else if (campaign) {
      navigation.navigate("CampaignDetail", { id: campaign?.id });
    } else {
      navigation.navigate("Categories");
    }
  }, [campaign, navigation]);

  // TODO: Add breadcrumb navigation
  return (
    <MainLayout alignStretch={false} loading={loading || !campaign} loadingText={text} {...props}>
      <Layout style={eva?.style?.mainContent}>
        <Layout style={isTablet ? eva?.style?.mainMobile : eva?.style?.mainCard}>
          {campaign && (
            <>
              {/* Out of flex-flow buttons */}
              <BackButton
                text="Back"
                style={isTablet ? eva?.style?.topBackNarrow : eva?.style?.topBackWide}
              />
              {/* Back to flex-flow */}
              <Text style={eva?.style?.titleText}>Edit Campaign</Text>
              <Text style={eva?.style?.subtitleText}>Manage campaign information.</Text>
              <Divider style={eva?.style?.miniDivider} />
              <Layout style={eva?.style?.formsContainer}>
                <CampaignStep2Form control={control} state={formState} showLabels />
                <CampaignStep1Form
                  control={control}
                  errors={errors}
                  goalEditable={false}
                  nameEditable={false}
                  state={formState}
                  showLabels
                />
                <CampaignStep3Form control={control} errors={errors} state={formState} showLabels />
              </Layout>
              <CancelConfirmButtons
                disableCancel={loading}
                disableConfirm={loading || !formIsDirty}
                onCancelPress={handleClosePress}
                onConfirmPress={handleSubmit(handleSavePress)}
                cancelText="Back"
                confirmText="Save Changes"
              />

              {/* <Footer>
                    {campaign?.isDraft ? (
                      <ActionButton disabled={loading} onPress={handleSubmit(handlePublishPress)}>
                        Publish…
                      </ActionButton>
                    ) : (
                      <ActionButton
                        disabled={loading}
                        onPress={handleWithdrawPress}
                        accessoryLeft={(props) => <Icon {...props} name="download" />}
                      >
                        Withdraw Funds…
                      </ActionButton>
                    )}
                  </Footer> */}
            </>
          )}
        </Layout>
      </Layout>
    </MainLayout>
  );
};

export default withStyles(observer(CampaignEditScreen), () => ({
  mainContent: {
    backgroundColor: "#f8f9f9",
    flex: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  mainCard: {
    flexShrink: 1,
    width: "80%",
    maxWidth: 1380,
    paddingHorizontal: 128,
    paddingVertical: 64,
    paddingTop: 108,
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: 64,
    marginBottom: 64,
    boxShadow: "0px 4px 11px 0px #0000000F",
    border: 0,
    borderRadius: 10,
    overflow: "hidden",
    rowGap: 12,
  },
  mainMobile: {
    flex: 1,
    width: "100%",
    paddingHorizontal: 20,
    paddingVertical: 48,
    paddingTop: 68,
    paddingBottom: 25,
    rowGap: 12,
  },
  titleText: {
    fontFamily: "Jost_500Medium",
    fontWeight: "500",
    fontSize: 24,
    lineHeight: 35,
  },
  subtitleText: {
    fontFamily: "Jost_400Regular",
    fontWeight: "400",
    fontSize: 16,
    lineHeight: 23,
    color: "#757f87",
  },
  miniDivider: {
    backgroundColor: "#35d07f",
    height: 4,
    width: 57,
    radius: 100,
    marginTop: 12,
    marginBottom: 12,
  },
  formsContainer: {
    display: "flex",
    flex: 1,
    rowGap: 24,
  },
  topBackWide: {
    flexGrow: 0,
    flexShrink: 1,
    position: "absolute",
    top: 50,
    left: 50,
  },
  topBackNarrow: {
    flexGrow: 0,
    flexShrink: 1,
    position: "absolute",
    top: 20,
    left: 20,
  },
  deleteWide: {
    maxWidth: 208,
    width: "100%",
    position: "absolute",
    top: 108,
    right: 128,
    paddingHorizontal: 0,
  },
}));

import React from "react";
import About from "../components/About";
import HeroCarousel from "../components/HeroCarousel";
import FeaturedCampaigns from "../components/FeaturedCampaigns";
import useWindowSize from "../hooks/useWindowSize";
import carouselImage1 from "../assets/images/carousel_image1.png";
import carouselImage2 from "../assets/images/carousel_image2.png";
import carouselImage3 from "../assets/images/carousel_image3.png";
import MainLayout, { MainLayoutProps } from "../layouts/MainLayout";

const carouselImages = [
  { source: carouselImage1, key: "image1" },
  { source: carouselImage2, key: "image2" },
  { source: carouselImage3, key: "image3" },
];

interface Props extends MainLayoutProps {}

const HomeScreen: React.FC<Props> = (props) => {
  const { isMobile } = useWindowSize();

  return (
    <MainLayout {...props}>
      <HeroCarousel images={carouselImages} isMobile={isMobile} />
      <About />
      <FeaturedCampaigns />
    </MainLayout>
  );
};

export default HomeScreen;

import { valueToBigNumber } from "@celo/contractkit/lib/wrappers/BaseWrapper";
import { useNavigation, useRoute, useFocusEffect, StackActions } from "@react-navigation/native";
import { EvaProp, Layout, Text, withStyles } from "@ui-kitten/components";
import { observer } from "mobx-react-lite";
import React, { useCallback, useContext, useState } from "react";
import { BackHandler, ScrollView } from "react-native";
import styled from "styled-components";
import useEffectOnce from "react-use/lib/useEffectOnce";
import api from "../api";
import { APICampaignCreate, APICampaignInput } from "../api/campaigns";
import CampaignCreateStep1 from "../components/CampaignCreateStep1";
import CampaignCreateStep2 from "../components/CampaignCreateStep2";
import CampaignCreateStep3 from "../components/CampaignCreateStep3";
import LoadingOverlay from "../components/LoadingOverlay";
import SafeArea from "../components/SafeArea";
import Toast, { SHORT } from "../components/Toast";
import { useStore } from "../store";
import { deployContract } from "../utils/contract";
import appConfig from "../configs/appConfig";
import TopNavigationResponsive from "../components/TopNavigationResponsive";
import PageFooter from "../components/PageFooter";
import useWindowSize from "../hooks/useWindowSize";
import WalletConnectContext from "../components/contexts/WalletConnectContext";

interface Props {
  eva?: EvaProp;
}

interface RouteParams {
  step?: number;
}

const Wrapper = styled(Layout)`
  flex: 1;
`;

let initialData: APICampaignInput = {
  goal: valueToBigNumber(0),
  name: "",
  purpose: "To raise money",
  location: "",
  endDate: null,
  cover: null,
  content: "",
  category: null,
};

const dev = !!appConfig.isLocal;
if (dev) {
  initialData = {
    goal: valueToBigNumber(1000),
    name: "Campaign",
    purpose: "To raise money",
    location: "",
    endDate: null,
    cover: null,
    content: "Here",
    category: 1,
  };
}

const NO_GAS_FAIL = "gas required exceeds allowance";

const fail = (message?: string) => {
  if (message && message?.indexOf(NO_GAS_FAIL) >= 0) {
    Toast.show({
      type: "error",
      text1:
        "Your Valora wallet requires a minimum amount of funds to deploy the campaign to the Celo network.",
      visibilityTime: SHORT,
    });
    return;
  }

  Toast.show({
    type: "error",
    text1: `We were not able to create the campaign. Please try again.${
      message ? `\n\n${message}` : ""
    }`,
    visibilityTime: SHORT,
  });
};

const CampaignCreateScreen: React.FC<Props> = ({ eva }) => {
  const navigation = useNavigation();
  const route = useRoute();
  const store = useStore();
  const { connectWallet } = useContext(WalletConnectContext);

  const { isTablet } = useWindowSize();

  const [loading, setLoading] = useState(false);
  const [text, setText] = useState("Launching Valora...");
  const [formState, setFormState] = useState<APICampaignInput>(initialData);
  const [termsAccepted, setTermsAccepted] = useState(false);

  const routeParams: RouteParams = route.params || {};
  const step = routeParams.step ? routeParams.step : 1;

  const create = useCallback(
    async (data: APICampaignCreate, draft: boolean) => {
      let contractAddress: string | null;

      if (!store.address || store.address === "") {
        fail("Please link a wallet first");
        return;
      }

      const { contractKit, error } = await connectWallet(false, store.address);

      if (!contractKit) {
        fail(error || "Uninitialized contract kit");
        return;
      }

      setText("Launching Valora...");
      setLoading(true);

      try {
        contractAddress = await deployContract(
          contractKit,
          store.address!,
          formState.goal!,
          () => {
            setLoading(true);
            setText("Waiting for wallet confirmation...");
          },
          () => {
            setText("Creating campaign...");
          }
        );
      } catch (e) {
        setLoading(false);
        fail(e.message);
        return;
      }

      if (contractAddress) {
        setText("Creating campaign...");
        setLoading(true);
        try {
          const { id } = await api.campaigns.create({
            ...formState,
            ...data,
            contractAddress,
            isDraft: draft,
          });
          // Success
          setLoading(false);
          navigation.dispatch(StackActions.popToTop());
          navigation.navigate("CampaignDetail", { id });
          return;
        } catch (e) {
          setLoading(false);
          // @ts-ignore
          console.error({ response: e.response });
          fail();
          return;
        }
      }

      fail();
      setLoading(false);
    },
    [formState, navigation, store.address, connectWallet]
  );

  const handleClosePress = useCallback(async () => {
    if (navigation.canGoBack()) {
      navigation.dispatch(StackActions.popToTop());
    } else {
      navigation.navigate("Home");
    }
  }, [navigation]);

  const handleBackPress = useCallback(async () => {
    navigation.navigate("CampaignCreate", { step: step - 1 });
  }, [navigation, step]);

  const handleNextPress = useCallback(
    async (data: APICampaignCreate) => {
      setFormState((prev) => ({ ...prev, ...data }));
      navigation.navigate("CampaignCreate", { step: step + 1 });
    },
    [navigation, step]
  );

  const handlePreviewPress = useCallback(
    (data: APICampaignCreate) => {
      store.campaigns.setPreview({ ...formState, ...data });
      navigation.dispatch(StackActions.push("CampaignDetail", { id: 0 }));
    },
    [navigation, store.campaigns, formState]
  );

  const handleDraftPress = useCallback(
    async (data: APICampaignCreate) => {
      await create(data, true);
    },
    [create]
  );

  const handlePublishPress = useCallback(
    async (data: APICampaignCreate) => {
      // TODO: Bring back confirmation modal here
      create(data, false);
      /*
      Alert.alert(
        "Publish campaign?",
        "Are you sure you want to publish this campaign?\n\nIf you are not ready to make this campaign public, you can save as draft and publish later.",
        [
          { text: "No", style: "cancel", onPress: () => {} },
          {
            text: "Yes",
            style: "destructive",
            onPress: async () => create(data, false),
          },
        ],
        { cancelable: true }
      );
      */
    },
    [create]
  );

  const backPressLogic = useCallback(() => {
    if (loading) {
      return true;
    }
    if (step <= 1) {
      handleClosePress();
    } else {
      handleBackPress();
    }
    return true;
  }, [handleBackPress, handleClosePress, loading, step]);

  const handleAcceptTerms = useCallback((status: boolean) => {
    setTermsAccepted(status);
  }, []);

  useFocusEffect(
    useCallback(() => {
      const backHandler = BackHandler.addEventListener("hardwareBackPress", backPressLogic);
      return () => {
        backHandler.remove();
      };
    }, [backPressLogic])
  );

  // Run only once on load: reset form and go to step 1
  useEffectOnce(() => {
    setFormState(initialData);
    navigation.navigate("CampaignCreate", { step: 1 });
  });

  return (
    <SafeArea>
      <TopNavigationResponsive />
      <LoadingOverlay visible={loading} text={text} />
      <ScrollView style={eva?.style?.scroller}>
        <Layout style={eva?.style?.wrapper}>
          <Layout style={eva?.style?.mainContent}>
            <Layout style={isTablet ? eva?.style?.mainMobile : eva?.style?.mainCard}>
              <Text appearance="hint" style={eva?.style?.stepIndicator}>
                Step {step} of 3
              </Text>
              <Wrapper>
                {step === 1 && (
                  <CampaignCreateStep1
                    disabled={loading}
                    state={formState}
                    onNext={handleNextPress}
                    onBack={backPressLogic}
                    termsAccepted={termsAccepted}
                    handleAcceptTerms={handleAcceptTerms}
                  />
                )}
                {step === 2 && (
                  <CampaignCreateStep2
                    disabled={loading}
                    state={formState}
                    onNext={handleNextPress}
                    onBack={backPressLogic}
                  />
                )}
                {step === 3 && (
                  <CampaignCreateStep3
                    disabled={loading}
                    state={formState}
                    onDraft={handleDraftPress}
                    onPublish={handlePublishPress}
                    onPreview={handlePreviewPress}
                    onBack={backPressLogic}
                  />
                )}
              </Wrapper>
            </Layout>
          </Layout>
          <PageFooter />
        </Layout>
      </ScrollView>
    </SafeArea>
  );
};

export default withStyles(observer(CampaignCreateScreen), () => ({
  scroller: {
    flex: 1,
    height: "calc(100vh - 80px)",
  },
  wrapper: {
    display: "flex",
    minHeight: "calc(100vh - 81px)",
  },
  mainContent: {
    backgroundColor: "#f8f9f9",
    flex: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  mainCard: {
    flexShrink: 1,
    width: "80%",
    maxWidth: 1100,
    paddingHorizontal: 128,
    paddingVertical: 64,
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: 64,
    marginBottom: 64,
    boxShadow: "0px 4px 11px 0px #0000000F",
    border: 0,
    borderRadius: 10,
    overflow: "hidden",
  },
  mainMobile: {
    flex: 1,
    width: "100%",
    paddingHorizontal: 20,
    paddingVertical: 48,
  },
  stepIndicator: {
    textAlign: "center",
    marginBottom: 12,
  },
}));

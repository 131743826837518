import {
  model,
  Model,
  modelAction,
  modelFlow,
  prop_dateString,
  prop_mapObject,
  _async,
  _await,
} from "mobx-keystone";
import api from "../api";
import { APIUser } from "../api/users";
import User from "./models/User";

@model("doni/UserStore")
class UserStore extends Model({
  listItems: prop_mapObject(() => new Map<number, User>()),
  updatedAt: prop_dateString<Date | null>(null),
}) {
  @modelAction
  saveListItem(item: APIUser) {
    if (this.listItems.has(item.id)) {
      const listItem = this.listItems.get(item.id);
      listItem?.update(item);
      return listItem;
    }

    const listItem = new User(item);
    this.listItems.set(item.id, listItem);
    this.updatedAt = new Date();
    return listItem;
  }

  @modelFlow
  get = _async(function* (this: UserStore, userId: number) {
    try {
      const result = yield* _await(api.users.detail(userId));
      this.saveListItem(result);
      return result;
    } catch (e) {
      throw new Error(e);
    }
  });
}

export default UserStore;

import React from "react";
import InlineSVG, { InlineSVGProps } from "svg-inline-react";
import LogoSVG from "../../assets/logo.svg";

interface Props extends Omit<InlineSVGProps, "src" | "raw"> {}

const Logo = (props: Props) => <InlineSVG src={LogoSVG} raw {...props} />;

Logo.defaultProps = {
  style: undefined,
};

export default Logo;

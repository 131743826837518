import React from "react";
import { ViewProps, StyleSheet, View } from "react-native";

interface Props extends ViewProps {
  width: number;
  height: number;
  color: string;
  space: number;
}

const VerticalDivider: React.FC<Props> = ({ height, width, color, space }) => {
  const styles = StyleSheet.create({
    vert: {
      height,
      width,
      backgroundColor: color,
      marginLeft: space,
      marginRight: space,
    },
  });

  return <View style={styles.vert} />;
};

export default VerticalDivider;

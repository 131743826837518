import React, { useCallback, useContext } from "react";
import { StyleSheet, TouchableOpacity } from "react-native";
import { useRoute } from "@react-navigation/native";
import { DrawerContentComponentProps, DrawerContentOptions } from "@react-navigation/drawer";
import {
  Divider,
  Drawer as UIKDrawer,
  DrawerItem,
  Icon,
  Layout,
  TopNavigation,
  Text,
  Avatar,
  EvaProp,
} from "@ui-kitten/components";
import { observer } from "mobx-react-lite";
import Button from "./Button";
import BubbleChart from "./svgs/BubbleChart";
import DocumentSearch from "./svgs/DocumentSearch";
import Home from "./svgs/Home";
import Logo from "./svgs/Logo";
import SearchBar from "./forms/SearchBar";
import { useStore } from "../store";
import EnvelopeHeart from "./svgs/EnvelopeHeart";
import Settings from "./svgs/Settings";
import Envelope from "./svgs/Envelope";
import Bell from "./svgs/Bell";
import ProfileChip from "./svgs/ProfileChip";
import WalletConnectContext from "./contexts/WalletConnectContext";

const styles = StyleSheet.create({
  icon: {
    width: 24,
    height: 24,
  },
  logo: {
    width: 28,
    height: 28,
  },
  close: {
    width: 32,
    height: 32,
  },
  iconContainer: {
    marginRight: 20,
  },
  drawer: {
    paddingLeft: 24,
    paddingRight: 24,
  },
  UIKDrawer: {},
  UIKDrawerItem: {
    paddingHorizontal: 0,
  },
  UIKDrawerItemText: {
    fontFamily: "Jost_400Regular",
    fontSize: 16,
    fontWeight: "400",
    lineHeight: 23,
    width: "100%",
  },
  topNavigation: {
    paddingLeft: 20,
    paddingRight: 20,
    height: 80,
  },
  divider: {
    marginTop: 8,
    marginBottom: 8,
  },
  loginButton: {
    marginTop: 16,
    height: 40,
  },
  startCampaignButton: {
    marginTop: 16,
    height: 40,
  },
  loginButtonText: {
    fontFamily: "Jost_500Medium",
    fontSize: 15,
    fontWeight: "500",
    color: "#27a563",
  },
  startCampaignButtonText: {
    fontFamily: "Jost_500Medium",
    fontSize: 15,
    fontWeight: "500",
    color: "#ffffff",
  },
  userButtons: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    flexDirection: "row",
    columnGap: 24,
    marginTop: 12,
  },
  avatar: {
    width: 40,
    height: 40,
  },
  searchBar: {
    marginVertical: 0,
    marginHorizontal: 0,
    backgroundColor: "none",
    border: 0,
    width: "100%",
  },
  searchBarContainer: {
    height: 38,
    marginHorizontal: 16,
    display: "flex",
  },
  searchBarText: {
    fontFamily: "Jost_400Regular",
    fontSize: 16,
    fontWeight: "400",
    marginHorizontal: 0,
    paddingHorizontal: 0,
    paddingVertical: 0,
  },
  logoutLink: {
    marginTop: 24,
  },
  logoutLinkText: {
    fontFamily: "Jost_400Regular",
    fontSize: 16,
    fontWeight: "400",
    color: "#27A563",
  },
});
const iconDimensions = StyleSheet.flatten([styles.icon]);
const logoDimensions = StyleSheet.flatten([styles.logo]);
const avatarDimensions = StyleSheet.flatten([styles.avatar]);

// refer to src/screens/Navigation.tsx
const drawerItemRoutes = ["Home", "Categories", "FAQ"];

const loginDrawerItemRoutes = ["Dashboard", "Settings"];

const ROUTE_NAMES_WITHOUT_SEARCH = ["Categories", "CampaignCategoryExplore"];

const DrawerContent = (
  { navigation, state }: DrawerContentComponentProps<DrawerContentOptions>,
  eva: EvaProp
) => {
  const store = useStore();
  const { disconnectWallet } = useContext(WalletConnectContext);
  const route = state.routes[state.index] || {};
  const routeName = route.name;
  const hideSearch = ROUTE_NAMES_WITHOUT_SEARCH.includes(routeName);

  const handleClosePress = useCallback(() => {
    navigation.closeDrawer();
  }, [navigation]);

  const accessoryRight = useCallback(() => {
    return (
      <TouchableOpacity onPress={handleClosePress}>
        <Icon style={styles.close} name="close-outline" />
      </TouchableOpacity>
    );
  }, [handleClosePress]);

  const handleLogin = useCallback(() => {
    if (!store.me) {
      navigation.navigate("SignIn", { next: "dashboard" });
    }
  }, [navigation, store.me]);

  const handleStartCampaign = useCallback(() => {
    if (store.me) {
      navigation.navigate("CampaignCreate");
    } else {
      navigation.navigate("SignIn", { next: "campaign-create" });
    }
  }, [navigation, store.me]);

  const handleAvatar = useCallback(() => {
    navigation.navigate("FundraiserDetail", { id: store.me?.id });
  }, [navigation, store.me]);

  /*
  const handleMessages = useCallback(() => {
    // TODO: Handle pressing messages button
  }, []);

  const handleNotifications = useCallback(() => {
    // TODO: Handle pressing notifications button
  }, []);
  */

  const handleLogout = useCallback(async () => {
    await disconnectWallet();
    store.logout();
  }, [store, disconnectWallet]);

  /* Search bar handlers */

  // Handle submitting a search query
  const handleSearchSubmit = useCallback(
    (submittedPattern: string) => {
      navigation.navigate("CampaignCategoryExplore", {
        categoryName: "all",
        searchPattern: submittedPattern,
      });
    },
    [navigation]
  );

  const handleSearchClear = () => {
    // do nothing
  };

  /* End search bar handlers */

  return (
    <>
      <TopNavigation
        title={() => <Logo width={logoDimensions.width} height={logoDimensions.height} />}
        accessoryRight={accessoryRight}
        alignment="center"
        style={styles.topNavigation}
      />
      {!hideSearch && (
        <SearchBar
          {...eva}
          placeholder="Search"
          onClear={handleSearchClear}
          onSubmit={handleSearchSubmit}
          style={styles.searchBar}
          containerStyle={styles.searchBarContainer}
          textStyle={styles.searchBarText}
          showClearButton={false}
          showIcon={false}
        />
      )}
      <Layout style={styles.drawer}>
        <Divider style={styles.divider} />
        <UIKDrawer
          onSelect={(index) => navigation.navigate(drawerItemRoutes[index.row])}
          appearance="noDivider"
          style={styles.UIKDrawer}
        >
          <DrawerItem
            title={() => <Text style={styles.UIKDrawerItemText}>Home</Text>}
            style={styles.UIKDrawerItem}
            accessoryLeft={() => (
              <Layout style={styles.iconContainer}>
                <Home width={iconDimensions.width} height={iconDimensions.height} />
              </Layout>
            )}
          />
          <DrawerItem
            title={() => <Text style={styles.UIKDrawerItemText}>Campaigns</Text>}
            style={styles.UIKDrawerItem}
            accessoryLeft={() => (
              <Layout style={styles.iconContainer}>
                <BubbleChart width={iconDimensions.width} height={iconDimensions.height} />
              </Layout>
            )}
          />
          <DrawerItem
            title={() => <Text style={styles.UIKDrawerItemText}>FAQ</Text>}
            style={styles.UIKDrawerItem}
            accessoryLeft={() => (
              <Layout style={styles.iconContainer}>
                <DocumentSearch width={iconDimensions.width} height={iconDimensions.height} />
              </Layout>
            )}
          />
        </UIKDrawer>
        <Divider style={styles.divider} />
        {store.me ? (
          // Logged in
          <>
            <UIKDrawer
              onSelect={(index) => {
                if (store.me) {
                  navigation.navigate(loginDrawerItemRoutes[index.row]);
                }
              }}
              appearance="noDivider"
              style={styles.UIKDrawer}
            >
              <DrawerItem
                title={() => <Text style={styles.UIKDrawerItemText}>My Campaigns</Text>}
                style={styles.UIKDrawerItem}
                accessoryLeft={() => (
                  <Layout style={styles.iconContainer}>
                    <EnvelopeHeart width={iconDimensions.width} height={iconDimensions.height} />
                  </Layout>
                )}
              />
              <DrawerItem
                title={() => <Text style={styles.UIKDrawerItemText}>Settings</Text>}
                style={styles.UIKDrawerItem}
                accessoryLeft={() => (
                  <Layout style={styles.iconContainer}>
                    <Settings width={iconDimensions.width} height={iconDimensions.height} />
                  </Layout>
                )}
              />
            </UIKDrawer>
            <Layout style={styles.userButtons}>
              <TouchableOpacity onPress={handleAvatar}>
                {store.me?.photo ? (
                  <Avatar
                    source={{
                      uri: store.me?.photoSizes?.medium || store.me?.photoSizes?.big,
                      cache: "force-cache",
                    }}
                    style={styles.avatar}
                  />
                ) : (
                  <ProfileChip width={avatarDimensions.width} height={avatarDimensions.height} />
                )}
              </TouchableOpacity>
              {/*
              <TouchableOpacity onPress={handleMessages}>
                <Envelope width={iconDimensions.width} height={iconDimensions.height} />
              </TouchableOpacity>
              <TouchableOpacity onPress={handleNotifications}>
                <Bell width={iconDimensions.width} height={iconDimensions.height} />
              </TouchableOpacity>
              */}
            </Layout>
            <Layout style={styles.logoutLink}>
              <TouchableOpacity onPress={handleLogout}>
                <Text style={styles.logoutLinkText}>Logout</Text>
              </TouchableOpacity>
            </Layout>
          </>
        ) : (
          // Not logged in
          <>
            <Button
              text="Login"
              onPress={handleLogin}
              type="greenTertiary"
              containerStyle={styles.loginButton}
            />
            <Button
              text="Start a Campaign"
              onPress={handleStartCampaign}
              containerStyle={styles.startCampaignButton}
            />
          </>
        )}
      </Layout>
    </>
  );
};

export default observer(DrawerContent);

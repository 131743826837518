import React from "react";
import InlineSVG, { InlineSVGProps } from "svg-inline-react";
import DocumentSearchSVG from "../../assets/document_search.svg";

interface Props extends Omit<InlineSVGProps, "src" | "raw"> {}

const DocumentSearch = (props: Props) => <InlineSVG src={DocumentSearchSVG} raw {...props} />;

DocumentSearch.defaultProps = {
  style: undefined,
};

export default DocumentSearch;

import { useNavigation } from "@react-navigation/native";
import { Text } from "@ui-kitten/components";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { StyleSheet, ViewProps } from "react-native";
import styled from "rn-css";
import { useForm } from "react-hook-form";
import useListener from "../hooks/useListener";
import Campaign from "../store/models/Campaign";
import { FlexCol, FlexRow } from "../styles/containers";
import Button from "./Button";
import CampaignThumbnail from "./CampaignThumbnail";
import DonateForm, {
  defaultValues,
  DonateFormCleanedData,
  getCleanedData,
} from "./forms/DonateForm";
import Divider from "./Divider";
import HorizontalRule from "./HorizontalRule";

const styles = StyleSheet.create({
  grayColor: {
    color: "#abadaf",
  },
  bold: {
    fontFamily: "Jost_600SemiBold",
    fontWeight: "600",
  },
  backButton: {
    maxWidth: 170,
    width: "100%",
    alignSelf: "center",
  },
});

const Header = styled(Text)`
  font-family: Jost_500Medium;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 35px;
  font-feature-settings: "pnum" on, "lnum" on;
  color: #2e3338;
`;

const Subheader = styled(Text)`
  font-family: Jost_400Regular;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  font-feature-settings: "pnum" on, "lnum" on;
  color: #757f87;
`;

const InfoText = styled(Text)`
  padding: 0px 8px;
  font-family: Jost_400Regular;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  font-feature-settings: "pnum" on, "lnum" on;
  color: #757f87;
`;

const SpacedBetween = styled(FlexRow)`
  justify-content: space-between;
  gap: 12px;
`;

const Container = styled(FlexCol)`
  padding: 64px 64px 24px;
  gap: 24px;
  width: 538px;
  background: #ffffff;
  box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.06);
  border-radius: 10px;

  @media (max-width: 768px) {
    padding: 48px 20px;
    width: 100%;
  }
`;

export interface CampaignDonateDialogProps extends ViewProps {
  campaign: Campaign;
  onSubmit: (data?: DonateFormCleanedData) => void;
  submitDisabled?: boolean;
}

const CampaignDonateDialog: React.FC<CampaignDonateDialogProps> = ({
  campaign,
  onSubmit,
  submitDisabled = false,
  ...props
}) => {
  const navigation = useNavigation();
  const [amount, setAmount] = useState<number>(getCleanedData(defaultValues).amount);

  const onBack = useListener(() => {
    if (navigation.canGoBack()) {
      navigation.goBack();
    } else {
      navigation.navigate("Dashboard");
    }
  });

  const onFormChange = useListener((data: { amount: number }) => {
    setAmount(data.amount);
  });

  const onFormSubmit = useListener((data: { amount: string }) => {
    onSubmit(getCleanedData(data));
  });

  const formMethods = useForm({ defaultValues });

  const amountStr = Number.isNaN(amount) ? "0.00" : amount.toFixed(2);

  return (
    <Container {...props}>
      <Header>Make a Donation</Header>
      <Subheader>
        You are making a donation to{" "}
        <Subheader style={styles.bold}>{campaign.name ?? ""}</Subheader>
      </Subheader>
      <Divider />
      <CampaignThumbnail campaign={campaign} />
      <DonateForm formMethods={formMethods} onChange={onFormChange} />
      <HorizontalRule />
      <SpacedBetween>
        <InfoText>Your Donation</InfoText>
        <InfoText>{amountStr} cUSD</InfoText>
      </SpacedBetween>
      <Button
        disabled={!!formMethods.formState.errors.amount || submitDisabled}
        type="greenPrimary"
        text="Continue"
        onPress={formMethods.handleSubmit(onFormSubmit)}
      />
      <Button type="link" text="Back" containerStyle={styles.backButton} onPress={onBack} />
    </Container>
  );
};

export default observer(CampaignDonateDialog);

import React, { useState } from "react";
import { StyleSheet, Text, View } from "react-native";
import styled from "styled-components/native";
import { useNavigation } from "@react-navigation/native";
import Button from "./Button";
import useWindowSize from "../hooks/useWindowSize";
import FeaturedCampaignsCarousel from "./FeaturedCampaignsCarousel";
import useCampaignList from "../hooks/useCampaignList";
import GreenDivider from "./Divider";

const styles = StyleSheet.create({
  container: {},
  containerMobile: {
    paddingTop: 64,
    paddingBottom: 64,
    paddingLeft: 20,
    paddingRight: 20,
    gap: 24,
  },
  heading: {},
  headingMobile: {
    fontSize: 36,
    lineHeight: 52,
  },
  button: {
    width: 343,
    alignSelf: "center",
  },
  subHeading: {},
  subHeadingMobile: {
    fontSize: 16,
    lineHeight: 23,
  },
});

const Container = styled(View)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  gap: 32px;
  padding: 124px;
  background-color: #ffffff;
  flex-grow: 0;
`;

const Header = styled(View)`
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 24px;
`;

const Heading = styled(Text)`
  font-family: "Jost_500Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 52px;
  line-height: 75px;
  color: #2e3338;
  flex-grow: 0;
  font-feature-settings: "pnum" on, "lnum" on;
`;

const Subheading = styled(Text)`
  font-family: "Jost_500Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  color: #757f87;
  flex-grow: 0;
`;

const FeaturedCampaigns = () => {
  const { isMobile } = useWindowSize();
  const navigation = useNavigation();

  const [filter] = useState({ searchParams: { excludeDrafts: true, ordering: "-created" } });
  const { results: campaigns } = useCampaignList(filter);
  const featuredCampaigns = campaigns.length > 4 ? campaigns.slice(0, 4) : campaigns;

  const handleExploreCampaigns = () => {
    navigation.navigate("Categories");
  };

  return (
    <Container style={[styles.container, isMobile && styles.containerMobile]}>
      <Header>
        <Heading style={[styles.heading, isMobile && styles.headingMobile]}>
          Featured Campaigns
        </Heading>
        <Subheading style={[styles.subHeading, isMobile && styles.subHeadingMobile]}>
          Make a difference today.
        </Subheading>
        <GreenDivider />
      </Header>
      <FeaturedCampaignsCarousel featuredCampaigns={featuredCampaigns} />
      <Button
        text="Explore More Campaigns"
        onPress={handleExploreCampaigns}
        type="bluePrimary"
        containerStyle={styles.button}
      />
    </Container>
  );
};

export default FeaturedCampaigns;

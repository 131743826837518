import React, { useCallback } from "react";
import { Icon, Text } from "@ui-kitten/components";
import { useNavigation, StackActions } from "@react-navigation/native";
import { StyleProp, StyleSheet, TextStyle, ViewStyle } from "react-native";
import Button from "../Button";

interface Props {
  onPress?: () => Promise<void>;
  showCloseIcon?: boolean;
  style?: StyleProp<ViewStyle>;
  text?: string;
  textStyle?: StyleProp<TextStyle>;
}

const styles = StyleSheet.create({
  icon: {
    marginLeft: 0,
    marginRight: 10,
    height: 20,
    width: 20,
  },
  backButton: {
    width: "fit-content",
  },
  buttonText: {
    color: "inherit",
  },
});

const BackButton: React.FC<Props> = ({
  onPress,
  showCloseIcon = false,
  style,
  text,
  textStyle,
}) => {
  const navigation = useNavigation();

  const handleBackPress = useCallback(async () => {
    if (navigation.canGoBack()) {
      navigation.goBack();
    } else {
      navigation.dispatch(StackActions.popToTop());
    }
  }, [navigation]);

  return (
    <Button
      containerStyle={styles.backButton}
      onPress={onPress ?? handleBackPress}
      style={style}
      type="blackGhost"
    >
      {(evaProps) => (
        <>
          <Icon
            style={styles.icon}
            name={showCloseIcon ? "close-outline" : "arrow-ios-back-outline"}
            fill="inherit"
          />
          <Text {...evaProps} style={[styles.buttonText, textStyle]}>
            {text}
          </Text>
        </>
      )}
    </Button>
  );
};

export default BackButton;

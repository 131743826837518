import React from "react";
import { StyleSheet, ImageBackground, View, StyleProp, ImageStyle } from "react-native";
import { observer } from "mobx-react-lite";

type Props = React.PropsWithChildren<{
  dim?: boolean;
  uri?: string | null;
  width?: string | number;
  height?: string | number;
  style?: StyleProp<ImageStyle>;
}>;

const styles = StyleSheet.create({
  cover: {
    display: "flex",
    marginBottom: -1,
  },
  coverTint: {
    flex: 1,
    display: "flex",
    alignItems: "center",
    alignSelf: "stretch",
    justifyContent: "center",
    backgroundColor: "rgba(0, 0, 0, 0.45)",
  },
});

const genericCoverUri = "";

const Cover: React.FC<Props> = ({
  children,
  dim = true,
  uri,
  width = "100%",
  height = 140,
  style,
}) => {
  return (
    <ImageBackground
      style={[styles.cover, { width, height }, style]}
      source={{
        uri: uri || genericCoverUri,
        cache: "force-cache",
      }}
    >
      {children ? <View style={dim && styles.coverTint}>{children}</View> : null}
    </ImageBackground>
  );
};

export default observer(Cover);

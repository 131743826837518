import { View } from "react-native";
import styled from "rn-css";

const Divider = styled(View)`
  width: 100%;
  max-width: 57px;
  min-height: 4px;
  max-height: 4px;
  background: #35d07f;
  border-radius: 99999px;
`;

export default Divider;

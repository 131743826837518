import React, { useEffect, useRef, useState } from "react";
import {
  LayoutChangeEvent,
  NativeScrollEvent,
  NativeSyntheticEvent,
  ScrollView as ScrollViewBase,
  StyleProp,
  View,
  ViewProps,
  ViewStyle,
} from "react-native";
import styled from "styled-components/native";
import { clamp } from "lodash";
import PaginationBase from "./Pagination";
import useListener from "../hooks/useListener";
import { FlexRow } from "../styles/containers";

const ScrollView = styled(ScrollViewBase)`
  flex: auto;
`;

const ItemsWrapper = styled(FlexRow)`
  width: fit-content;
`;

const CarouselItem = styled(View)<{ interval: number }>`
  width: ${({ interval }) => interval}px;
`;

const Container = styled(FlexRow)`
  justify-content: center;
  overflow: hidden;
`;

export interface CarouselProps extends ViewProps {
  children: JSX.Element[];
  selectedIndex?: number;
  viewItemCount?: number;
  customStyles?: {
    carousel?: StyleProp<ViewStyle>;
    flexWrapper?: StyleProp<ViewStyle>;
    itemWrapper?: StyleProp<ViewStyle>;
  };
  renderItem?: (item: JSX.Element) => JSX.Element;
  onSelectIndex?: (index: number) => void;
  pagination?: typeof PaginationBase;
}

const Carousel: React.FC<CarouselProps> = ({
  children,
  selectedIndex = 0,
  viewItemCount = 1,
  customStyles: { carousel, flexWrapper, itemWrapper } = {},
  renderItem,
  onSelectIndex,
  pagination,
  ...props
}) => {
  const ref = useRef() as React.RefObject<ScrollViewBase>;
  const [index, setIndex] = React.useState(selectedIndex);
  const [pageCount, setPageCount] = useState<number>(0);
  const [snapInterval, setSnapInterval] = useState<number>(0);

  const onSelect = useListener((whichIndex: number) => {
    ref?.current?.scrollTo({ x: whichIndex * snapInterval, animated: true });
    setIndex(whichIndex);
    onSelectIndex?.(whichIndex);
  });

  const onViewLayout = useListener((e: LayoutChangeEvent) => {
    const {
      nativeEvent: {
        layout: { width },
      },
    } = e;

    const viewCount = clamp(viewItemCount, 1, children.length);
    setSnapInterval(width / viewCount);
    setPageCount(Math.max(1, children.length - viewCount + 1));
  });

  const onViewScroll = useListener((e: NativeSyntheticEvent<NativeScrollEvent>) => {
    const {
      nativeEvent: {
        contentOffset: { x },
      },
    } = e;

    onSelect(Math.floor((x + snapInterval / 2) / snapInterval));
  });

  useEffect(
    useListener(() => {
      if (selectedIndex !== index) {
        onSelect(selectedIndex);
      }
    }),
    [selectedIndex]
  );

  const Pagination = pagination;

  return (
    <Container {...props}>
      <ScrollView
        horizontal
        showsHorizontalScrollIndicator={false}
        showsVerticalScrollIndicator={false}
        snapToInterval={snapInterval}
        onLayout={onViewLayout}
        onScroll={onViewScroll}
        style={carousel}
        ref={ref}
      >
        <ItemsWrapper style={flexWrapper}>
          {children.map((child) =>
            renderItem ? (
              renderItem(child)
            ) : (
              <CarouselItem key={child.key} interval={snapInterval} style={itemWrapper}>
                {child}
              </CarouselItem>
            )
          )}
        </ItemsWrapper>
      </ScrollView>
      {Pagination && <Pagination count={pageCount} onSelectIndex={onSelect} />}
    </Container>
  );
};

export default Carousel;

import React from "react";
import { Image, StyleSheet, View, ViewProps } from "react-native";
import { Text } from "@ui-kitten/components";
import styled from "styled-components/native";
import PressableIcon from "./PressableIcon";
import WalletCard from "./WalletCard";
// import CeloWallet from "./svgs/CeloWallet";
import ValoraWallet from "../assets/valora_wallet.png";

interface ContainerProps extends ViewProps {
  isMobile: boolean;
}

type WalletStatus = "" | "connected" | "linked";

const styles = StyleSheet.create({
  connectedWallet: {
    backgroundColor: "#35D07F0D",
  },
});

const Container = styled(View)<ContainerProps>`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 36px;
  gap: 24px;
  width: ${({ isMobile }) => (isMobile ? "335px" : "420px")};
  background: #ffffff;
  box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.06);
  border-radius: 10px;
`;

const CloseIcon = styled(PressableIcon)`
  width: 24px;
  height: 24px;
  position: absolute;
  right: 15px;
  top: 15px;
`;

const Heading = styled(Text)`
  font-family: "Jost_500Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  display: flex;
  font-feature-settings: "pnum" on, "lnum" on;
  color: #2e3338;
`;

interface Props extends ContainerProps {
  onClose: () => void;
  onSelectWalletPress: (wallet: any) => void;
  hasValoraWallet?: boolean;
}

const WalletSelectDialog: React.FC<Props> = ({
  onClose,
  onSelectWalletPress,
  hasValoraWallet = false,
  ...props
}) => {
  const walletOptions = [
    {
      icon: <Image source={ValoraWallet} />,
      name: "Valora",
      caption: "Connect to Valora, a mobile payment app that works worldwide",
      status: hasValoraWallet ? "connected" : ("" as WalletStatus),
    },
    // {
    //   icon: <CeloWallet />,
    //   name: "Celo Wallet",
    //   caption: "Connect to a Celo Wallet for web or desktop",
    //   status: "" as "" | "connected" | "linked",
    // },
  ];

  return (
    <Container {...props}>
      <CloseIcon iconName="close" fill="#2e3338" onPress={onClose} />
      <Heading>Connect Your Wallet</Heading>
      {walletOptions.map((wallet) => (
        <WalletCard
          key={wallet.name}
          icon={wallet.icon}
          name={wallet.name}
          caption={wallet.caption}
          status={wallet.status}
          onPress={wallet.status !== "connected" ? () => onSelectWalletPress(wallet) : undefined}
          selectable={wallet.status !== "connected"}
          style={[wallet.status === "connected" && styles.connectedWallet]}
        />
      ))}
    </Container>
  );
};

export default WalletSelectDialog;

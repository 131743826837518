import { valueToBigNumber } from "@celo/contractkit/lib/wrappers/BaseWrapper";
import { EvaProp, Layout, withStyles, Text, Divider } from "@ui-kitten/components";
import { observer } from "mobx-react-lite";
import React, { useCallback } from "react";
import { useForm } from "react-hook-form";
import { APICampaignCreate, APICampaignInput } from "../api/campaigns";
import CampaignStep1Form from "./forms/CampaignStep1Form";
import Button from "./Button";

interface Props {
  eva?: EvaProp;
  disabled?: boolean;
  goalEditable?: boolean;
  state: APICampaignInput;
  onNext: (data: APICampaignCreate) => Promise<void>;
  onBack?: () => boolean;
  termsAccepted?: boolean;
  handleAcceptTerms?: (status: boolean) => void;
}

const CampaignCreateStep1: React.FC<Props> = ({
  eva,
  disabled,
  goalEditable = true,
  state,
  onNext,
  onBack,
  termsAccepted = false,
  handleAcceptTerms = () => {},
}) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  // const [nextButtonEnabled, setNextButtonEnabled] = useState(false);

  const onSubmit = useCallback(
    async (data: APICampaignInput) => {
      await onNext({ ...data, goal: valueToBigNumber(data.goal || 0) });
    },
    [onNext]
  );

  return (
    <Layout style={eva?.style?.container}>
      <Layout style={eva?.style?.form}>
        <Text style={eva?.style?.titleText}>Start a Campaign</Text>
        <Text style={eva?.style?.subtitleText}>Basic information for your campaign. </Text>
        <Divider style={eva?.style?.miniDivider} />
        <CampaignStep1Form
          control={control}
          errors={errors}
          goalEditable={goalEditable}
          state={state}
          termsAccepted={termsAccepted}
          handleAcceptTerms={handleAcceptTerms}
        />
      </Layout>
      <Layout style={eva?.style?.buttons}>
        <Button
          disabled={disabled}
          onPress={() => {
            if (onBack) onBack();
          }}
          containerStyle={eva?.style?.back}
          text="Back"
          type="blackGhost"
          removePadding
        />
        <Button
          disabled={disabled || !termsAccepted}
          status="primary"
          onPress={handleSubmit(onSubmit)}
          containerStyle={eva?.style?.next}
          text="Next"
          type="greenPrimary"
        />
      </Layout>
    </Layout>
  );
};

export default withStyles(observer(CampaignCreateStep1), () => ({
  container: {
    display: "flex",
    flex: 1,
    rowGap: 48,
  },
  form: {
    flex: 1,
    rowGap: 12,
  },
  next: {
    height: 40,
    maxWidth: 240,
    flex: 1,
  },
  back: {
    height: 40,
    maxWidth: 64,
    flex: 1,
  },
  buttons: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    columnGap: 12,
  },
  titleText: {
    fontFamily: "Jost_500Medium",
    fontWeight: "500",
    fontSize: 24,
    lineHeight: 35,
  },
  subtitleText: {
    fontFamily: "Jost_400Regular",
    fontWeight: "400",
    fontSize: 16,
    lineHeight: 23,
    color: "#757f87",
  },
  miniDivider: {
    backgroundColor: "#35d07f",
    height: 4,
    width: 57,
    radius: 100,
    marginTop: 12,
    marginBottom: 12,
  },
}));

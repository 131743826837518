import React from "react";
import { Image as ImageBase, ImageSourcePropType, View } from "react-native";
import { Text } from "@ui-kitten/components";
import styled from "rn-css";
import Accordion from "../components/Accordion";
import Markdown from "../components/Markdown";
import MainLayoutBase, { MainLayoutProps } from "../layouts/MainLayout";
import { FlexCol } from "../styles/containers";
import fundingACampaign from "../assets/images/faq_funding-a-campaign.png";
import creatingACampaign from "../assets/images/faq_creating-a-campaign.png";
import payingThroughValora from "../assets/images/faq_paying-through-valora.png";
import faq from "../assets/data/faq.json";
import faqQuestions from "../assets/data/faq_questions.json";

const images = [
  { source: fundingACampaign, width: 262, height: 217 },
  { source: creatingACampaign, width: 218, height: 261 },
  { source: payingThroughValora, width: 248, height: 181 },
];

const Image = styled(ImageBase)`
  align-self: center;
`;

const HorizontalRule = styled(View)`
  height: 1px;
  background: #dddddd;
`;

const Body = styled(FlexCol)`
  gap: 48px;

  @media (max-width: 768px) {
    gap: 24px;
  }
`;

const ArticleWrapper = styled(FlexCol)`
  gap: 48px;
`;

const SectionContainer = styled(FlexCol)`
  gap: 24px;
`;

const SectionHeader = styled(Text)`
  font-family: Jost_500Medium;
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 46px;
  text-align: center;
  font-feature-settings: "pnum" on, "lnum" on;
  color: #2e3338;

  @media (max-width: 768px) {
    font-size: 24px;
    line-height: 35px;
  }
`;

const ImageWrapper = styled(View)`
  padding: 40px;
`;

const MainLayout = styled(MainLayoutBase)`
  align-self: center;
  width: 100%;
  max-width: 818px;
  padding: 90px 20px;

  @media (max-width: 768px) {
    padding: 58px 20px;
  }
`;

const Section = ({
  imgData: { source, width, height },
  title,
  content,
}: {
  imgData: { source: ImageSourcePropType; width: number; height: number };
  title: string;
  content: string;
}) => {
  return (
    <SectionContainer>
      <ImageWrapper>
        <Image source={source} style={{ width, height }} />
      </ImageWrapper>
      <SectionHeader>{title}</SectionHeader>
      <Markdown>{content}</Markdown>
    </SectionContainer>
  );
};

interface Props extends MainLayoutProps {}

const FAQScreen: React.FC<Props> = (props) => {
  return (
    <MainLayout alignStretch={false} {...props}>
      <Body>
        <Accordion headerComponent={<Markdown># How It Works</Markdown>} collapsible={false}>
          <ArticleWrapper>
            {faq.articles.map(({ title, body }, index) => (
              <Section key={title} imgData={images[index]} title={title} content={body} />
            ))}
          </ArticleWrapper>
        </Accordion>
        {faqQuestions && faqQuestions.length > 0 && (
          <>
            <HorizontalRule />
            <Markdown># More FAQs</Markdown>
            {faqQuestions.map(({ title, body }) => (
              <Accordion headerComponent={<Markdown>{title}</Markdown>} collapsible>
                <Markdown>{body}</Markdown>
              </Accordion>
            ))}
          </>
        )}
      </Body>
    </MainLayout>
  );
};

export default FAQScreen;

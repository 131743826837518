import React from "react";
import InlineSVG, { InlineSVGProps } from "svg-inline-react";
import EnvelopeHeartSVG from "../../assets/envelope_heart.svg";

interface Props extends Omit<InlineSVGProps, "src" | "raw"> {}

const EnvelopeHeart = (props: Props) => <InlineSVG src={EnvelopeHeartSVG} raw {...props} />;

EnvelopeHeart.defaultProps = {
  style: undefined,
};

export default EnvelopeHeart;

import React, { useCallback, useEffect, useState } from "react";
import {
  Avatar,
  Text,
  Icon,
  withStyles,
  EvaProp,
  Layout,
  Button,
  Divider,
} from "@ui-kitten/components";
import * as Linking from "expo-linking";
import { observer } from "mobx-react-lite";
import { View } from "native-base";
import { useNavigation } from "@react-navigation/native";
import api from "../api";
import StyledButton from "./Button";
import { useStore } from "../store";
import User from "../store/models/User";
import CampaignGallery from "./CampaignGallery";
import useListener from "../hooks/useListener";
import useUserCampaignList from "../hooks/useUserCampaignList";
import { FlexCol } from "../styles/containers";
import NoCampaign from "./svgs/NoCampaign";
import useWindowSize from "../hooks/useWindowSize";

interface Props {
  user: User;
  onCampaignSharePress?: (...args: any[]) => void;
  eva?: EvaProp;
}

const FundraiserDetail: React.FC<Props> = ({ user, eva, onCampaignSharePress = () => {} }) => {
  const { me } = useStore();
  const { isMobile } = useWindowSize();
  const navigation = useNavigation();
  const { results: campaigns } = useUserCampaignList({ user });
  const [defaultValues, setDefaultValues] = useState(user?.asAPI);

  // Update page after (user's own) profile is modified
  useEffect(() => {
    if (me?.id === user?.id) {
      setDefaultValues(me?.asAPI);
    }
  }, [me, user]);

  const handleSocialPress = async (trigger?: string) => {
    if (trigger) {
      try {
        await Linking.openURL(trigger);
      } catch (e) {
        await api.handleError(e);
      }
    }
  };

  const handleEditPress = useCallback(() => {
    navigation.navigate("EditProfile");
  }, [navigation]);

  const onCampaignManagePress = useListener((campaign) => {
    navigation.navigate("CampaignEdit", { id: campaign.id });
  });

  const onStartCampaignPress = useListener(() => {
    if (me) {
      navigation.navigate("CampaignCreate");
    } else {
      navigation.navigate("SignIn", { next: "campaign-create" });
    }
  });

  return (
    <Layout style={eva?.style?.wrapper}>
      <Layout style={eva?.style?.profileContainer}>
        {defaultValues?.name && <Text style={eva?.style?.title}>{defaultValues?.name}</Text>}
        <Divider style={eva?.style?.miniDivider} />
        <View style={eva?.style?.centeredProfileContainer}>
          <View style={eva?.style?.avatarWrapper}>
            {defaultValues?.photo && (
              <Avatar
                height={160}
                source={{ uri: defaultValues?.photo, cache: "force-cache" }}
                size="giant"
                style={eva?.style?.avatar}
              />
            )}
          </View>

          {defaultValues?.location && (
            <View style={eva?.style?.tag}>
              <Icon
                width={20}
                height={20}
                fill="#35D07F"
                name="pin"
                style={eva?.style?.locationIcon}
              />
              <Text style={eva?.style?.locationText}>{defaultValues?.location}</Text>
            </View>
          )}

          {/* TODO: Styling of social icons in fundraiser profile */}
          <View style={eva?.style?.tag}>
            {defaultValues?.twitter && (
              <Button
                appearance="ghost"
                accessoryLeft={() => (
                  <Icon name="twitter" fill="#55ACEE" style={eva?.style?.socialsIcon} />
                )}
                onPress={() => handleSocialPress(defaultValues?.twitter)}
              />
            )}

            {defaultValues?.facebook && (
              <Button
                appearance="ghost"
                accessoryLeft={() => (
                  <Icon name="facebook" fill="#3B5998" style={eva?.style?.socialsIcon} />
                )}
                onPress={() => handleSocialPress(defaultValues?.facebook)}
              />
            )}

            {defaultValues?.linkedin && (
              <Button
                appearance="ghost"
                accessoryLeft={() => (
                  <Icon name="linkedin" fill="#2867b2" style={eva?.style?.socialsIcon} />
                )}
                onPress={() => handleSocialPress(defaultValues?.linkedin)}
              />
            )}
          </View>
        </View>
        {defaultValues?.bio && <Text style={eva?.style?.bio}>{defaultValues?.bio}</Text>}
        <View style={eva?.style?.buttonContainer}>
          {user && me?.id === user?.id ? (
            <StyledButton
              status="primary"
              type="greenTertiary"
              containerStyle={eva?.style?.button}
              onPress={handleEditPress}
              text="Edit Profile"
            />
          ) : (
            <StyledButton
              type="blueSecondary"
              containerStyle={eva?.style?.button}
              // TODO: Handle pressing "Message" button
              onPress={() => {}}
            >
              {() => (
                <>
                  <Text style={eva?.style?.messageButtonText}>Message</Text>
                  <Icon name="email-outline" style={eva?.style?.messageButtonIcon} />
                </>
              )}
            </StyledButton>
          )}

          <StyledButton
            type="greenSecondary"
            containerStyle={eva?.style?.button}
            // TODO: Handle pressing "Share" button
            onPress={() => {}}
          >
            {() => (
              <>
                <Text style={eva?.style?.shareButtonText}>Share</Text>
                <Icon name="share-outline" style={eva?.style?.shareButtonIcon} />
              </>
            )}
          </StyledButton>
        </View>
      </Layout>
      {/* TODO: Add message when there are 0 matching campaigns */}
      {campaigns.length > 0 && (
        <CampaignGallery
          campaigns={campaigns}
          showFundingMeters
          showDeadlines
          onCampaignManagePress={onCampaignManagePress}
          onCampaignSharePress={onCampaignSharePress}
        />
      )}
      {campaigns.length === 0 && (
        <FlexCol style={eva?.style?.emptyCampaignWrapper}>
          {me?.id === user?.id && (
            <>
              <Text style={[eva?.style?.title, eva?.style?.centerText]}>
                No Campaigns Created Yet
              </Text>
              <Text style={[eva?.style?.subtitle, eva?.style?.centerText]}>
                We&rsquo;re looking forward to seeing your first campaign!
              </Text>
              <NoCampaign />
              {!isMobile && (
                <StyledButton
                  text="Start a Campaign"
                  type="greenPrimary"
                  containerStyle={eva?.style?.noCampaignButton}
                  onPress={onStartCampaignPress}
                />
              )}
            </>
          )}
          {me?.id !== user?.id && (
            <Text style={[eva?.style?.subtitle, eva?.style?.centerText, eva?.style?.fadeText]}>
              This user has no campaigns.
            </Text>
          )}
        </FlexCol>
      )}
    </Layout>
  );
};

export default withStyles(observer(FundraiserDetail), (theme) => ({
  wrapper: {
    display: "flex",
    rowGap: 48,
  },
  profileContainer: {
    maxWidth: 410,
    width: "100%",
    alignItems: "flex-start",
    marginHorizontal: "auto",
  },
  centeredProfileContainer: {
    alignItems: "center",
    width: "100%",
    marginBottom: 12,
    rowGap: 12,
  },
  avatarWrapper: {
    width: 150,
    height: 150,
    position: "relative",
    backgroundColor: theme["background-basic-color-3"],
    borderRadius: 75,
    marginBottom: 12,
  },
  avatar: {
    width: 150,
    height: 150,
    position: "absolute",
  },
  tag: {
    display: "flex",
    flexDirection: "row",
  },
  title: {
    width: "100%",
    fontFamily: "Jost_500Medium",
    fontWeight: "500",
    fontSize: 24,
    lineHeight: 35,
  },
  subtitle: {
    width: "100%",
    fontFamily: "Jost_400Regular",
    fontWeight: "400",
    fontSize: 18,
    lineHeight: 26,
  },
  locationText: {
    textAlign: "center",
    fontFamily: "Jost_400Regular",
    fontWeight: "400",
    fontSize: 14,
    lineHeight: 20,
  },
  locationIcon: {
    marginRight: 6,
  },
  bio: {
    textAlign: "left",
    fontFamily: "Jost_400Regular",
    fontWeight: "400",
    fontSize: 16,
    marginBottom: 24,
    color: theme["color-basic-500"],
  },
  socialsIcon: {
    width: 30,
    height: 30,
  },
  miniDivider: {
    backgroundColor: "#35d07f",
    height: 4,
    width: 57,
    radius: 100,
    marginTop: 24,
    marginBottom: 24,
  },

  centerText: {
    textAlign: "center",
  },
  fadeText: {
    color: "#757f87",
  },

  buttonContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    columnGap: 12,
  },
  button: {
    flex: 1,
    height: 40,
  },
  shareButtonText: {
    fontFamily: "Jost_500Medium",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: 15,
    lineHeight: 22,
    letterSpacing: 0.02,
    color: "#27a563",
  },
  shareButtonIcon: {
    paddingLeft: 7,
    width: 16,
    height: 16,
    fill: "#27a563",
  },
  messageButtonText: {
    fontFamily: "Jost_500Medium",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: 15,
    lineHeight: 22,
    letterSpacing: 0.02,
    color: "#3488EC",
  },
  messageButtonIcon: {
    paddingLeft: 7,
    width: 16,
    height: 16,
    fill: "#3488EC",
  },

  emptyCampaignWrapper: {
    justifyContent: "center",
    alignItems: "center",
    rowGap: 12,
  },
  noCampaignButton: {
    width: 343,
    height: 40,
    marginTop: 12,
  },
}));

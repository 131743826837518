import { EvaProp, Text, withStyles } from "@ui-kitten/components";
import React from "react";
import { View, ViewProps } from "react-native";
import { ScrollView, TouchableOpacity } from "react-native-gesture-handler";
import styled from "rn-css";
import { TabProps } from "./Tab";

/*
  Custom TabView, since the UIKitten on isn't that stylable.
  Only <Tab>s are supported as children.
*/

/* 
  This currently loads all tabs at once, but displays them one at a time.
  Maybe this should dynamically load instead?
*/

interface Props extends ViewProps {
  selectedIndex?: number;
  onSelect?: (index: number) => void;
  centerTabBar?: boolean;
  eva?: EvaProp;
  children?: React.ReactElement<TabProps> | React.ReactElement<TabProps>[];
}

const TabHighlightLine = styled(View)<{ selected: boolean }>`
  background-color: ${(props) => {
    if (props.selected) {
      return "#35d07f";
    } else {
      return "#00000000";
    }
  }};
  height: 4px;
  width: 100%;
  border-radius: 2px;
  margin-top: 16px;
`;

const TabBarLine = styled(View)`
  background-color: #edeeef;
  height: 1px;
  width: 100%;
  position: relative;
  margin-top: -2.5px;
  z-index: 0;
`;

const TabView: React.FC<Props> = ({
  selectedIndex = 0,
  onSelect,
  centerTabBar = false,
  eva,
  children,
}) => {
  const [selectedIndexInt, setSelectedIndexInt] = React.useState(selectedIndex);

  const onPressTabBarButton = (index: number) => {
    if (onSelect) onSelect(index);
    setSelectedIndexInt(index);
  };

  const renderSingleTabBarButton = (title: string, selected: boolean, index: number) => (
    <View style={eva?.style?.tabButton}>
      <TouchableOpacity onPress={() => onPressTabBarButton(index)}>
        <Text
          style={[eva?.style?.tabTitle, selected && eva?.style?.tabTitleSelected]}
          key={title + index}
        >
          {title}
        </Text>
      </TouchableOpacity>
      <TabHighlightLine selected={selected} />
    </View>
  );

  return (
    <View>
      <ScrollView
        horizontal
        showsHorizontalScrollIndicator={false}
        style={eva?.style?.tabBarContainer}
      >
        <View style={[eva?.style?.tabBar, centerTabBar && eva?.style?.tabBarCentered]}>
          {Array.isArray(children)
            ? React.Children.map(children, (child, index) =>
                renderSingleTabBarButton(child.props.title, index === selectedIndexInt, index)
              )
            : renderSingleTabBarButton(children?.props.title, true, 0)}
        </View>
      </ScrollView>
      <TabBarLine />
      <View style={eva?.style?.tabScreen}>
        {Array.isArray(children) ? children[selectedIndexInt] : children}
      </View>
    </View>
  );
};

export default withStyles(TabView, () => ({
  tabBarContainer: {
    position: "relative",
    zIndex: 10,
  },
  tabBar: {
    display: "flex",
    width: "100%",
    justifyContent: "flex-start",
    flexDirection: "row",
    columnGap: 36,
  },
  tabBarCentered: {
    justifyContent: "center",
  },
  tabTitle: {
    fontFamily: "Jost_500Medium",
    fontWeight: "500",
    fontSize: 16,
    lineHeight: 23,
    marginHorizontal: 1,
    color: "#757F87",
  },
  tabTitleSelected: {
    color: "#2E3338",
  },
  tabScreen: {
    marginTop: 24,
  },
}));

import React from "react";
import { KeyboardAvoidingView, StyleSheet } from "react-native";
import { SafeAreaView, SafeAreaViewProps } from "react-native-safe-area-context";

const styles = StyleSheet.create({
  safeArea: {
    display: "flex",
    flex: 1,
  },
  container: {
    display: "flex",
    flex: 1,
  },
});

const SafeArea: React.FC<SafeAreaViewProps> = ({ children, style, ...props }) => {
  return (
    <SafeAreaView style={[styles.safeArea, style]} {...props}>
      <KeyboardAvoidingView style={styles.container}>{children}</KeyboardAvoidingView>
    </SafeAreaView>
  );
};

export default SafeArea;

import React from "react";
import { Image as ImageBase, ImageSourcePropType, Text, View } from "react-native";
import styled from "styled-components/native";

const Container = styled(View)`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  padding: 0;
  width: 261.69px;
  flex-grow: 0;
`;

const ImageWrapper = styled(View)`
  width: 261.69px;
  height: 160px;
  overflow: hidden;
`;

const Image = styled(ImageBase)`
  width: auto;
  height: 100%;
  object-fit: cover;
`;

const Heading = styled(Text)`
  font-family: "Jost_500Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  text-align: center;
  color: #2e3338;
  flex-grow: 0;
`;

const Caption = styled(Text)`
  font-family: "Jost_400Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  color: #757f87;
  align-self: stretch;
  flex-grow: 0;
`;

interface Props {
  imgSrc: ImageSourcePropType;
  infoShort: string;
  infoExtended: string;
}

const AboutInfo: React.FC<Props> = ({ imgSrc, infoShort, infoExtended }) => (
  <Container>
    <ImageWrapper>
      <Image source={imgSrc} />
    </ImageWrapper>
    <Heading>{infoShort}</Heading>
    <Caption>{infoExtended}</Caption>
  </Container>
);

export default AboutInfo;

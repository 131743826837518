import React from "react";
import { Text as TextBase } from "@ui-kitten/components";
import { observer } from "mobx-react-lite";
import { Image, Pressable, PressableProps } from "react-native";
import useEffectOnce from "react-use/lib/useEffectOnce";
import styled from "rn-css";
import useListener from "../hooks/useListener";
import Category from "../store/models/Category";
import { FlexCol } from "../styles/containers";
import CoverBase from "./Cover";

const Icon = styled(Image)`
  width: 72px;
  height: 72px;
  tint-color: white;

  @media (max-width: 768px) {
    width: 48px;
    height: 48px;
  }
`;

const Text = styled(TextBase)`
  font-family: "Jost_500Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 35px;
  text-align: center;
  font-feature-settings: "pnum" on, "lnum" on;
  color: white;

  @media (max-width: 768px) {
    font-size: 18px;
    line-height: 26px;
  }
`;

const Cover = styled(CoverBase)`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const Overlay = styled(FlexCol)`
  align-items: center;
  gap: 23px;

  @media (max-width: 768px) {
    gap: 18px;
  }
`;

const Container = styled(Pressable)`
  width: 398px;
  height: 240px;
  border-radius: 4px;
  flex-grow: 1;
  overflow: hidden;
  cursor: pointer;
  transition-timing-function: ease-out;
  transition-duration: 0.3s;

  @media (max-width: 768px) {
    width: 161.5px;
    height: 140px;
  }

  &:hover {
    background: #5097716b;
  }
`;

interface Props extends Omit<PressableProps, "children" | "onPress"> {
  category: Category;
  onPress: (category: Category) => void;
}

const CategoryListItem: React.FC<Props> = ({ category, onPress, ...props }) => {
  useEffectOnce(() => {
    if (category.cover) {
      Image.prefetch(category.cover);
    }
  });

  const onItemPress = useListener(() => {
    onPress(category);
  });

  const categoryName = category.id ? category.name! : "All Campaigns";

  return (
    <Container onPress={onItemPress} {...props}>
      <Cover uri={category.cover} dim>
        <Overlay>
          <Icon source={{ uri: category.icon ?? "" }} />
          <Text numberOfLines={1}>{categoryName}</Text>
        </Overlay>
      </Cover>
    </Container>
  );
};

export default observer(CategoryListItem);

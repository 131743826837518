import React, { useCallback, useState } from "react";
import { Image, ImageSourcePropType, View, ViewProps } from "react-native";
import styled from "styled-components/native";
import CarouselBase from "./Carousel";
import PaginationBase from "./Pagination";
import HeroOverlay from "./HeroOverlay";

const Carousel = styled(CarouselBase)`
  flex: auto;
  height: 100%;
  width: 100%;
`;

interface CarouselContainerProps extends ViewProps {
  isMobile?: boolean;
}

const CarouselContainer = styled(View)<CarouselContainerProps>`
  height: calc(100vh - ${({ isMobile }) => (isMobile ? 63 : 65)}px);
  display: flex;
  justify-content: center;
  align-items: stretch;
  overflow: hidden;
`;

const CarouselImage = styled(Image)`
  object-fit: cover;
  width: auto;
  height: 100%;
`;

const Pagination = styled(PaginationBase)`
  gap: 12px;
  position: absolute;
  width: 60px;
  left: calc(50% - 60px / 2);
  top: 89.07%;
  bottom: 9.72%;
`;

interface Props {
  images: { source: ImageSourcePropType; key: string }[];
  isMobile?: boolean;
}

const HeroCarousel: React.FC<Props> = ({ images, isMobile = false }) => {
  const [selectedIndex, setSelectedIndex] = useState(0);

  const onSelectIndex = useCallback((index: number) => {
    setSelectedIndex(index);
  }, []);

  return (
    <CarouselContainer isMobile={isMobile}>
      <Carousel selectedIndex={selectedIndex} onSelectIndex={onSelectIndex}>
        {images.map(({ source, key }) => (
          <CarouselImage key={key} source={source} />
        ))}
      </Carousel>
      <HeroOverlay />
      <Pagination
        count={images.length}
        selectedIndex={selectedIndex}
        onSelectIndex={onSelectIndex}
      />
    </CarouselContainer>
  );
};

export default HeroCarousel;

import { Divider, EvaProp, Layout, Text, withStyles } from "@ui-kitten/components";
import React, { useCallback } from "react";
import { useForm } from "react-hook-form";
import { APICampaignInput } from "../api/campaigns";
import Button from "./Button";
import CampaignStep2Form from "./forms/CampaignStep2Form";

interface Props {
  eva?: EvaProp;
  disabled?: boolean;
  state: APICampaignInput;
  onNext: (data: APICampaignInput) => Promise<void>;
  onBack?: () => boolean;
}

const CampaignCreateStep2: React.FC<Props> = ({ eva, disabled, state, onNext, onBack }) => {
  const { control, handleSubmit } = useForm();

  const onSubmit = useCallback(
    async (data: APICampaignInput) => {
      await onNext(data);
    },
    [onNext]
  );

  return (
    <Layout style={eva?.style?.container}>
      <Layout style={eva?.style?.form}>
        <Text category="h1" style={eva?.style?.titleText}>
          Upload your cover photo
        </Text>
        <Text category="p1" style={eva?.style?.subtitleText} appearance="hint">
          An effective cover photo helps draw more supporters to your cause by
          <br />
          giving them a glimpse of what your campaign is all about.
        </Text>
        <Divider style={eva?.style?.miniDivider} />
        <CampaignStep2Form control={control} state={state} />
      </Layout>
      <Layout style={eva?.style?.buttons}>
        <Button
          disabled={disabled}
          onPress={() => {
            if (onBack) onBack();
          }}
          containerStyle={eva?.style?.back}
          text="Back"
          type="blackGhost"
          removePadding
        />
        <Button
          disabled={disabled}
          status="primary"
          onPress={handleSubmit(onSubmit)}
          containerStyle={eva?.style?.next}
          text="Next"
          type="greenPrimary"
        />
      </Layout>
    </Layout>
  );
};

export default withStyles(CampaignCreateStep2, () => ({
  container: {
    display: "flex",
    flex: 1,
    rowGap: 48,
  },
  form: {
    flex: 1,
    rowGap: 12,
  },
  next: {
    height: 40,
    maxWidth: 240,
    flex: 1,
  },
  back: {
    height: 40,
    maxWidth: 64,
    flex: 1,
  },
  buttons: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    columnGap: 12,
  },
  titleText: {
    fontFamily: "Jost_500Medium",
    fontWeight: "500",
    fontSize: 24,
    lineHeight: 35,
  },
  subtitleText: {
    fontFamily: "Jost_400Regular",
    fontWeight: "400",
    fontSize: 16,
    lineHeight: 23,
    color: "#757f87",
  },
  miniDivider: {
    backgroundColor: "#35d07f",
    height: 4,
    width: 57,
    radius: 100,
    marginTop: 12,
    marginBottom: 12,
  },
}));

import { Text } from "@ui-kitten/components";
import { observer } from "mobx-react-lite";
import React from "react";
import { ViewProps } from "react-native";
import styled from "rn-css";
import { FlexCol, FlexRow } from "../styles/containers";

const CampaignBalanceContainer = styled(FlexRow)`
  justify-content: space-between;
  gap: 12px;
  padding: 26px 24px;
  background: #35d07f;
  border-radius: 8px;
`;

const CampaignBalanceLabel = styled(Text)`
  font-family: Jost_500Medium;
  font-style: normal;
  font-size: 18px;
  line-height: 32px;
  color: #ffffff;
  letter-spacing: 0.01em;
  user-select: none;
`;

const CampaignBalanceAmount = styled(Text)`
  font-family: Jost_600SemiBold;
  font-style: normal;
  font-size: 24px;
  line-height: 32px;
  color: #ffffff;
  letter-spacing: 0.01em;
  user-select: none;
`;

export interface CampaignBalanceProps extends ViewProps {
  balance: string;
}

const CampaignBalance: React.FC<CampaignBalanceProps> = ({ balance }) => (
  <CampaignBalanceContainer>
    <CampaignBalanceLabel>Donation Balance</CampaignBalanceLabel>
    <CampaignBalanceAmount>cUSD {balance}</CampaignBalanceAmount>
  </CampaignBalanceContainer>
);

export default CampaignBalance;

import { Divider, Layout, LayoutProps } from "@ui-kitten/components";
import React from "react";
import { StyleSheet } from "react-native";

interface Props
  extends React.PropsWithChildren<{
      direction?: "horizontal" | "vertical";
      showDivider?: boolean;
    }>,
    LayoutProps {}

const styles = StyleSheet.create({
  footer: {
    display: "flex",
    paddingVertical: 24,
    paddingHorizontal: 38,
  },
  footerHorizontal: {
    flexDirection: "row",
  },
  footerVertical: {
    flexDirection: "column",
    alignItems: "stretch",
    justifyContent: "center",
  },
});

const Footer: React.FC<Props> = ({
  children,
  direction = "horizontal",
  showDivider = true,
  style,
}) => {
  return (
    <>
      {showDivider ? <Divider /> : null}
      <Layout
        style={[
          styles.footer,
          direction === "horizontal" ? styles.footerHorizontal : styles.footerVertical,
          style,
        ]}
      >
        {children}
      </Layout>
    </>
  );
};

export default Footer;

import * as eva from "@eva-design/eva";
import { NavigationContainer } from "@react-navigation/native";
import { LinkingOptions } from "@react-navigation/native/lib/typescript/src/types";
import { ApplicationProvider, IconRegistry } from "@ui-kitten/components";
import { EvaIconsPack } from "@ui-kitten/eva-icons";
import Toast from "react-native-toast-message";
import { observer } from "mobx-react-lite";
import { Container } from "native-base";
import { SafeAreaProvider } from "react-native-safe-area-context";
import WalletConnectProvider from "@walletconnect/web3-provider";
import { ContractKit, newKitFromWeb3 } from "@celo/contractkit";
import Web3 from "web3";
import React, { useCallback, useState } from "react";
import mapping from "../assets/mapping.json";
import theme from "../assets/theme.json";
import { config as ToastConfig } from "../components/Toast";
import WalletConnectContext, {
  WalletConnectParams,
} from "../components/contexts/WalletConnectContext";
import QRCodeModal from "../components/QRCodeModal";
import appConfig from "../configs/appConfig";
import Navigation from "./Navigation";

interface Props {}

const Root: React.FC<Props> = () => {
  const [kit, setKit] = useState<ContractKit | null>(null);
  const [busy, setBusy] = useState(false);
  const [qrCodeOpen, setQrCodeOpen] = useState(false);
  const [qrCodeUri, setQrCodeUri] = useState("");
  const [provider, setProvider] = useState<WalletConnectProvider | null>(null);

  const linking: LinkingOptions = {
    prefixes: [appConfig.reactUrl! as string],
    config: {
      screens: {
        Dashboard: "dashboard",
        CampaignCreate: "campaigns/create",
        CampaignDetail: "campaigns/:id",
        CampaignDonate: "campaigns/:id/donate",
        CampaignEdit: "campaigns/:id/edit",
        CampaignWithdraw: "campaigns/:id/withdraw",
        FundraiserDetail: "fundraisers/:id",
        EditProfile: "profile",
        SignIn: "sign-in",
        Categories: "categories",
        CampaignCategoryExplore: "categories/:categoryName",
        WalletConnect: "wallet/connect",
        TermsAndCondition: "terms-and-condition",
        PrivacyPolicy: "privacy-policy",
        FAQ: "faq",
        Home: "",
        Settings: "settings",
        error: "*",
      },
    },
  };

  const connectWallet = useCallback(
    async (keepWalletOpen = false, allowedAddress: string | null = null) => {
      setBusy(true);
      try {
        const newProvider = new WalletConnectProvider({
          rpc: {
            44787: "https://alfajores-forno.celo-testnet.org",
            42220: "https://forno.celo.org",
          },
          qrcodeModal: {
            close: keepWalletOpen ? () => {} : () => setQrCodeOpen(false),
            open: (uri) => {
              setQrCodeUri(uri);
              setQrCodeOpen(true);
            },
          },
        });

        await newProvider.enable();
        const web3 = new Web3(newProvider as any);
        const newKit = newKitFromWeb3(web3 as any);

        [newKit.defaultAccount] = newProvider.accounts;

        // Ensure the user can only connect their associated wallet
        if (
          allowedAddress &&
          newKit.defaultAccount.toLowerCase() !== allowedAddress.toLowerCase()
        ) {
          /**
           * Delete localstorage entries used by walletconnect manually:
           * https://github.com/WalletConnect/walletconnect-monorepo/issues/394#issuecomment-882580677
           */
          await newProvider.connector.killSession();
          window.localStorage.removeItem("walletconnect");
          window.localStorage.removeItem("WALLETCONNECT_DEEPLINK_CHOICE");
          setBusy(false);
          return { contractKit: null, error: "Please use your linked wallet" };
        }

        setKit(newKit);
        setProvider(newProvider);
        setBusy(false);
        return { contractKit: newKit, error: "" };
      } catch (e) {
        console.error(e);
        setBusy(false);
        return { contractKit: null, error: "Contractkit not initialized" };
      }
    },
    []
  );

  const disconnectWallet = useCallback(async () => {
    setBusy(true);
    try {
      await provider?.connector.killSession();
      await provider?.disconnect();
      window.localStorage.removeItem("walletconnect");
      window.localStorage.removeItem("WALLETCONNECT_DEEPLINK_CHOICE");
      setProvider(null);
      setKit(null);
    } catch (e) {
      console.error(e);
    } finally {
      setBusy(false);
    }
  }, [provider]);

  const walletConnectParams: WalletConnectParams = {
    provider,
    kit,
    setProvider,
    setKit,
    connectWallet,
    disconnectWallet,
    busy,
    closeQRCodeModal: () => setQrCodeOpen(false),
  };

  return (
    <Container>
      <IconRegistry icons={EvaIconsPack} />
      <ApplicationProvider
        {...eva}
        theme={{ ...eva.light, ...theme }}
        customMapping={mapping as any}
      >
        <SafeAreaProvider>
          <WalletConnectContext.Provider value={walletConnectParams}>
            <NavigationContainer linking={linking}>
              <Navigation />
            </NavigationContainer>
            <QRCodeModal
              visible={qrCodeOpen}
              qrCodeUri={qrCodeUri}
              onBackdropPress={() => setQrCodeOpen(false)}
            />
          </WalletConnectContext.Provider>
        </SafeAreaProvider>
        <Toast config={ToastConfig} />
      </ApplicationProvider>
    </Container>
  );
};

export default observer(Root);

import { useNavigation } from "@react-navigation/native";
import { useForm } from "react-hook-form";
import api from "../api";
import { APIUserInput } from "../api/users";
import Toast, { SHORT } from "../components/Toast";
import { RootStore } from "../store";

type MethodsProp = ReturnType<typeof useForm>;
type NavigationProp = ReturnType<typeof useNavigation>;
type SetLoadingFunctionProp = (value: React.SetStateAction<boolean>) => void;

export const handleValidateProfile = (
  store: RootStore,
  navigation: NavigationProp,
  methods: MethodsProp,
  isLoading: boolean,
  setIsLoading: SetLoadingFunctionProp
) => {
  const handleValidate = async (data: APIUserInput) => {
    setIsLoading(true);

    try {
      await store.updateProfile(data);
    } catch (e) {
      const { detail } = await api.handleError(e);
      if (detail) {
        Object.entries(detail!).forEach(([key, message]) => {
          methods.setError(key as any, { message: (message as string[])[0] });
        });
      }
      setIsLoading(false);
      Toast.show({
        type: "error",
        text1: "Could not update profile",
        visibilityTime: SHORT,
      });
      return;
    }

    setIsLoading(false);
    Toast.show({
      type: "success",
      text1: "Your profile has been saved",
      visibilityTime: SHORT,
    });

    const address = store.me?.address;
    if (address == null || address === "") {
      navigation.navigate("WalletConnect");
    }

    if (navigation.canGoBack()) {
      navigation.goBack();
    } else {
      navigation.navigate("Dashboard");
    }
  };

  return handleValidate;
};

import React from "react";
import InlineSVG, { InlineSVGProps } from "svg-inline-react";
import SettingsSVG from "../../assets/settings.svg";

interface Props extends Omit<InlineSVGProps, "src" | "raw"> {}

const Settings = (props: Props) => <InlineSVG src={SettingsSVG} raw {...props} />;

Settings.defaultProps = {
  style: undefined,
};

export default Settings;

import { StyleSheet, View } from "react-native";
import styled from "styled-components/native";

const styles = StyleSheet.create({
  flexRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "stretch",
  },
  flexCol: {
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
  },
  alignCenter: {
    alignItems: "center",
  },
  justifyCenter: {
    justifyContent: "center",
  },
  placeCenter: {
    justifyContent: "center",
    alignItems: "center",
  },
});

export const propFlexRow = `
  display: flex;
  flex-direction: row;
  align-items: stretch;
`;
export const propFlexCol = `
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;
export const propPlaceCenter = `
  justify-content: center;
  align-items: center;
`;

export const FlexRow = styled(View)`
  ${propFlexRow};
`;

export const FlexCol = styled(View)`
  ${propFlexCol};
`;

export const { flexRow, flexCol, alignCenter, justifyCenter, placeCenter } = styles;

export default styles;

import { Layout, Text } from "@ui-kitten/components";
import React from "react";
import { StyleSheet, TouchableOpacity } from "react-native";
import { useNavigation } from "@react-navigation/native";
import styled from "rn-css";
import Footer from "./Footer";
import Logo from "./svgs/Logo";
import useWindowSize from "../hooks/useWindowSize";
import useListener from "../hooks/useListener";

const styles = StyleSheet.create({
  copyrightText: {
    flex: 1,
    textAlign: "center",
    fontFamily: "Jost_400Regular",
    fontWeight: "400",
    fontSize: 16,
    lineHeight: 23,
  },
  copyrightSubtext: {
    fontFamily: "Jost_400Regular",
    fontWeight: "400",
    fontSize: 14,
    lineHeight: 24,
  },
  links: {
    flex: 1,
    alignItems: "center",
    justifyContent: "flex-end",
    flexDirection: "row",
    columnGap: 36,
  },
  linksNarrow: {
    justifyContent: "flex-end",
    flexWrap: "wrap",
  },
  logoContainer: {
    flex: 1,
  },
  logoWide: {
    height: 46,
    width: 46,
  },
  logoNarrow: {
    height: 35,
    width: 35,
  },
  wrapperWide: {
    flex: 1,
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "row",
  },
  wrapperNarrow: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  wrapperNarrowTop: {
    flex: 1,
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "row",
    marginBottom: 20,
    columnGap: 36,
  },
  footerWide: {
    paddingHorizontal: 80,
    paddingVertical: 32,
  },
  footerNarrow: {
    paddingHorizontal: 20,
  },
});

const StyledText = styled(Text)`
  color: #27a563;
  font-family: Jost_500Medium;
  font-weight: 500;
  font-size: 16px;
  line-height: 23px;
  transition-timing-function: ease-out;
  transition-duration: 0.3s;

  &:hover {
    color: #000000;
  }
`;

const logoWideDimensions = StyleSheet.flatten([styles.logoWide]);
const logoNarrowDimensions = StyleSheet.flatten([styles.logoNarrow]);

// Component: link container for "Terms of Services" & "Privacy Policy"
const Links = () => {
  const navigation = useNavigation();
  const { isTablet } = useWindowSize();

  const handleTOS = useListener(() => {
    navigation.navigate("TermsAndCondition");
  });

  const handlePrivPolicy = useListener(() => {
    navigation.navigate("PrivacyPolicy");
  });

  return (
    <Layout style={[styles.links, isTablet && styles.linksNarrow]}>
      <TouchableOpacity onPress={handleTOS}>
        <StyledText>Terms of Services</StyledText>
      </TouchableOpacity>
      <TouchableOpacity onPress={handlePrivPolicy}>
        <StyledText>Privacy Policy</StyledText>
      </TouchableOpacity>
    </Layout>
  );
};

// Component: wide-screen-layout page footer
const PageFooterWide = () => {
  return (
    <Footer direction="horizontal" showDivider style={styles.footerWide}>
      <Layout style={styles.wrapperWide}>
        <Layout style={styles.logoContainer}>
          <Logo width={logoWideDimensions.width} height={logoWideDimensions.height} />
        </Layout>
        <Text category="s1" style={styles.copyrightText}>
          © 2020 The Doni Team{"\n"}
          <Text category="s1" style={styles.copyrightSubtext}>
            All Rights Reserved.
          </Text>
        </Text>
        <Links />
      </Layout>
    </Footer>
  );
};

// Component: narrow-screen-layout page footer
const PageFooterNarrow = () => {
  return (
    <Footer direction="horizontal" showDivider style={styles.footerNarrow}>
      <Layout style={styles.wrapperNarrow}>
        <Layout style={styles.wrapperNarrowTop}>
          <Logo width={logoNarrowDimensions.width} height={logoNarrowDimensions.height} />
          <Links />
        </Layout>
        <Text category="s1" style={styles.copyrightText}>
          © 2020 The Doni Team{"\n"}
          <Text category="s1" style={styles.copyrightSubtext}>
            All Rights Reserved.
          </Text>
        </Text>
      </Layout>
    </Footer>
  );
};

// Component (exported): page footer
const PageFooter = () => {
  const { isTablet } = useWindowSize();
  return <>{isTablet ? <PageFooterNarrow /> : <PageFooterWide />}</>;
};

export default PageFooter;

import { useNavigation, useRoute } from "@react-navigation/native";
import { Text } from "@ui-kitten/components";
import React, { useEffect, useMemo, useState } from "react";
import { ScrollView, StyleSheet, View } from "react-native";
import styled from "rn-css";
import { observer } from "mobx-react-lite";
import { toKebabCase } from "../utils/toKebabCase";
import Button from "../components/Button";
import CampaignFilterBar, { CampaignFilter } from "../components/CampaignFilterBar";
import CampaignGallery from "../components/CampaignGallery";
import CampaignQRCodeDialog from "../components/CampaignQRCodeDialog";
import CategoryLabel from "../components/CategoryLabel";
import CampaignSearchBar from "../components/CampaignSearchBar";
import EmptyCampaignGalleryBase from "../components/EmptyCampaignGallery";
import LogoGray from "../components/svgs/LogoGray";
import TextWithHighlights from "../components/TextWithHighlights";
import useCampaignList from "../hooks/useCampaignList";
import useCategoryList from "../hooks/useCategoryList";
import useListener from "../hooks/useListener";
import useWindowSize from "../hooks/useWindowSize";
import MainLayoutBase, { MainLayoutProps } from "../layouts/MainLayout";
import Campaign from "../store/models/Campaign";
import { FlexCol, FlexRow, placeCenter } from "../styles/containers";
import { APICampaignListSearchParams } from "../api/campaigns";
import { useStore } from "../store";

const toSentenceCase = (str: string) =>
  str.replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());

const styles = StyleSheet.create({
  button: {
    width: 240,
    height: 40,
    alignSelf: "flex-end",
  },
  buttonMobile: {
    width: "100%",
    maxWidth: 343,
    height: 40,
  },
  loadMore: {
    width: 240,
    alignSelf: "center",
    height: 40,
  },
  loadMoreMobile: {
    width: "100%",
  },
});

const highlightedTextStyles = StyleSheet.create({
  highlights: {
    color: "#3488ec",
  },
});

const CategoriesSwiper = styled(ScrollView)`
  max-height: 50px;
`;

const SearchBar = styled(CampaignSearchBar)`
  height: 64px;
  max-height: 64px;
  width: 100%;
  padding-left: 24px;
  padding-right: 24px;

  @media (max-width: 768px) {
    height: 48px;
    max-height: 48px;
  }
`;

const CategoryList = styled(FlexRow)`
  gap: 12px;
`;

const Header = styled(FlexCol)`
  gap: 6px;
`;

const Heading = styled(Text)`
  font-family: "Jost_500Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 35px;
  font-feature-settings: "pnum" on, "lnum" on;
  color: #2e3338;

  @media (max-width: 768px) {
    font-size: 20px;
    line-height: 29px;
  }
`;

const EmptyCampaignGallery = styled(EmptyCampaignGalleryBase)`
  gap: 24px;
  padding: 0px;
`;

const EmptyCategoryTextContainer = styled(FlexCol)`
  align-items: center;
  gap: 8px;
`;

const EmptyCategoryText = styled(Heading)`
  text-align: center;
`;

const EmptyCategorySubText = styled(Text)`
  font-family: "Jost_400Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  text-align: center;
  font-feature-settings: "pnum" on, "lnum" on;
  color: #2e3338;

  @media (max-width: 768px) {
    font-size: 16px;
    line-height: 23px;
  }
`;

const LogoContainer = styled(FlexRow)`
  justify-content: center;
  padding: 24px;
`;

const StickyFooter = styled(View)`
  z-index: 10;
  position: sticky;
  bottom: 0px;
  width: 100%;
  padding: 20px;
  background: #ffffff;
  border: 1px solid #edeeef;
  box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.05);
`;

const MainLayout = styled(MainLayoutBase)`
  gap: 36px;
  padding: 36px 128px;

  @media (max-width: 768px) {
    gap: 16px;
    padding: 36px 20px;
  }
`;

interface Props extends MainLayoutProps {}

const CampaignCategoryExploreScreen: React.FC<Props> = () => {
  const { isMobile } = useWindowSize();
  const navigation = useNavigation();
  const route = useRoute();
  const { me } = useStore();
  const routeParams = (route.params || {}) as { categoryName: string; searchPattern: string };

  const [page, setPage] = useState(1);
  const [sortOrder, setSortOrder] = useState("-created");
  const { results: categories } = useCategoryList();
  const allCategory = categories.at(0);

  const categoryItems = useMemo(
    () =>
      categories.map((category) => ({
        category,
        onToggle: () => {
          if (toKebabCase(routeParams.categoryName) !== category.name) {
            navigation.navigate("CampaignCategoryExplore", {
              categoryName: toKebabCase(category.name ?? ""),
            });
          }
        },
      })),
    [categories, navigation, routeParams.categoryName]
  );

  useEffect(() => {
    if (routeParams.categoryName) {
      const campaignCategory = routeParams.categoryName || "All";
      navigation.setOptions({
        title: `Doni | ${toSentenceCase(campaignCategory)} Campaigns`,
      });
      setPage(1);
    }
  }, [navigation, routeParams.categoryName]);

  useEffect(() => {
    setPage(1);
  }, [me]);

  const selectedCategory = categories.find(
    (category) => toKebabCase(category.name ?? "") === routeParams.categoryName
  );

  const [contextCampaign, setContextCampaign] = useState<Campaign>();
  const [qrCodeModalVisible, setQrCodeModalVisible] = useState(false);
  const categoryFilter = useMemo<APICampaignListSearchParams>(
    () => ({
      ...(routeParams.searchPattern && { search: routeParams.searchPattern }),
      ...(selectedCategory?.id && { category: selectedCategory?.id }),
      ordering: sortOrder,
    }),
    [selectedCategory, sortOrder, routeParams.searchPattern]
  );
  const { count, results: filteredCampaigns, loading, next } = useCampaignList({
    searchParams: categoryFilter,
    page,
  });

  const [filterItems] = useState<CampaignFilter[]>([
    { key: "most-recent", name: "Most Recent", onSelect: () => setSortOrder("-created") },
    { key: "oldest", name: "Oldest Campaigns", onSelect: () => setSortOrder("created") },
  ]);

  const onStartCampaignPress = useListener(() => {
    if (me) {
      navigation.navigate("CampaignCreate");
    } else {
      navigation.navigate("SignIn", { next: "campaign-create" });
    }
  });

  const onCampaignSharePress = useListener((campaign) => {
    setContextCampaign(campaign);
    setQrCodeModalVisible(true);
  });

  const onQrCodeModalHide = useListener(() => {
    setQrCodeModalVisible(false);
  });

  const onSearchSubmit = useListener((pattern: string) => {
    navigation.setParams({
      searchPattern: pattern.length === 0 ? undefined : pattern,
    });
  });

  const selectedCategoryName =
    selectedCategory?.id === allCategory?.id ? "all" : selectedCategory?.name?.toLowerCase() ?? "";
  const emptyGallery = count === 0;
  const headingText = routeParams.searchPattern
    ? `Exploring "${routeParams.searchPattern}" campaigns`
    : `Explore ${selectedCategoryName} campaigns`;

  return (
    <MainLayout
      loading={loading && page === 1}
      loadingText={`Loading ${selectedCategoryName} campaigns...`}
      footer={
        isMobile &&
        emptyGallery && (
          <StickyFooter style={placeCenter}>
            <Button
              text="Start a Campaign"
              type="greenPrimary"
              containerStyle={styles.buttonMobile}
              onPress={onStartCampaignPress}
            />
          </StickyFooter>
        )
      }
      modalDialog={
        <CampaignQRCodeDialog campaign={contextCampaign as Campaign} onClose={onQrCodeModalHide} />
      }
      showModalDialog={qrCodeModalVisible}
      onModalBackdropPress={onQrCodeModalHide}
    >
      {!isMobile && (
        <Button
          text="Start a Campaign"
          type="greenPrimary"
          containerStyle={styles.button}
          onPress={onStartCampaignPress}
        />
      )}
      <SearchBar
        clearOnBlur={false}
        defaultValue={routeParams.searchPattern}
        onSubmit={onSearchSubmit}
      />
      <CategoriesSwiper horizontal showsHorizontalScrollIndicator={false}>
        <CategoryList>
          {categoryItems.map(({ category, onToggle }) => (
            <CategoryLabel
              key={category.id}
              active={category.id === selectedCategory?.id}
              text={category.id === allCategory?.id ? "All Campaigns" : category.name!}
              icon={category.icon ? { uri: category.icon } : undefined}
              onToggle={onToggle}
            />
          ))}
        </CategoryList>
      </CategoriesSwiper>
      <Header>
        <Heading>{headingText}</Heading>
        {isMobile ? (
          <TextWithHighlights customStyles={highlightedTextStyles}>
            {`There are {${count}} campaigns to explore`}
          </TextWithHighlights>
        ) : (
          <CampaignFilterBar
            infoTemplate={`There are {${count}} campaigns to explore`}
            filters={filterItems}
          />
        )}
      </Header>
      {isMobile && <CampaignFilterBar infoTemplate="" filters={filterItems} />}
      {emptyGallery ? (
        <EmptyCampaignGallery
          searchText={routeParams.searchPattern}
          buttonText="Start a Campaign"
          onButtonPress={onStartCampaignPress}
        >
          <EmptyCategoryTextContainer>
            <EmptyCategoryText>
              {routeParams.searchPattern
                ? `Sorry, we couldn't find any campaigns for "${routeParams.searchPattern}"`
                : "Sorry, there are no active campaigns right now"}
            </EmptyCategoryText>
            <EmptyCategorySubText>
              {routeParams.searchPattern
                ? "Try searching for a different campaign or explore our other categories"
                : "Be the first one to add a new campaign here!"}
            </EmptyCategorySubText>
          </EmptyCategoryTextContainer>
        </EmptyCampaignGallery>
      ) : (
        <CampaignGallery
          campaigns={filteredCampaigns}
          showDeadlines
          onCampaignSharePress={onCampaignSharePress}
        />
      )}
      {next ? (
        <Button
          text="Load more..."
          onPress={() => setPage(page + 1)}
          disabled={loading}
          containerStyle={[styles.loadMore, isMobile && styles.loadMoreMobile]}
        />
      ) : (
        <LogoContainer>
          <LogoGray width={35} height={35} />
        </LogoContainer>
      )}
    </MainLayout>
  );
};

export default observer(CampaignCategoryExploreScreen);

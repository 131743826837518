import base from "./base";
import { APIUser } from "./users";

export interface APIToken {
  token: string;
  user: APIUser;
}

export const callbackToken = (phoneNumber: string, address?: string | null) =>
  base.postEmpty("auth/mobile/", {
    json: address ? { phoneNumber, address } : { phoneNumber },
  });

export const authToken = (phoneNumber: string, token: string) =>
  base.post<APIToken>("auth/token/", {
    json: { phoneNumber, token },
  });

export const logout = () => base.postEmpty("auth/logout/");

export const ping = () => base.get<APIToken>("auth/ping/");

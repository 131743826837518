import { useState, useEffect } from "react";

const MOBILE_BREAKPOINT = 768;
const TABLET_BREAKPOINT = 1024;

const debounce = (fn: Function, ms: number) => {
  let timeoutId: ReturnType<typeof setTimeout>;
  return function debouncedFunction(this: any, ...args: any[]) {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => fn.apply(this, args), ms);
  };
};

const useWindowSize = (delay = 100) => {
  const [width, setWidth] = useState<number>(window.innerWidth);

  const handleWindowSizeChange = debounce(() => {
    setWidth(window.innerWidth);
  }, delay);

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
    /* eslint-disable react-hooks/exhaustive-deps */
  }, []);

  return {
    isMobile: width <= MOBILE_BREAKPOINT,
    isTablet: width <= TABLET_BREAKPOINT,
    width,
  };
};

export default useWindowSize;

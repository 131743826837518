import React from "react";
import { Icon as IconBase, Text as TextBase } from "@ui-kitten/components";
import { View, ViewProps } from "react-native";
import styled from "styled-components/native";

const Icon = styled(IconBase)`
  width: 16px;
  height: 16px;
  fill: #abadaf;
`;

const Text = styled(TextBase)`
  font-family: "Jost_400Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #abadaf;
`;

const Container = styled(View)`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;

export interface InfoTagProps extends Omit<ViewProps, "children"> {
  iconName: string;
  text: string;
}

const InfoTag: React.FC<InfoTagProps> = ({ iconName, text, style, ...props }) => (
  <Container style={style} {...props}>
    <Icon name={iconName} style={style} />
    <Text style={style}>{text}</Text>
  </Container>
);

export default InfoTag;

import React, { useCallback, useEffect, useState } from "react";
import { View, ViewProps } from "react-native";
import styled from "styled-components/native";
import { FlexCol, FlexRow } from "../styles/containers";
import PressableIcon from "./PressableIcon";

const Container = styled(FlexCol)`
  gap: 24px;
  overflow: hidden;
`;

const Header = styled(FlexRow)`
  align-items: center;
  gap: 13px;
`;

const CollapseIcon = styled(PressableIcon)<{ collapsed: boolean }>`
  width: 24px;
  height: 24px;
  transform: rotate(${({ collapsed }) => (collapsed ? 270 : 90)}deg);
  transition: transform 0.1s linear;
`;

const Divider = styled(View)`
  width: 100%;
  max-width: 57px;
  height: 4px;
  background: #35d07f;
  border-radius: 100px;
`;

const Body = styled(View)<{ collapsed: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  height: ${({ collapsed }) => (collapsed ? "0px" : "auto")};
`;

export interface AccordionProps extends ViewProps {
  headerComponent?: JSX.Element;
  divider?: JSX.Element;
  collapsible?: boolean;
  defaultCollapsed?: boolean;
}

const Accordion: React.FC<AccordionProps> = ({
  children,
  headerComponent,
  divider,
  collapsible = false,
  defaultCollapsed = false,
  ...props
}) => {
  const [collapsed, setCollapsed] = useState(false);

  const onCollapseToggle = useCallback(() => setCollapsed((flag) => !flag), []);

  useEffect(() => {
    setCollapsed(defaultCollapsed);
  }, [defaultCollapsed]);

  return (
    <Container {...props}>
      <Header>
        {headerComponent}
        {collapsible && (
          <CollapseIcon
            iconName="arrow-ios-back"
            onPress={onCollapseToggle}
            collapsed={collapsed}
          />
        )}
      </Header>
      {divider ?? <Divider />}
      <Body collapsed={collapsible ? collapsed : false}>{children}</Body>
    </Container>
  );
};

export default Accordion;

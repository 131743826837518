import React, { useContext, useEffect, useState } from "react";
import { useNavigation, useRoute } from "@react-navigation/native";
import styled from "rn-css";
import { observer } from "mobx-react-lite";
import { useStore } from "../store";
import useListener from "../hooks/useListener";
import CampaignWithdrawDialog from "../components/CampaignWithdrawDialog";
import CampaignWithdrawConfirmDialog from "../components/CampaignWithdrawConfirmDialog";
import useCampaign from "../hooks/useCampaign";
import MainLayoutBase, { MainLayoutProps } from "../layouts/MainLayout";
import Toast, { SHORT } from "../components/Toast";
import WalletConnectContext from "../components/contexts/WalletConnectContext";
import { withdrawFunds } from "../utils/contract";
import showErrorPage from "../utils/showErrorPage";

const MainLayout = styled(MainLayoutBase)`
  justify-content: center;
  padding: 43px 0px;

  @media (max-width: 768px) {
    padding: 0px;
  }
`;

const fail = (errorMessage: string) => {
  Toast.show({
    type: "error",
    text1: errorMessage,
    visibilityTime: SHORT,
  });
};

interface Props extends MainLayoutProps {}

interface TransactionDetails {
  fundsWithdrawn: string;
  blockNumber: number;
}

const CampaignWithdrawScreen: React.FC<Props> = (props) => {
  const route = useRoute();
  const navigation = useNavigation();
  const { id } = (route?.params || {}) as { id: string };
  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingText, setLoadingText] = useState("");
  const store = useStore();
  const { connectWallet } = useContext(WalletConnectContext);
  const [transactionDetails, setTransactionDetails] = useState<TransactionDetails>({
    fundsWithdrawn: "0",
    blockNumber: 0,
  });

  const { campaign, error: campaignError } = useCampaign(id);

  const onWithdraw = useListener(async () => {
    if (!campaign) {
      fail("Campaign not found");
      return;
    }

    if (campaign.organizer !== store.me?.id) {
      fail("Not allowed to withdraw from campaign");
      return;
    }

    if (!store.address || store.address === "") {
      fail("Please link a wallet first");
      return;
    }

    const { contractKit, error } = await connectWallet(false, store.address);

    if (!contractKit) {
      fail(error || "Uninitialized contract kit");
      return;
    }

    setLoading(true);
    setLoadingText("Waiting for wallet confirmation...");

    try {
      const fundsWithdrawn = campaign.balanceDecimal;
      const receipt = await withdrawFunds(contractKit, store.address!, campaign.contractAddress!);
      setTransactionDetails({ fundsWithdrawn, blockNumber: receipt.blockNumber });
      setSubmitted(true);
    } catch (e) {
      console.error(e);
      fail(e.message);
    } finally {
      setLoading(false);
      setLoadingText("");
    }
  });

  useEffect(() => {
    if (campaign) {
      // Redirect to error page if a logged-in user tries to
      // withdraw funds from someone else's campaign
      if (campaign.organizer !== store.me?.id) {
        showErrorPage(navigation, "Cannot withdraw campaign's funds.");
      }
    } else if (campaignError) {
      // Redirect to error page if user tries to
      // withdraw funds from nonexistent campaign
      showErrorPage(navigation, "Failed to load campaign.");
    } else {
      // Campaign is still loading, do nothing
    }
  }, [campaign, campaignError, navigation, store.me]);

  return (
    <MainLayout alignStretch={false} loading={loading} loadingText={loadingText} {...props}>
      {campaign &&
        (submitted ? (
          <CampaignWithdrawConfirmDialog campaign={campaign} {...transactionDetails} />
        ) : (
          <CampaignWithdrawDialog campaign={campaign} onWithdraw={onWithdraw} />
        ))}
    </MainLayout>
  );
};

export default observer(CampaignWithdrawScreen);

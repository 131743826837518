import React, { useEffect, useMemo, useState } from "react";
import { Pressable, StyleProp, ViewProps, ViewStyle } from "react-native";
import styled from "styled-components/native";
import useListener from "../hooks/useListener";
import { FlexRow } from "../styles/containers";

const Container = styled(FlexRow)`
  justify-content: center;
  align-items: center;
  padding: 0px;
`;

const Page = styled(Pressable)`
  width: 12px;
  height: 12px;
  border-radius: 999999px;
  background-color: #aca8a7;
`;

const ActivePage = styled(Page)`
  background-color: #ffffff;
`;

export interface PaginationProps extends ViewProps {
  count: number;
  selectedIndex?: number;
  onSelectIndex: (index: number) => void;
  pageStyle?: StyleProp<ViewStyle>;
  activePageStyle?: StyleProp<ViewStyle>;
}

const Pagination: React.FC<PaginationProps> = ({
  count,
  selectedIndex = 0,
  onSelectIndex,
  pageStyle,
  activePageStyle,
  ...props
}) => {
  const [index, setIndex] = useState(selectedIndex);
  const onSelect = useListener((whichIndex: number) => {
    onSelectIndex(whichIndex);
    setIndex(whichIndex);
  });
  const handlers = useMemo(
    () => Array.from({ length: count }, (_, i: number) => onSelect.bind(undefined, i)),
    [count, onSelect]
  );

  useEffect(
    useListener(() => {
      onSelect(selectedIndex);
    }),
    [selectedIndex]
  );

  return (
    <Container {...props}>
      {Array.from({ length: count }, (_, i: number) =>
        index === i ? (
          <ActivePage key={i} style={activePageStyle} />
        ) : (
          <Page key={i} onPress={handlers[i]} style={pageStyle} />
        )
      )}
    </Container>
  );
};

export default Pagination;

import React, { useCallback } from "react";
import { StyleSheet, Text, View } from "react-native";
import { useNavigation } from "@react-navigation/native";
import styled from "styled-components/native";
import Button from "./Button";
import useWindowSize from "../hooks/useWindowSize";
import AboutInfo from "./AboutInfo";
import aboutInfoImage1 from "../assets/images/about-info_image1.png";
import aboutInfoImage2 from "../assets/images/about-info_image2.png";
import aboutInfoImage3 from "../assets/images/about-info_image3.png";

const styles = StyleSheet.create({
  button: {
    width: 343,
    alignSelf: "center",
  },
  overview: {},
  overviewMobile: {
    paddingTop: 64,
    paddingBottom: 64,
    paddingLeft: 20,
    paddingRight: 20,
  },
  overviewHeading: {},
  overviewHeadingMobile: {
    fontSize: 36,
    lineHeight: 52,
  },
  overviewCaption: {},
  overviewCaptionMobile: {
    fontSize: 16,
    lineHeight: 23,
  },
  infoWrapper: {},
  infoWrapperMobile: {
    gap: 48,
    paddingTop: 64,
    paddingBottom: 64,
    paddingLeft: 20,
    paddingRight: 20,
  },
});

const Container = styled(View)`
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

const OverviewContainer = styled(View)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 0px;
  background-color: #27a563;
  flex-grow: 0;
`;

const Overview = styled(View)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  padding: 124px 20px;
  gap: 24px;
  width: 816px;
  background-color: #27a563;
  flex: 0 1 auto;
`;

const OverviewHeading = styled(Text)`
  font-family: "Jost_500Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 52px;
  line-height: 75px;
  color: #ffffff;
  flex-grow: 0;
`;

const CaptionContainer = styled(View)`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 12px;
`;

const Caption = styled(Text)`
  font-family: "Jost_500Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  color: #ffffff;
`;

const AboutInfoWrapper = styled(View)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  align-items: flex-start;
  gap: 128px;
  padding: 128px 0px;
`;

const About = () => {
  const { isMobile } = useWindowSize();
  const navigation = useNavigation();

  const handleLearnMore = useCallback(() => {
    navigation.navigate("FAQ");
  }, [navigation]);

  return (
    <Container>
      <OverviewContainer>
        <Overview style={[styles.overview, isMobile && styles.overviewMobile]}>
          <OverviewHeading
            style={[styles.overviewHeading, isMobile && styles.overviewHeadingMobile]}
          >
            About Doni
          </OverviewHeading>
          <CaptionContainer>
            <Caption style={[styles.overviewCaption, isMobile && styles.overviewCaptionMobile]}>
              Doni is an outreach fundraising website dedicated to reinvent and nurture new living
              experiences for people, through the use of fundraising campaigns.
            </Caption>
            <Caption style={[styles.overviewCaption, isMobile && styles.overviewCaptionMobile]}>
              We strived to create a digital support system for people to engage, connect and
              inform. Doni gives them an opportunity to get under their own spotlight and tell their
              part of the story to the world.
            </Caption>
          </CaptionContainer>
          <Button
            text="Learn More"
            containerStyle={styles.button}
            type="invertedGreenTertiary"
            onPress={handleLearnMore}
          />
        </Overview>
      </OverviewContainer>
      <AboutInfoWrapper style={[styles.infoWrapper, isMobile && styles.infoWrapperMobile]}>
        <AboutInfo
          imgSrc={aboutInfoImage1}
          infoShort="Support communities by funding them and sharing their story"
          infoExtended="Allow yourself to explore and discover different communities and stories from different people. Know and support their humble beginnings and lead them to success."
        />
        <AboutInfo
          imgSrc={aboutInfoImage2}
          infoShort="Easily create a campaign for your cause"
          infoExtended="Effortlessly convey your stories in the app through your fundraiser campaigns while connecting your cause with different people and informing them with timely updates and pictures."
        />
        <AboutInfo
          imgSrc={aboutInfoImage3}
          infoShort="Send and receive money all through the security of Valora"
          infoExtended="Confidently send your donations and keep your money controlled and secure with Valora. Recieve or direct your donations with ease anytime and anywhere."
        />
      </AboutInfoWrapper>
    </Container>
  );
};

export default About;

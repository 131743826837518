import React from "react";
import { ViewProps } from "react-native";
import Campaign from "../store/models/Campaign";
import CampaignPreviewCard from "./CampaignPreviewCard";
import Gallery from "./Gallery";

export interface CampaignGalleryProps extends ViewProps {
  campaigns: Campaign[];
  showFundingMeters?: boolean;
  showDeadlines?: boolean;
  onCampaignManagePress?: (campaign: Campaign) => void;
  onCampaignSharePress?: (campaign: Campaign) => void;
}

const CampaignGallery: React.FC<CampaignGalleryProps> = ({
  campaigns,
  showFundingMeters,
  showDeadlines,
  onCampaignManagePress,
  onCampaignSharePress,
  ...props
}) => {
  return (
    <Gallery gap={24} {...props}>
      {campaigns.map((campaign) => (
        <CampaignPreviewCard
          key={campaign.id}
          campaign={campaign}
          showMeter={showFundingMeters}
          showDeadline={showDeadlines}
          onManagePress={onCampaignManagePress}
          onSharePress={onCampaignSharePress}
        />
      ))}
    </Gallery>
  );
};

export default CampaignGallery;

import React from "react";
import { StyleSheet, ViewProps } from "react-native";
import { Text as TextBase } from "@ui-kitten/components";
import styled from "rn-css";
import useWindowSize from "../hooks/useWindowSize";
import { FlexCol } from "../styles/containers";
import Button from "./Button";
import NoCampaign from "./svgs/NoCampaign";

const styles = StyleSheet.create({
  button: {
    width: 343,
    height: 40,
  },
});

const Text = styled(TextBase)`
  font-family: "Jost_500Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  text-align: center;
  font-feature-settings: "pnum" on, "lnum" on;
  color: #2e3338;

  max-width: 540px;
  font-size: 36px;
  line-height: 52px;

  @media (max-width: 768px) {
    max-width: 335px;
    font-size: 20px;
    line-height: 29px;
  }
`;

const Container = styled(FlexCol)`
  align-items: center;
  padding: 0px 64px 64px;
  gap: 48px;
`;

export interface EmptyCampaignsGalleryProps extends ViewProps {
  searchText?: string;
  text?: string;
  buttonText?: string;
  onButtonPress?: () => void;
}

const EmptyCampaignsGallery: React.FC<EmptyCampaignsGalleryProps> = ({
  children,
  searchText,
  text,
  buttonText,
  onButtonPress,
  ...props
}) => {
  const { isMobile } = useWindowSize();

  return (
    <Container {...props}>
      {!searchText && <NoCampaign />}
      {text ? <Text>{text}</Text> : children}
      {!isMobile && !searchText && buttonText && (
        <Button
          text={buttonText}
          type="greenPrimary"
          containerStyle={styles.button}
          onPress={onButtonPress}
        />
      )}
    </Container>
  );
};

export default EmptyCampaignsGallery;

import { CeloTxReceipt } from "@celo/connect";
import { ContractKit } from "@celo/contractkit";
import CampaignJSON from "@doni/contracts/build/contracts/Campaign.json";
import BigNumber from "bignumber.js";
import * as Random from "expo-random";

BigNumber.config({ EXPONENTIAL_AT: [-7, 30] });

export async function generateRequestId(prefix: string) {
  const bytes = await Random.getRandomBytesAsync(64);
  const bytesHex = Buffer.from(bytes).toString("hex");
  return `${prefix}__${bytesHex}`;
}

export async function deployContract(
  kit: ContractKit,
  fromAddress: string,
  goal: BigNumber,
  beforeRequest?: () => void,
  afterRequest?: () => void,
  afterResult?: () => void
) {
  const stableToken = await kit.contracts.getStableToken();
  const decimals = await stableToken.decimals();
  const goalPow = goal.multipliedBy(new BigNumber(10).pow(decimals));

  console.info("DEPLOYING WITH arguments:", stableToken.address, goalPow.toString());

  const contract = new kit.web3.eth.Contract(CampaignJSON.abi as any[]);

  if (beforeRequest) {
    beforeRequest();
    console.debug("beforeRequest");
  }

  const txResult = await contract
    .deploy({
      data: CampaignJSON.bytecode,
      arguments: [stableToken.address, goalPow.toString()],
    })
    .send(
      {
        from: fromAddress,
        gas: 99999999,
      },
      (error) => {
        if (error) {
          console.error(error);
        }
        if (afterRequest) {
          afterRequest();
        }
      }
    );

  if (afterResult) {
    console.debug("afterResult");
    afterResult();
  }
  return txResult.options.address;
}

export async function donate(kit: ContractKit, toAddress: string, amountRaw: BigNumber) {
  const stableToken = await kit.contracts.getStableToken();
  const decimals = await stableToken.decimals();
  const amount = amountRaw.multipliedBy(new BigNumber(10).pow(decimals));
  const tx = await stableToken.transfer(toAddress, amount.toString()).send();
  await tx.waitReceipt();
}

export async function withdrawFunds(
  kit: ContractKit,
  fromAddress: string,
  contractAddress: string
) {
  const contract = new kit.web3.eth.Contract(CampaignJSON.abi as any[], contractAddress);

  const receipt: CeloTxReceipt = await contract.methods.withdrawCUSD().send({
    from: fromAddress,
    gas: 99999999,
  });

  return receipt;
}

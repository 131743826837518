import { useEffect, useState } from "react";
import { useStore } from "../store";
import Toast, { SHORT } from "../components/Toast";
import Category from "../store/models/Category";
import { getAllCampaignsCategory } from "../api/categories";

const useCategoryList = () => {
  const { categories } = useStore();
  const [fetchResult, setFetchResult] = useState({
    results: [] as Category[],
    loading: false,
    error: undefined,
  });

  useEffect(() => {
    setFetchResult({
      ...fetchResult,
      loading: true,
      error: undefined,
    });
    Promise.all([getAllCampaignsCategory(), categories.list()])
      .then(([apiCategory, { results }]) => {
        const list = [new Category(apiCategory), ...(results as Category[])];
        setFetchResult({
          results: list,
          loading: false,
          error: undefined,
        });
      })
      .catch((e) => {
        setFetchResult({
          results: [],
          loading: false,
          error: e,
        });
        Toast.show({
          type: "error",
          text1: "Error Fetching Categories",
          visibilityTime: SHORT,
        });
      });
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [categories]);

  return fetchResult;
};

export default useCategoryList;

import { Text, TextProps } from "@ui-kitten/components";
import React from "react";
import { StyleProp, TextStyle } from "react-native";

export interface TextWithHightlightsProps extends TextProps {
  children: string;
  delimiters?: {
    open: string;
    close: string;
  };
  customStyles?: {
    text?: StyleProp<TextStyle>;
    highlights?: StyleProp<TextStyle>;
  };
}

const TextWithHighlights: React.FC<TextWithHightlightsProps> = ({
  children,
  delimiters: { open = "{", close = "}" } = {},
  customStyles,
  style,
  ...props
}) => {
  const regex = new RegExp(`(?:${open}(.*?)${close})`);
  const segments = children.split(regex);

  return (
    <Text style={[style, customStyles?.text]} {...props}>
      {segments.map((segment, index) =>
        index % 2 ? (
          <Text key={`${segment}`} style={[style, customStyles?.highlights]} {...props}>
            {segment}
          </Text>
        ) : (
          segment
        )
      )}
    </Text>
  );
};

export default TextWithHighlights;

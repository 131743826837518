import { EvaProp, Layout, withStyles, Text, Divider } from "@ui-kitten/components";
import React from "react";
import { useForm } from "react-hook-form";
import { APICampaignInput, APICampaignCreate } from "../api/campaigns";
import Button from "./Button";
import CampaignStep3Form from "./forms/CampaignStep3Form";

interface Props {
  eva?: EvaProp;
  disabled?: boolean;
  state: APICampaignInput;
  onDraft: (data: APICampaignCreate) => Promise<void>;
  onPublish: (data: APICampaignCreate) => Promise<void>;
  onPreview: (data: APICampaignCreate) => void;
  onBack?: () => boolean;
}

const CampaignCreateStep3: React.FC<Props> = ({
  eva,
  disabled,
  state,
  onDraft,
  onPublish,
  // onPreview,
  onBack,
}) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  return (
    <Layout style={eva?.style?.container}>
      <Layout style={eva?.style?.form}>
        <Text category="h1" style={eva?.style?.titleText}>
          Tell your story
        </Text>
        <Text category="p1" style={eva?.style?.subtitleText} appearance="hint">
          Say something about who you are and why you’re starting a fundraiser.
        </Text>
        <Divider style={eva?.style?.miniDivider} />
        <CampaignStep3Form control={control} disabled={disabled} errors={errors} state={state} />
        <Layout style={eva?.style?.footerPreviewDraft}>
          {/*
          <Button
            containerStyle={eva?.style?.wideButton}
            disabled={disabled}
            type="greenSecondary"
            onPress={handleSubmit(onPreview)}
            text="Preview"
            removePadding
          />
          */}
          <Button
            containerStyle={eva?.style?.wideButton}
            disabled={disabled}
            type="graySecondary"
            onPress={handleSubmit(onDraft)}
            text="Save as Draft"
            removePadding
          />
        </Layout>
        <Divider style={eva?.style?.bigDivider} />
      </Layout>
      <Layout style={eva?.style?.buttons}>
        <Button
          disabled={disabled}
          onPress={() => {
            if (onBack) onBack();
          }}
          containerStyle={eva?.style?.back}
          text="Back"
          type="blackGhost"
          removePadding
        />
        <Button
          containerStyle={eva?.style?.wideButton}
          disabled={disabled}
          status="primary"
          type="greenPrimary"
          onPress={handleSubmit(onPublish)}
          text="Publish Campaign"
          removePadding
        />
      </Layout>
    </Layout>
  );
};

export default withStyles(CampaignCreateStep3, () => ({
  container: {
    display: "flex",
    flex: 1,
    rowGap: 48,
  },
  form: {
    flex: 1,
    rowGap: 12,
  },
  footerPreviewDraft: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    columnGap: 24,
    marginTop: 12,
  },
  wideButton: {
    height: 40,
    maxWidth: 240,
    width: "100%",
    flex: 1,
  },
  buttons: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    columnGap: 12,
  },
  titleText: {
    fontFamily: "Jost_500Medium",
    fontWeight: "500",
    fontSize: 24,
    lineHeight: 35,
  },
  subtitleText: {
    fontFamily: "Jost_400Regular",
    fontWeight: "400",
    fontSize: 16,
    lineHeight: 23,
    color: "#757f87",
  },
  miniDivider: {
    backgroundColor: "#35d07f",
    height: 4,
    width: 57,
    radius: 100,
    marginTop: 12,
    marginBottom: 12,
  },
  bigDivider: {
    color: "#EDEEEF",
    backgroundColor: "#EDEEEF",
    marginTop: 12,
  },
}));

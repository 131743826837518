import React from "react";
import { View, ViewProps } from "react-native";
import { Text } from "@ui-kitten/components";
import { observer } from "mobx-react-lite";
import styled from "rn-css";
import QRCode from "react-native-qrcode-svg";
import PressableIcon from "./PressableIcon";
import Campaign from "../store/models/Campaign";
import { FlexCol } from "../styles/containers";

const QRCodeContainer = styled(View)`
  align-self: center;
  padding: 10px;
  width: 270px;
  height: 270px;
`;

const Container = styled(FlexCol)`
  padding: 64px;
  gap: 24px;
  max-width: 538px;
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.06);
  border-radius: 10px;

  @media (max-width: 768px) {
    padding: 44px 24px 24px;
    max-width: 335px;
  }
`;

const CloseIcon = styled(PressableIcon)`
  width: 24px;
  height: 24px;
  position: absolute;
  right: 15px;
  top: 15px;
`;

const Heading = styled(Text)`
  font-family: "Jost_500Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 35px;
  display: flex;
  font-feature-settings: "pnum" on, "lnum" on;
  color: #2e3338;
`;

const Caption = styled(Text)`
  font-family: "Jost_400Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  display: flex;
  align-items: center;
  color: #757f87;
`;

const GreenDivider = styled(View)`
  width: 57px;
  height: 4px;
  background: #35d07f;
  border-radius: 999999px;
`;

interface CampaignQRCodeDialogProps extends ViewProps {
  campaign: Campaign;
  onClose: () => void;
}

const CampaignQRCodeDialog: React.FC<CampaignQRCodeDialogProps> = ({
  campaign,
  onClose,
  ...props
}) => {
  return (
    <Container {...props}>
      <CloseIcon iconName="close" fill="#2e3338" onPress={onClose} />
      <Heading>Share &quot;{campaign.name ?? ""}&quot;</Heading>
      <Caption>
        You may use this QR code to share to your potential supporters for easier transaction.
      </Caption>
      <GreenDivider />
      {campaign.valoraQrCode && (
        <QRCodeContainer>
          <QRCode size={250} value={campaign.valoraQrCode ?? ""} />
        </QRCodeContainer>
      )}
    </Container>
  );
};

export default observer(CampaignQRCodeDialog);

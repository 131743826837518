import React from "react";
import InlineSVG, { InlineSVGProps } from "svg-inline-react";
import LogoWithTextSVG from "../../assets/logo_with_text.svg";

interface Props extends Omit<InlineSVGProps, "src" | "raw"> {}

const LogoWithText = (props: Props) => <InlineSVG src={LogoWithTextSVG} raw {...props} />;

LogoWithText.defaultProps = {
  style: undefined,
};

export default LogoWithText;

import React from "react";
import InlineSVG, { InlineSVGProps } from "svg-inline-react";
import BuildingBlocksSVG from "../../assets/building_blocks.svg";

interface Props extends Omit<InlineSVGProps, "src" | "raw"> {}

const BuildingBlocks = (props: Props) => <InlineSVG src={BuildingBlocksSVG} raw {...props} />;

BuildingBlocks.defaultProps = {
  style: undefined,
};

export default BuildingBlocks;

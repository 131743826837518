import Toast, { ToastConfig, ToastConfigParams } from "react-native-toast-message";
import { StyleSheet } from "react-native";
import { Icon, Layout, Text } from "@ui-kitten/components";
import React from "react";

export const SHORT = 3000;
export const LONG = 5000;

const styles = StyleSheet.create({
  toast: {
    padding: 16,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 8,
    borderWidth: 1,
    gap: 16,
  },
  errorToast: {
    backgroundColor: "#F7E1DE",
    borderColor: "#D25A4A4D",
  },
  errorToastText: {
    color: "#D25A4A",
  },
  successToast: {
    backgroundColor: "#F4FCF8",
    borderColor: "#83E2B0",
  },
  successToastText: {
    color: "#27A563",
  },
});

const ErrorToast: React.FC<ToastConfigParams<any>> = ({ text1 }) => (
  <Layout style={[styles.toast, styles.errorToast]}>
    <Icon width={16} height={16} fill="#D25A4A" name="info" />
    <Text style={[styles.errorToastText]}>{text1}</Text>
  </Layout>
);

const SuccessToast: React.FC<ToastConfigParams<any>> = ({ text1 }) => (
  <Layout style={[styles.toast, styles.successToast]}>
    <Icon width={16} height={16} fill="#27A563" name="checkmark-circle-outline" />
    <Text style={[styles.successToastText]}>{text1}</Text>
  </Layout>
);

export const config: ToastConfig = {
  success: (props) => <SuccessToast {...props} />,
  error: (props) => <ErrorToast {...props} />,
};

export default Toast;

import { requestAccountAddress, waitForAccountAuth } from "@celo/dappkit";
import { AccountAuthResponseSuccess } from "@celo/utils";
import * as Linking from "expo-linking";
import { Model, model, modelAction, modelFlow, prop, _async, _await } from "mobx-keystone";
import { Platform } from "react-native";
import api from "../api";
import { APIUserInput } from "../api/users";
import { generateRequestId } from "../utils/contract";
import storage from "../services/storage";
import kit from "../utils/kit";
import CampaignStore from "./CampaignStore";
import CategoryStore from "./CategoryStore";
import UserStore from "./UserStore";

import User from "./models/User";

const ONBOARDING_DONE_KEY = "doni__onboarding_done";

@model("doni/RootStore")
export default class RootStore extends Model({
  address: prop<string | null | undefined>(),
  phoneNumber: prop<string | undefined>(),
  token: prop<string | undefined>(),
  loading: prop(() => false),

  me: prop<User | undefined>(),

  campaigns: prop<CampaignStore>(),
  categories: prop<CategoryStore>(),
  users: prop<UserStore>(),

  isOnboardingDone: prop<boolean>(() => false),
  deviceToken: prop<string | undefined>(),
}) {
  @modelFlow
  init = _async(function* (this: RootStore) {
    const isOnboardingDone = yield* _await(storage.getItem(ONBOARDING_DONE_KEY));
    this.isOnboardingDone = JSON.parse((isOnboardingDone as string) || "false");
  });

  @modelAction
  reset() {
    this.address = undefined;
    this.phoneNumber = undefined;
    this.token = undefined;
    this.loading = false;
    this.me = undefined;
    this.campaigns = new CampaignStore({});
    this.categories = new CategoryStore({});
    this.users = new UserStore({});

    api.config.token = "";
  }

  @modelFlow
  setIsOnboardingDone = _async(function* (this: RootStore, status: boolean) {
    try {
      yield* _await(storage.setItem(ONBOARDING_DONE_KEY, JSON.stringify(status)));
    } catch (e) {
      this.isOnboardingDone = false;
      return;
    }
    this.isOnboardingDone = status;
  });

  @modelAction
  setDeviceToken(token: string) {
    this.deviceToken = token;
  }

  @modelAction
  setPhoneNumber(phoneNumber: string) {
    this.phoneNumber = phoneNumber;
  }

  @modelFlow
  valoraLogin = _async(function* (this: RootStore) {
    const requestId = yield* _await(generateRequestId("login"));
    const dappName = "Doni";
    const callback = Linking.makeUrl("/");

    requestAccountAddress({
      requestId,
      dappName,
      callback,
    });

    let dappkitResponse: AccountAuthResponseSuccess;

    try {
      dappkitResponse = yield* _await(waitForAccountAuth(requestId));
      console.debug({ dappkitResponse });
    } catch (e) {
      throw new Error(e);
    }

    kit.defaultAccount = dappkitResponse.address;
    this.address = dappkitResponse.address;
    this.phoneNumber = dappkitResponse.phoneNumber;
  });

  @modelAction
  logout() {
    // Reset the store regardless whether or not the API call
    // was successful or not.

    if (this.deviceToken) {
      api.notification.unregister(this.deviceToken).catch((e) => console.warn(e));
    }

    api.auth.logout().catch((e) => {
      console.warn("Logout failure", { e });
    });
    this.reset();
  }

  @modelFlow
  ping = _async(function* (this: RootStore) {
    try {
      const response = yield* _await(api.auth.ping());
      this.me = new User(response.user);

      if (this.deviceToken) {
        api.notification.register(this.deviceToken, Platform.OS).catch((e) => console.warn(e));
      }
    } catch (e) {
      throw new Error(e);
    }
  });

  @modelFlow
  updateProfile = _async(function* (this: RootStore, apiData: APIUserInput) {
    if (!this.me) {
      return;
    }
    const response = yield* _await(api.users.update(this.me.id, apiData));
    this.me = new User(response);
  });

  @modelFlow
  updateAddress = _async(function* (this: RootStore, address: string | null) {
    if (!this.me) {
      return;
    }
    const response = yield* _await(api.users.updateAddress(this.me.id, address));
    this.me = new User(response);
    this.address = response.address;
  });

  @modelFlow
  callbackToken = _async(function* (this: RootStore) {
    if (!this.phoneNumber) {
      return;
    }

    this.loading = true;
    yield* _await(api.auth.callbackToken(this.phoneNumber, this.address));
    this.loading = false;
  });

  @modelFlow
  authToken = _async(function* (this: RootStore, token: string) {
    if (!this.phoneNumber) {
      return;
    }

    this.loading = true;
    try {
      const response = yield* _await(api.auth.authToken(this.phoneNumber, token));
      api.config.token = response.token;
      this.token = response.token;
      this.me = new User(response.user);
      this.address = response.user.address;

      if (this.deviceToken) {
        api.notification.register(this.deviceToken, Platform.OS).catch((e) => console.warn(e));
      }
    } catch (e) {
      throw new Error(e);
    } finally {
      this.loading = false;
    }
  });
}

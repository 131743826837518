import React, { useState, useCallback } from "react";
import { Control, Controller } from "react-hook-form";
import * as ImagePicker from "expo-image-picker";
import { Button, EvaProp, Icon, Layout, Text, withStyles } from "@ui-kitten/components";
import { TouchableOpacity } from "react-native-gesture-handler";
import { View } from "native-base";
import { APICampaignInput } from "../../api/campaigns";
import Cover from "../Cover";

interface Props {
  control: Control;
  hasSeparateButtons?: boolean;
  state: APICampaignInput;
  showLabels?: boolean;
  eva?: EvaProp;
}

const hintText =
  "Your cover photo with an aspect ratio of around 2:1 works best, and must not exceed 5 MB. Please make sure you have rights to use any cover that you will upload.";

const CampaignStep2Form: React.FC<Props> = ({
  control,
  hasSeparateButtons = false,
  state,
  showLabels = false,
  eva,
}) => {
  const [canUseCamera, setCanUseCamera] = useState<boolean | null>(null);
  const [filename, setFilename] = useState("");

  const handleSetImagePress = useCallback(async (onChange: (...event: any[]) => void) => {
    const { status } = await ImagePicker.requestMediaLibraryPermissionsAsync();
    setCanUseCamera(status === "granted");
    if (status === "granted") {
      const result = await ImagePicker.launchImageLibraryAsync({
        mediaTypes: ImagePicker.MediaTypeOptions.Images,
        allowsEditing: true,
        aspect: [2, 1],
        quality: 1,
      });
      if (!result.cancelled) {
        onChange(result.uri);
        setFilename(result.uri);
      }
    }
  }, []);

  // TODO: Add "Remove cover" button

  const handleClearImagePress = useCallback(
    (onChange: (...event: any[]) => void) => {
      if (state?.cover) {
        onChange(state.cover);
        setFilename(state.cover);
      } else {
        onChange(null);
        setFilename("");
      }
    },
    [state]
  );

  return (
    <Controller
      name="cover"
      control={control}
      defaultValue={state.cover}
      render={({ field: { onChange, value } }) => (
        <Layout style={eva?.style?.formWrapper}>
          <View>
            {showLabels && <Text style={eva?.style?.labelText}>Cover Photo</Text>}

            <Cover height={270} uri={value || ""}>
              <Button
                appearance="ghost"
                disabled={canUseCamera === false}
                status="basic"
                accessoryLeft={(props) => (
                  <Icon {...props} width={40} height={40} name="camera-outline" />
                )}
                onPress={() => handleSetImagePress(onChange)}
              />
            </Cover>

            {hasSeparateButtons && (
              <Layout style={eva?.style?.buttons}>
                <Button
                  appearance="secondary"
                  status="basic"
                  disabled={canUseCamera === false}
                  onPress={() => handleSetImagePress(onChange)}
                >
                  {value ? "Change cover" : "Choose cover"}
                </Button>
                {value && (
                  <Button
                    appearance="ghost"
                    status="basic"
                    disabled={canUseCamera === false}
                    onPress={() => handleClearImagePress(onChange)}
                  >
                    Remove cover
                  </Button>
                )}

                {canUseCamera === false && (
                  <Text appearance="hint" status="danger" category="c1">
                    Permission to access media library was not granted.
                  </Text>
                )}
              </Layout>
            )}
          </View>

          <Layout style={eva?.style?.formStatusBar}>
            <Text category="c1" style={eva?.style?.hint} appearance="hint">
              {hintText}
            </Text>
            {filename.length > 0 && (
              <Layout style={eva?.style?.coverFile}>
                <Icon width={16} height={16} name="checkmark-outline" fill="#3488EC" />
                <Text style={eva?.style?.coverFileText}>File selected</Text>
                <TouchableOpacity
                  disabled={canUseCamera === false}
                  onPress={() => handleClearImagePress(onChange)}
                >
                  <Icon width={16} height={16} name="close-outline" fill="#3488EC" />
                </TouchableOpacity>
              </Layout>
            )}
          </Layout>
        </Layout>
      )}
    />
  );
};

export default withStyles(CampaignStep2Form, () => ({
  buttons: {
    marginVertical: 12,
  },
  hint: {
    width: "50%",
  },
  formStatusBar: {
    display: "flex",
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  formWrapper: {
    display: "flex",
    rowGap: 12,
    flexDirection: "column",
  },
  coverFile: {
    display: "flex",
    flexShrink: 1,
    flexDirection: "row",
    alignItems: "center",
    columnGap: 10,
    borderRadius: 100,
    backgroundColor: "rgba(52, 136, 236, 0.1)",
    paddingVertical: 4,
    paddingHorizontal: 16,
    maxHeight: 28,
  },
  coverFileText: {
    color: "#3488EC",
    fontFamily: "Jost_500Medium",
    fontWeight: "500",
    fontSize: 14,
    lineHeight: 20.2,
  },
  labelText: {
    fontFamily: "Jost_400Regular",
    fontWeight: "400",
    fontSize: 14,
    lineHeight: 20,
    color: "#757f87",
    marginBottom: 12,
  },
}));

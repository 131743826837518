import React from "react";
import { StyleSheet, Text, View } from "react-native";
import styled from "styled-components/native";
import { useNavigation } from "@react-navigation/native";
import Button from "./Button";
import useWindowSize from "../hooks/useWindowSize";
import { useStore } from "../store";

const styles = StyleSheet.create({
  hero: {
    fontSize: 64,
    lineHeight: 92,
  },
  mobileHero: {
    fontSize: 36,
    lineHeight: 52,
  },
  button: {
    width: 343,
  },
});

const Container = styled(View)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 64px;
  padding: 0px;
  position: absolute;
  left: 15.83%;
  right: 15.83%;
  top: 38.56%;
  bottom: 29.95%;
  height: auto;
`;

const HeroTextContainer = styled(View)`
  max-width: 880px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
`;

const HeroText = styled(Text)`
  font-family: "Jost_500Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 64px;
  line-height: 92px;
  text-align: center;
  font-feature-settings: "pnum" on, "lnum" on;
  color: #ffffff;
  text-shadow: 0px 4px 50px rgba(0, 0, 0, 0.5);
`;

const ButtonsContainer = styled(View)`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 12px;
  padding: 0;
  flex-grow: 0;
`;

interface Props {}

const HeroOverlay: React.FC<Props> = () => {
  const { isMobile } = useWindowSize();
  const store = useStore();
  const navigation = useNavigation();

  const handleExploreCampaigns = () => {
    navigation.navigate("Categories");
  };

  const handleStartCampaign = () => {
    if (!store.me) {
      navigation.navigate("SignIn", { next: "campaign-create" });
    } else {
      navigation.navigate("CampaignCreate");
    }
  };

  return (
    <Container>
      <HeroTextContainer>
        <HeroText style={[styles.hero, isMobile && styles.mobileHero]}>Raise funds and</HeroText>
        <HeroText style={[styles.hero, isMobile && styles.mobileHero]}>
          support causes that matter.
        </HeroText>
      </HeroTextContainer>
      <ButtonsContainer>
        <Button
          text="Explore Campaigns"
          onPress={handleExploreCampaigns}
          type="bluePrimary"
          containerStyle={styles.button}
        />
        <Button
          text="Start a Campaign"
          onPress={handleStartCampaign}
          containerStyle={styles.button}
        />
      </ButtonsContainer>
    </Container>
  );
};

export default HeroOverlay;

import React from "react";
import { StyleSheet, View, ViewProps } from "react-native";
import { Text } from "@ui-kitten/components";
import styled from "styled-components/native";
import Button from "./Button";
import ValoraQRCode from "./ValoraQRCode";
import PressableIcon from "./PressableIcon";

const styles = StyleSheet.create({
  containerMobile: {
    paddingTop: 36,
    paddingBottom: 36,
    paddingLeft: 24,
    paddingRight: 24,
    position: "absolute",
    width: 335,
  },
  button: {
    width: 250,
  },
});

interface ContainerProps extends ViewProps {
  isMobile: boolean;
}

const Container = styled(View)<ContainerProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 36px;
  gap: 24px;
  width: ${({ isMobile }) => (isMobile ? "335px" : "420px")};
  background: #ffffff;
  box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.06);
  border-radius: 10px;
`;

const CloseIcon = styled(PressableIcon)`
  width: 24px;
  height: 24px;
  position: absolute;
  right: 15px;
  top: 15px;
`;

const BackIcon = styled(PressableIcon)`
  width: 24px;
  height: 24px;
  position: absolute;
  left: 0px;
`;

const Header = styled(View)`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const Heading = styled(Text)`
  font-family: "Jost_500Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  display: flex;
  font-feature-settings: "pnum" on, "lnum" on;
  color: #2e3338;
`;

const Caption = styled(Text)`
  text-align: center;
  font-family: "Jost_400Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #757f87;
`;

interface Props extends ContainerProps {
  onClose: () => void;
  onBack: () => void;
  onOpenValoraApp: () => void;
}

const ValoraConnectDialog: React.FC<Props> = ({ onClose, onBack, onOpenValoraApp, ...props }) => {
  return (
    <Container {...props}>
      <CloseIcon iconName="close" fill="#2e3338" onPress={onClose} />
      <Header>
        <BackIcon iconName="arrow-ios-back" fill="#2e3338" onPress={onBack} />
        <Heading>Valora</Heading>
      </Header>
      <Caption>
        Open Valora Wallet. If it doesn&apos;t open, you can scan this QR code or if on a mobile
        device press Open
      </Caption>
      <ValoraQRCode />
      <Button onPress={onOpenValoraApp} text="Open Valora" containerStyle={styles.button} />
    </Container>
  );
};

export default ValoraConnectDialog;

import { CommonActions, useNavigation } from "@react-navigation/native";
import Toast, { SHORT } from "../components/Toast";

type NavigationProp = ReturnType<typeof useNavigation>;

// This function replaces the current screen with the error page.

const fail = (message?: string) =>
  Toast.show({
    type: "error",
    text1: message || "An error occurred.",
    visibilityTime: SHORT,
  });

const showErrorPage = (navigation: NavigationProp, message?: string) => {
  if (message) fail(message);

  navigation.dispatch((state) => {
    state.routes.pop();
    state.routes.push({ key: "error-page", name: "error" });

    return CommonActions.reset({
      ...state,
      index: state.routes.length - 1,
    });
  });
};

export default showErrorPage;

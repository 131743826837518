import { model, Model, prop } from "mobx-keystone";

@model("doni/Category")
class Category extends Model({
  cover: prop<string | null>(() => null),
  icon: prop<string | null>(() => null),
  id: prop<number | null>(() => null),
  name: prop<string | null>(() => null),
}) {}

export default Category;

import React from "react";
import InlineSVG, { InlineSVGProps } from "svg-inline-react";
import ProfileChipSVG from "../../assets/profile_chip.svg";

interface Props extends Omit<InlineSVGProps, "src" | "raw"> {}

const ProfileChip = (props: Props) => <InlineSVG src={ProfileChipSVG} raw {...props} />;

ProfileChip.defaultProps = {
  style: undefined,
};

export default ProfileChip;

import React from "react";
import {
  ColorValue,
  Image as ImageBase,
  ImageSourcePropType,
  Pressable,
  PressableProps,
} from "react-native";
import { Icon as IconBase } from "@ui-kitten/components";
import styled from "styled-components/native";
import { propFlexRow } from "../styles/containers";

const Icon = styled(IconBase)`
  width: 100%;
  height: 100%;
`;

const Image = styled(ImageBase)`
  width: 100%;
  height: 100%;
`;

const Container = styled(Pressable)<PressableProps>`
  ${propFlexRow};
  justify-content: center;
  ${({ onPress }) => (onPress ? "cursor: pointer;" : "")}
`;

export interface PressableIconProps extends PressableProps {
  iconName?: string;
  iconSource?: ImageSourcePropType;
  fill?: ColorValue;
  background?: ColorValue;
}

const PressableIcon: React.FC<PressableIconProps> = ({
  iconName,
  iconSource,
  fill,
  background,
  ...props
}) => {
  if (iconName) {
    return (
      <Container {...props}>
        <Icon name={iconName} style={{ fill, backgroundColor: background }} />
      </Container>
    );
  }
  if (iconSource) {
    return (
      <Container {...props}>
        <Image source={iconSource} style={{ tintColor: fill, backgroundColor: background }} />
      </Container>
    );
  }
  return <></>;
};

export default PressableIcon;

import React from "react";
import { StyleSheet } from "react-native";
import SearchBar, { SearchBarProps } from "./forms/SearchBar";

const styles = StyleSheet.create({
  searchBar: {
    marginVertical: 0,
    marginHorizontal: 0,
    backgroundColor: "none",
    border: 0,
  },
  searchBarContainer: {
    height: 38,
    width: "100%",
    flexGrow: 1,
    border: "1px solid #edeeef",
    borderRadius: 999999,
  },
  searchBarText: {
    fontFamily: "Jost_400Regular",
    fontSize: 16,
  },
});

export interface ContentSearchBarProps extends Omit<SearchBarProps, "onClear" | "onSubmit"> {
  onClear?: () => void;
  onSubmit?: (value: string) => void;
}

const ContentSearchBar: React.FC<ContentSearchBarProps> = ({
  onClear = () => {},
  onSubmit = () => {},
  style,
  ...props
}) => {
  return (
    <SearchBar
      {...props}
      placeholder="Search for campaigns"
      onClear={onClear}
      onSubmit={onSubmit}
      style={styles.searchBar}
      containerStyle={[styles.searchBarContainer, style]}
      textStyle={styles.searchBarText}
    />
  );
};

export default ContentSearchBar;

import React from "react";
import { ButtonProps, Button as BaseButton, TextProps } from "@ui-kitten/components";
import styled from "rn-css";
import { View, ViewStyle, StyleProp, ViewProps, Text } from "react-native";

type RGB = `rgb(${number}, ${number}, ${number})`;
type RGBA = `rgba(${number}, ${number}, ${number}, ${number})`;
type HEX = `#${string}`;

type Color = RGB | RGBA | HEX | "transparent";

interface ButtonStyle {
  textColor: Color;
  backgroundColor: Color;
  borderColor: Color;
  disabledTextColor: Color;
  disabledBackgroundColor: Color;
  disabledBorderColor: Color;
  hoverTextColor: Color;
  hoverBackgroundColor: Color;
  hoverBorderColor: Color;
}

type ButtonTypes =
  | "greenPrimary"
  | "greenSecondary"
  | "greenTertiary"
  | "invertedGreenTertiary"
  | "greenGhost"
  | "greenOutline"
  | "bluePrimary"
  | "blueSecondary"
  | "blueTertiary"
  | "blueGhost"
  | "blueOutline"
  | "blackGhost"
  | "graySecondary"
  | "redOutline"
  | "link";

const BUTTON_STYLES: { [key: string]: ButtonStyle } = {
  greenPrimary: {
    textColor: "#FFFFFF",
    backgroundColor: "#35D07F",
    borderColor: "#35D07F",
    disabledTextColor: "#DADADA",
    disabledBackgroundColor: "#EDEEEF",
    disabledBorderColor: "#EDEEEF",
    hoverTextColor: "#FFFFFF",
    hoverBackgroundColor: "#27A563",
    hoverBorderColor: "#27A563",
  },
  greenSecondary: {
    textColor: "#27A563",
    backgroundColor: "#35D07F33",
    borderColor: "#35D07F10",
    disabledTextColor: "#27A56380",
    disabledBackgroundColor: "#D7E2DB",
    disabledBorderColor: "#D7E2DB",
    hoverTextColor: "#27A563",
    hoverBackgroundColor: "#c8e0d1",
    hoverBorderColor: "#c8e0d1",
  },
  greenTertiary: {
    textColor: "#27A563",
    backgroundColor: "transparent",
    borderColor: "#27A563",
    disabledTextColor: "#27A56380",
    disabledBackgroundColor: "#FFFFFF",
    disabledBorderColor: "#27A56380",
    hoverTextColor: "#FFFFFF",
    hoverBackgroundColor: "#27A563",
    hoverBorderColor: "#27A563",
  },
  invertedGreenTertiary: {
    textColor: "#FFFFFF",
    backgroundColor: "#27A563",
    borderColor: "#FFFFFF",
    disabledTextColor: "#FFFFFF",
    disabledBackgroundColor: "#27A563",
    disabledBorderColor: "#FFFFFF",
    hoverTextColor: "#27A563",
    hoverBackgroundColor: "#FFFFFF",
    hoverBorderColor: "#FFFFFF",
  },
  greenGhost: {
    textColor: "#27A563",
    backgroundColor: "transparent",
    borderColor: "transparent",
    disabledTextColor: "#27A56380",
    disabledBackgroundColor: "transparent",
    disabledBorderColor: "transparent",
    hoverTextColor: "#1D7C4A",
    hoverBackgroundColor: "transparent",
    hoverBorderColor: "transparent",
  },
  greenOutline: {
    textColor: "#27A563",
    backgroundColor: "#35D07F33",
    borderColor: "#27A563",
    disabledTextColor: "#27A56380",
    disabledBackgroundColor: "#35D07F33",
    disabledBorderColor: "#27A56380",
    hoverTextColor: "#1D7C4A",
    hoverBackgroundColor: "#35D07F33",
    hoverBorderColor: "#1D7C4A",
  },
  bluePrimary: {
    textColor: "#FFFFFF",
    backgroundColor: "#3488EC",
    borderColor: "#3488EC",
    disabledTextColor: "#FFFFFF",
    disabledBackgroundColor: "#3488EC80",
    disabledBorderColor: "#3488EC80",
    hoverTextColor: "#FFFFFF",
    hoverBackgroundColor: "#115FBB",
    hoverBorderColor: "#115FBB",
  },
  blueSecondary: {
    textColor: "#3488EC",
    backgroundColor: "#3488EC33",
    borderColor: "#3488EC33",
    disabledTextColor: "#3488EC",
    disabledBackgroundColor: "#3488EC1A",
    disabledBorderColor: "#3488EC1A",
    hoverTextColor: "#3488EC",
    hoverBackgroundColor: "#BCC9DC",
    hoverBorderColor: "#BCC9DC",
  },
  blueTertiary: {
    textColor: "#3488EC",
    backgroundColor: "transparent",
    borderColor: "#3488EC",
    disabledTextColor: "#3488EC80",
    disabledBackgroundColor: "transparent",
    disabledBorderColor: "#3488EC80",
    hoverTextColor: "#FFFFFF",
    hoverBackgroundColor: "#3488EC",
    hoverBorderColor: "#3488EC",
  },
  blueGhost: {
    textColor: "#3488EC",
    backgroundColor: "transparent",
    borderColor: "transparent",
    disabledTextColor: "#3488EC80",
    disabledBackgroundColor: "transparent",
    disabledBorderColor: "transparent",
    hoverTextColor: "#0D478C",
    hoverBackgroundColor: "transparent",
    hoverBorderColor: "transparent",
  },
  blueOutline: {
    textColor: "#3488EC",
    backgroundColor: "#3488EC33",
    borderColor: "#3488EC",
    disabledTextColor: "#3488EC80",
    disabledBackgroundColor: "#3488EC1A",
    disabledBorderColor: "#3488EC80",
    hoverTextColor: "#115FBB",
    hoverBackgroundColor: "#3488EC33",
    hoverBorderColor: "#115FBB",
  },
  blackGhost: {
    textColor: "#2E3338",
    backgroundColor: "transparent",
    borderColor: "transparent",
    disabledTextColor: "#27A56380",
    disabledBackgroundColor: "transparent",
    disabledBorderColor: "transparent",
    hoverTextColor: "#27A563",
    hoverBackgroundColor: "transparent",
    hoverBorderColor: "transparent",
  },
  graySecondary: {
    textColor: "#757F87",
    backgroundColor: "#EDEEEF",
    borderColor: "transparent",
    disabledTextColor: "#757F8780",
    disabledBackgroundColor: "#EDEEEF",
    disabledBorderColor: "transparent",
    hoverTextColor: "#757F87",
    hoverBackgroundColor: "#e1e3e6",
    hoverBorderColor: "transparent",
  },
  redOutline: {
    textColor: "#FB7C6D",
    backgroundColor: "transparent",
    borderColor: "#FB7C6D",
    disabledTextColor: "#FB7C6D80",
    disabledBackgroundColor: "transparent",
    disabledBorderColor: "#FB7C6D80",
    hoverTextColor: "#FFFFFF",
    hoverBackgroundColor: "#FB7C6D",
    hoverBorderColor: "#FB7C6D",
  },
  link: {
    textColor: "#2E3338",
    backgroundColor: "transparent",
    borderColor: "transparent",
    disabledTextColor: "#ABADAF",
    disabledBackgroundColor: "transparent",
    disabledBorderColor: "transparent",
    hoverTextColor: "#27A563",
    hoverBackgroundColor: "transparent",
    hoverBorderColor: "transparent",
  },
};

interface Props extends ButtonProps {
  containerStyle?: StyleProp<ViewStyle>;
  backgroundColor?: string;
  text?: string;
  type?: ButtonTypes;
  removePadding?: boolean;
}

interface ButtonWrapperProps extends ViewProps {
  buttonStyling: ButtonStyle;
  disabled?: boolean;
  removePadding?: boolean;
}

const ButtonWrapper = styled(View)<ButtonWrapperProps>`
  background: transparent;
  border-radius: 25px;
  padding: 0 ${({ removePadding = false }) => (removePadding ? 0 : 25)}px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({
    buttonStyling: { backgroundColor, disabledBackgroundColor },
    disabled = false,
  }) => (disabled ? disabledBackgroundColor || backgroundColor : backgroundColor)};
  border-width: 1px;
  border-style: solid;
  border-color: ${({ buttonStyling: { borderColor, disabledBorderColor }, disabled = false }) =>
    disabled ? disabledBorderColor : borderColor};
  color: ${({ buttonStyling: { textColor, disabledTextColor }, disabled = false }) =>
    disabled ? disabledTextColor : textColor};
  fill: ${({ buttonStyling: { textColor, disabledTextColor }, disabled = false }) =>
    disabled ? disabledTextColor : textColor};
  transition-timing-function: ease-out;
  transition-duration: 0.3s;

  &:hover {
    background: ${({
      buttonStyling: { disabledBackgroundColor, hoverBackgroundColor },
      disabled = false,
    }) => (disabled ? disabledBackgroundColor : hoverBackgroundColor)};
    border-color: ${({
      buttonStyling: { disabledBorderColor, hoverBorderColor },
      disabled = false,
    }) => (disabled ? disabledBorderColor : hoverBorderColor)};
    color: ${({ buttonStyling: { hoverTextColor, disabledTextColor }, disabled = false }) =>
      disabled ? disabledTextColor : hoverTextColor};
    fill: ${({ buttonStyling: { hoverTextColor, disabledTextColor }, disabled = false }) =>
      disabled ? disabledTextColor : hoverTextColor};
  }
`;

interface StyledTextProps extends TextProps {
  disabled: boolean;
  textColor: Color;
  disabledTextColor: Color;
  hoverTextColor: Color;
}

const StyledText = styled(Text)<StyledTextProps>`
  font-family: "Jost_500Medium";
  font-size: 15px;
  line-height: 21.68px;
  color: inherit;
  transition-timing-function: ease-out;
  transition-duration: 0.3s;
  width: 100%;
  text-align: center;
`;

const Button = styled(BaseButton)`
  background: transparent;
  border: 0;
  width: 100%;
  fill: inherit;
`;

const StyledButton = React.forwardRef<typeof ButtonWrapper, Props>(
  (
    {
      containerStyle,
      disabled,
      backgroundColor,
      type = "greenPrimary",
      onLayout,
      onMouseEnter,
      onMouseLeave,
      children,
      text,
      removePadding = false,
      ...props
    },
    ref
  ) => {
    const buttonStyling = BUTTON_STYLES[type];

    let render: any = children;
    if (text) {
      render = () => (
        <StyledText disabled={!!disabled} {...buttonStyling}>
          {text}
        </StyledText>
      );
    }

    return (
      <ButtonWrapper
        ref={ref}
        style={containerStyle}
        buttonStyling={buttonStyling}
        disabled={!!disabled}
        removePadding={removePadding || type === "link"}
      >
        <Button size="large" disabled={disabled} {...props}>
          {render}
        </Button>
      </ButtonWrapper>
    );
  }
);

export default StyledButton;

import React from "react";
import { Image, Linking, Pressable as PressableBase, ViewProps } from "react-native";
import { Text } from "@ui-kitten/components";
import { observer } from "mobx-react-lite";
import InlineSVG from "svg-inline-react";
import styled from "rn-css";
import rnStyled from "styled-components/native";
import PressableIcon from "./PressableIcon";
import { FlexCol, FlexRow } from "../styles/containers";
import GreenDivider from "./Divider";
import valoraIcon from "../assets/valora_wallet.png";
import valoraText from "../assets/valora_text.png";
import getOnAppStore from "../assets/get_valora_on_appstore.svg";
import getOnPlayStore from "../assets/get_valora_on_playstore.svg";
import useListener from "../hooks/useListener";
import ValoraQRCode from "./ValoraQRCode";

const GOOGLE_PLAY_STORE_LINK = "https://apps.apple.com/us/app/valora-crypto-wallet/id1520414263";
const APP_APP_STORE_LINK = "https://play.google.com/store/apps/details?id=co.clabs.valora";

const CloseIcon = styled(PressableIcon)`
  width: 24px;
  height: 24px;
  position: absolute;
  right: 15px;
  top: 15px;
`;

const Heading = styled(Text)`
  font-family: "Jost_500Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 35px;
  display: flex;
  font-feature-settings: "pnum" on, "lnum" on;
  color: #2e3338;
`;

const ValoraIcon = rnStyled(Image)`
  width: 40px;
  height: 40px;
`;

const ValoraText = rnStyled(Image)`
  width: 90px;
  height: 24px;
`;

const Row = styled(FlexRow)`
  align-items: center;
  gap: 12px;
`;

const Pressable = styled(PressableBase)`
  cursor: pointer;
`;

const CaptionContainer = styled(FlexCol)`
  align-self: stretch;
  gap: 24px;
`;

const Caption = styled(Text)`
  font-family: "Jost_400Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  display: flex;
  align-items: center;
  color: #757f87;
`;

const Container = styled(FlexCol)`
  align-items: center;
  padding: 64px;
  gap: 48px;
  max-width: 538px;
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.06);
  border-radius: 10px;

  @media (max-width: 768px) {
    padding: 48px 24px;
    max-width: 335px;
  }
`;

const attemptOpenUrl = (url: string) => {
  Linking.canOpenURL(url).then((flag) => {
    if (flag) {
      Linking.openURL(url);
    }
  });
};

export interface ValoraSignupDialogProps extends ViewProps {
  onClose: () => void;
}

const ValoraSignupDialog: React.FC<ValoraSignupDialogProps> = ({ onClose, ...props }) => {
  const onAppStoreLinkOpen = useListener(() => attemptOpenUrl(GOOGLE_PLAY_STORE_LINK));

  const onPlayStoreLinkOpen = useListener(() => attemptOpenUrl(APP_APP_STORE_LINK));

  return (
    <Container {...props}>
      <CloseIcon iconName="close" fill="#2e3338" onPress={onClose} />
      <Heading>Sign up for:</Heading>
      <Row>
        <ValoraIcon source={valoraIcon} />
        <ValoraText source={valoraText} />
      </Row>
      <ValoraQRCode />
      <Row>
        <Pressable onPress={onAppStoreLinkOpen}>
          <InlineSVG raw src={getOnAppStore} width={123} height={36} />
        </Pressable>
        <Pressable onPress={onPlayStoreLinkOpen}>
          <InlineSVG raw src={getOnPlayStore} width={123} height={36} />
        </Pressable>
      </Row>
      <CaptionContainer>
        <GreenDivider />
        <Caption>
          Valora allows for a secure transfer of funds through the Celo network. Celo Dollars are
          digital value that you can pay, save and send using Valora.
        </Caption>
      </CaptionContainer>
    </Container>
  );
};

export default observer(ValoraSignupDialog);

import React from "react";
import InlineSVG, { InlineSVGProps } from "svg-inline-react";
import Onboarding4SVG from "../../assets/onboarding4.svg";

interface Props extends Omit<InlineSVGProps, "src" | "raw"> {}

const Onboarding4 = (props: Props) => <InlineSVG src={Onboarding4SVG} raw {...props} />;

Onboarding4.defaultProps = {
  style: undefined,
};

export default Onboarding4;

import moment from "moment";
import React from "react";
import { StyleSheet, ViewProps } from "react-native";
import { Text as TextBase } from "@ui-kitten/components";
import { observer } from "mobx-react-lite";
import styled from "rn-css";
import Campaign from "../store/models/Campaign";
import { FlexCol, FlexRow } from "../styles/containers";
import Button from "./Button";
import CampaignThumbnail from "./CampaignThumbnail";
import Divider from "./Divider";
import HorizontalRule from "./HorizontalRule";

const styles = StyleSheet.create({
  green: {
    color: "#27a563",
  },
});

const Text = styled(TextBase)`
  font-family: Jost_400Regular;
  font-style: normal;
  font-weight: 400;
  font-feature-settings: "pnum" on, "lnum" on;
  color: #757f87;
`;

const Header = styled(Text)`
  font-family: Jost_500Medium;
  font-weight: 500;
  font-size: 24px;
  line-height: 35px;
  color: #2e3338;
`;

const Subheader = styled(Text)`
  font-size: 16px;
  line-height: 23px;
`;

const HighlightInfoText = styled(Subheader)`
  font-size: 18px;
  line-height: 26px;
`;

const SpacedBetween = styled(FlexRow)`
  justify-content: space-between;
  gap: 12px;
`;

const InfoText = styled(Text)`
  font-family: Jost_400Regular;
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  color: #2e3338;
`;

const Group = styled(FlexCol)`
  gap: 12px;
`;

const Container = styled(FlexCol)`
  padding: 64px;
  gap: 24px;
  width: 538px;
  background: #ffffff;
  box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.06);
  border-radius: 10px;

  @media (max-width: 768px) {
    padding: 48px 20px;
    width: 100%;
  }
`;

export interface CampaignDonateConfirmDialogProps extends ViewProps {
  campaign: Campaign;
  amount: number;
  onCampaignShare?: () => void;
  onCampaignsExplore?: () => void;
}

const CampaignDonateConfirmDialog: React.FC<CampaignDonateConfirmDialogProps> = ({
  campaign,
  amount,
  onCampaignShare,
  onCampaignsExplore,
  ...props
}) => {
  const amountStr = amount.toFixed(2);

  return (
    <Container {...props}>
      <Header>Thank you!</Header>
      <Subheader>Your donation was successful.</Subheader>
      <Divider />
      <CampaignThumbnail
        campaign={campaign}
        caption={
          <Subheader numberOfLines={1}>
            Donated <Subheader style={styles.green}>{amountStr} cUSD</Subheader>
          </Subheader>
        }
      />
      <HorizontalRule />
      <Group>
        <SpacedBetween>
          <InfoText>Date Sent</InfoText>
          <InfoText>{moment().format("MM/DD/YYYY")}</InfoText>
        </SpacedBetween>
        <SpacedBetween>
          <HighlightInfoText>Your Donation</HighlightInfoText>
          <HighlightInfoText style={styles.green}>{amountStr} cUSD</HighlightInfoText>
        </SpacedBetween>
      </Group>
      <Group>
        <Button type="bluePrimary" text="Share Campaign" onPress={onCampaignShare} />
        <Button type="blueSecondary" text="Explore Campaigns" onPress={onCampaignsExplore} />
      </Group>
    </Container>
  );
};

export default observer(CampaignDonateConfirmDialog);

import { useMemo } from "react";
import { useStore } from "../store";
import { APICampaignListSearchParams } from "../api/campaigns";
import User from "../store/models/User";
import useCampaignList from "./useCampaignList";

const useUserCampaignList = ({
  user,
  page,
  searchParams,
}: { user?: User; page?: number; searchParams?: APICampaignListSearchParams } = {}) => {
  const { me } = useStore();
  const whichUser = user ?? me;
  return useCampaignList({
    searchParams: useMemo(() => ({ ...searchParams, organizer: whichUser?.id }), [
      whichUser,
      searchParams,
    ]),
    page,
  });
};

export default useUserCampaignList;

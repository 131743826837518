import { Text } from "@ui-kitten/components";
import { observer } from "mobx-react-lite";
import React from "react";
import { useNavigation } from "@react-navigation/native";
import { StyleSheet, ViewProps } from "react-native";
import styled from "rn-css";
import useListener from "../hooks/useListener";
import Campaign from "../store/models/Campaign";
import { FlexCol, FlexRow } from "../styles/containers";
import Button from "./Button";
import Divider from "./Divider";
import HorizontalRule from "./HorizontalRule";
import CampaignBalance from "./CampaignBalance";

const styles = StyleSheet.create({
  grayColor: {
    color: "#abadaf",
  },
  bold: {
    fontFamily: "Jost_600SemiBold",
    fontWeight: "600",
  },
  backButton: {
    maxWidth: 170,
    width: "100%",
    alignSelf: "center",
  },
});

const Header = styled(Text)`
  font-family: Jost_500Medium;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 35px;
  font-feature-settings: "pnum" on, "lnum" on;
  color: #2e3338;
`;

const Subheader = styled(Text)`
  font-family: Jost_400Regular;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  font-feature-settings: "pnum" on, "lnum" on;
  color: #757f87;
`;

const InfoText = styled(Text)`
  padding: 0px 8px;
  font-family: Jost_400Regular;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  font-feature-settings: "pnum" on, "lnum" on;
  color: #757f87;
`;

const CampaignBalanceContainer = styled(FlexCol)`
  gap: 12px;
`;
const SpacedBetween = styled(FlexRow)`
  justify-content: space-between;
  gap: 12px;
`;

const Container = styled(FlexCol)`
  padding: 64px 64px 24px;
  gap: 24px;
  width: 538px;
  background: #ffffff;
  box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.06);
  border-radius: 10px;

  @media (max-width: 768px) {
    padding: 48px 20px;
    width: 100%;
    height: 100%;
    border-radius: 0;
  }
`;

export interface CampaignWithdrawDialogProps extends ViewProps {
  campaign: Campaign;
  onWithdraw: () => Promise<void>;
}

const CampaignWithdrawDialog: React.FC<CampaignWithdrawDialogProps> = ({
  campaign,
  onWithdraw,
  ...props
}) => {
  const navigation = useNavigation();
  const onBack = useListener(() => {
    if (navigation.canGoBack()) {
      navigation.goBack();
    } else {
      navigation.navigate("Dashboard");
    }
  });

  return (
    <Container {...props}>
      <Header>Withdraw Funds</Header>
      <Subheader>
        You are withdrawing funds from{" "}
        <Subheader style={styles.bold}>{campaign.name ?? ""}</Subheader>
      </Subheader>
      <Divider />
      <CampaignBalanceContainer>
        <CampaignBalance balance={campaign.balanceDecimal} />
        <SpacedBetween>
          <InfoText>Total Received:</InfoText>
          <InfoText>cUSD {campaign.totalReceivedDecimal}</InfoText>
        </SpacedBetween>
      </CampaignBalanceContainer>
      <HorizontalRule />
      <Button
        type="greenPrimary"
        text="Withdraw Funds"
        onPress={onWithdraw}
        disabled={campaign.balance.isZero() || campaign.balance.isNegative()}
      />
      <Button type="link" text="Back" containerStyle={styles.backButton} onPress={onBack} />
    </Container>
  );
};

export default observer(CampaignWithdrawDialog);

import base, { APIList, APISearchParams, config } from "./base";

export interface APICategory {
  cover: string;
  icon: string;
  id: number;
  name: string;
}

export interface APICategoryList extends APIList<APICategory> {}
export interface APICategoryListSearchParams extends APISearchParams {
  dropdown?: true;
}
export const list = (
  page = 1,
  limit = config.defaultLimit,
  searchParams?: APICategoryListSearchParams
) =>
  base.list<APICategoryList, APICategoryListSearchParams>("categories/", page, limit, searchParams);

export const getAllCampaignsCategory = () =>
  base.get<APICategory>("categories/all_campaigns_category/");

import { createDrawerNavigator } from "@react-navigation/drawer";
import { useTheme } from "@ui-kitten/components";
import { StatusBar } from "expo-status-bar";
import { observer } from "mobx-react-lite";
import React from "react";
import { useStore } from "../store";
import CampaignCreateScreen from "./CampaignCreateScreen";
import CampaignDonateScreen from "./CampaignDonateScreen";
import CampaignEditScreen from "./CampaignEditScreen";
import CampaignScreen from "./CampaignScreen";
import FundraiserScreen from "./FundraiserScreen";
import CategoryListScreen from "./CategoryListScreen";
import Dashboard from "./Dashboard";
import EditProfileScreen from "./EditProfileScreen";
import SignIn from "./SignIn";
import HomeScreen from "./HomeScreen";
import WalletConnectScreen from "./WalletConnectScreen";
import TermsAndConditionScreen from "./TermsAndConditionScreen";
import PrivacyPolicyScreen from "./PrivacyPolicyScreen";
import FAQScreen from "./FAQScreen";
import SafeArea from "../components/SafeArea";
import DrawerComponent from "../components/DrawerComponent";
import CampaignCategoryExploreScreen from "./CampaignCategoryExploreScreen";
import SettingsScreen from "./SettingsScreen";
import CampaignWithdrawScreen from "./CampaignWithdrawScreen";
import ErrorScreen from "./ErrorScreen";

interface Props {}

const { Navigator, Screen } = createDrawerNavigator();

const Navigation: React.FC<Props> = () => {
  const theme = useTheme();
  const store = useStore();
  const { token, me } = store;
  const isSignedIn = !!token && !!me;

  return (
    <SafeArea>
      <Navigator drawerContent={(props) => <DrawerComponent {...props} />}>
        {/* Always-accessible pages */}
        <Screen name="error" component={ErrorScreen} options={{ title: "Doni | Error" }} />
        <Screen name="Home" component={HomeScreen} options={{ title: "Doni" }} />
        <Screen name="CampaignDetail" component={CampaignScreen} options={{ title: "Doni" }} />
        <Screen name="FundraiserDetail" component={FundraiserScreen} options={{ title: "Doni" }} />
        <Screen
          name="Categories"
          component={CategoryListScreen}
          options={{ title: "Doni | Categories" }}
        />
        <Screen
          name="CampaignCategoryExplore"
          component={CampaignCategoryExploreScreen}
          options={{ title: "Doni" }}
        />
        <Screen
          name="TermsAndCondition"
          component={TermsAndConditionScreen}
          options={{ title: "Doni | Terms and Condition" }}
        />
        <Screen
          name="PrivacyPolicy"
          component={PrivacyPolicyScreen}
          options={{ title: "Doni | Privacy Policy" }}
        />
        <Screen
          name="FAQ"
          component={FAQScreen}
          options={{ title: "Doni | Frequently Asked Questions" }}
        />

        {/* Conditionally-accessible pages */}
        {isSignedIn ? (
          <>
            <Screen
              name="Dashboard"
              component={Dashboard}
              options={{ title: "Doni | My Campaigns" }}
            />
            <Screen
              name="CampaignCreate"
              component={CampaignCreateScreen}
              options={{ title: "Doni | Create Campaign" }}
            />
            <Screen name="CampaignEdit" component={CampaignEditScreen} />
            <Screen
              name="CampaignDonate"
              component={CampaignDonateScreen}
              options={{ title: "Doni | Donate" }}
            />
            <Screen
              name="EditProfile"
              component={EditProfileScreen}
              options={{ title: "Doni | Profile" }}
            />
            <Screen
              name="WalletConnect"
              component={WalletConnectScreen}
              options={{ title: "Doni | Connect Wallet" }}
            />
            <Screen
              name="CampaignWithdraw"
              component={CampaignWithdrawScreen}
              options={{ title: "Doni" }}
            />
            <Screen
              name="Settings"
              component={SettingsScreen}
              options={{ title: "Doni | Settings" }}
            />
          </>
        ) : (
          <>
            <Screen name="SignIn" component={SignIn} options={{ title: "Doni | Login" }} />
          </>
        )}
      </Navigator>
      <StatusBar style="dark" translucent backgroundColor={theme["background-basic-color-1"]} />
    </SafeArea>
  );
};

export default observer(Navigation);

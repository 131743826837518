import React from "react";
import InlineSVG, { InlineSVGProps } from "svg-inline-react";
import BubbleChartSVG from "../../assets/bubble_chart.svg";

interface Props extends Omit<InlineSVGProps, "src" | "raw"> {}

const BubbleChart = (props: Props) => <InlineSVG src={BubbleChartSVG} raw {...props} />;

BubbleChart.defaultProps = {
  style: undefined,
};

export default BubbleChart;

import { Layout, Text } from "@ui-kitten/components";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { StyleSheet, Text as RNText, TouchableOpacity } from "react-native";
import {
  CodeField,
  Cursor,
  useBlurOnFulfill,
  useClearByFocusCell,
} from "react-native-confirmation-code-field";
import useEffectOnce from "react-use/lib/useEffectOnce";
import styled from "rn-css";
import { E164Number } from "libphonenumber-js/types";
import api from "../api";
import Button from "./Button";
import DividerBase from "./Divider";
import Toast, { SHORT } from "./Toast";
import useWindowSize from "../hooks/useWindowSize";
import { useStore } from "../store";
import { obfuscatePhoneNumber } from "../utils/phoneNumber";

const Divider = styled(DividerBase)`
  margin-bottom: 24px;
`;

interface Props {
  phoneNumber: E164Number;
  onClose: () => void;
  showError: (errorMessage: string) => void;
  onFinish: () => void;
}

const styles = StyleSheet.create({
  cardContainer: {
    display: "flex",
    paddingHorizontal: 24,
    paddingTop: 30,
    paddingBottom: 48,
    borderRadius: 10,
    shadowOffset: {
      width: 0,
      height: 4,
    },
    shadowOpacity: 0.06,
    shadowRadius: 11,
  },
  cardContainerDesktop: {
    minWidth: 538,
    paddingHorizontal: 64,
    paddingTop: 64,
    paddingBottom: 64,
  },
  header: {
    marginBottom: 24,
  },
  hint: {
    marginBottom: 24,
  },
  tokenInput: {
    height: 40,
    borderColor: "gray",
    borderWidth: 1,
    marginTop: 10,
    marginBottom: 10,
    padding: 5,
  },
  codeFieldRoot: { marginVertical: 38, gap: 12, justifyContent: "center" },
  cell: {
    width: 40,
    height: 56,
    lineHeight: 56,
    fontSize: 24,
    borderWidth: 1,
    borderColor: "#DDDDDD",
    textAlign: "center",
    borderRadius: 4,
  },
  focusCell: {
    borderColor: "#35D07F",
  },
  tryAgain: {
    display: "flex",
    alignItems: "center",
    marginTop: 24,
  },
  resendCode: {
    marginTop: 24,
    color: "#3488EC",
    fontFamily: "Jost_500Medium",
    fontSize: 15,
  },
});

const CELL_COUNT = 6;

const CallbackToken: React.FC<Props> = ({ phoneNumber, onClose, showError, onFinish }) => {
  const store = useStore();
  const { loading, token: authToken } = store;
  const [token, setToken] = useState("");
  const [showTryAgain, setShowTryAgain] = useState(false);
  const { isMobile } = useWindowSize();

  const ref = useBlurOnFulfill({ value: token, cellCount: CELL_COUNT });
  const [props, getCellOnLayoutHandler] = useClearByFocusCell({
    value: token,
    setValue: setToken,
  });

  useEffectOnce(() => {
    setTimeout(() => {
      setShowTryAgain(true);
    }, 30000);
  });

  const handlePress = async () => {
    try {
      await store.authToken(token);
      onClose();
      onFinish();
    } catch (e) {
      await api.handleError(e);
      showError("You've entered the wrong digit code");
      setToken("");
      ref.current!.focus();
    }
  };

  const handleTryAgain = () => {
    onClose();
  };

  if (!phoneNumber) {
    return null;
  }

  return (
    <Layout style={[styles.cardContainer, !isMobile && styles.cardContainerDesktop]}>
      <Text category="h5" style={styles.header}>
        Authenticate
      </Text>

      <Text appearance="hint" style={styles.hint}>
        Enter the 6-digit code sent to {obfuscatePhoneNumber(phoneNumber)}
      </Text>

      <Divider />

      <CodeField
        ref={ref}
        {...props}
        autoFocus
        value={token}
        onChangeText={setToken}
        cellCount={CELL_COUNT}
        rootStyle={styles.codeFieldRoot}
        keyboardType="number-pad"
        textContentType="oneTimeCode"
        renderCell={({ index, symbol, isFocused }) => (
          <RNText
            key={index}
            style={[styles.cell, isFocused && styles.focusCell]}
            onLayout={getCellOnLayoutHandler(index)}
          >
            {symbol || (isFocused ? <Cursor /> : null)}
          </RNText>
        )}
      />

      <Button
        appearance="secondary"
        size="large"
        status={token.length === CELL_COUNT ? "primary" : "basic"}
        disabled={token.length < CELL_COUNT || loading || !!authToken}
        onPress={handlePress}
        text="Next"
      />

      {showTryAgain && (
        <Layout style={styles.tryAgain}>
          <Text category="c1" appearance="hint">
            Didn&rsquo;t get the SMS notification?
          </Text>
          <TouchableOpacity onPress={handleTryAgain}>
            <Text style={styles.resendCode}>Resend Code</Text>
          </TouchableOpacity>
        </Layout>
      )}
    </Layout>
  );
};

export default observer(CallbackToken);

import { EvaProp, Layout, Text, withStyles } from "@ui-kitten/components";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import TabView from "../components/TabView";
import Tab from "../components/Tab";
import useWindowSize from "../hooks/useWindowSize";
import MainLayout from "../layouts/MainLayout";
import { useStore } from "../store";
import SettingsProfileForm from "../components/forms/SettingsProfileForm";
import SettingsWalletConnect from "../components/SettingsWalletConnect";

interface Props {
  eva?: EvaProp;
}

// const TERMS_URL = "https://doni.app/terms/";
// const PRIVACY_URL = "https://doni.app/privacy/";

const SettingsScreen: React.FC<Props> = ({ eva }) => {
  const { me } = useStore();
  const { isTablet } = useWindowSize();

  const defaultValues = me?.asAPI;
  const profileMethods = useForm({ defaultValues });
  const [isLoading, setIsLoading] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);

  return (
    <MainLayout loading={isLoading} loadingText="Loading Settings...">
      <Layout style={eva?.style?.mainContent}>
        <Layout style={isTablet ? eva?.style?.mainMobile : eva?.style?.mainCard}>
          <Text style={eva?.style?.title}>Settings</Text>
          <TabView selectedIndex={selectedIndex} onSelect={(index) => setSelectedIndex(index)}>
            <Tab title="My Account">
              <FormProvider {...profileMethods}>
                <SettingsProfileForm
                  loading={isLoading}
                  setLoading={setIsLoading}
                  isTablet={isTablet}
                />
              </FormProvider>
            </Tab>
            <Tab title="Wallet and Payments">
              <SettingsWalletConnect />
            </Tab>
          </TabView>
        </Layout>
      </Layout>
    </MainLayout>
  );
};

export default withStyles(observer(SettingsScreen), () => ({
  mainContent: {
    backgroundColor: "#f8f9f9",
    flex: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  mainCard: {
    flexShrink: 1,
    width: "80%",
    maxWidth: 1100,
    paddingHorizontal: 128,
    paddingVertical: 64,
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: 64,
    marginBottom: 64,
    boxShadow: "0px 4px 11px 0px #0000000F",
    border: 0,
    borderRadius: 10,
    overflow: "hidden",
    rowGap: 22,
  },
  mainMobile: {
    flex: 1,
    width: "100%",
    paddingHorizontal: 20,
    paddingVertical: 48,
    paddingTop: 68,
    paddingBottom: 25,
    rowGap: 22,
  },
  title: {
    width: "100%",
    fontFamily: "Jost_500Medium",
    fontWeight: "500",
    fontSize: 24,
    lineHeight: 35,
  },
}));

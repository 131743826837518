import BigNumber from "bignumber.js";
import * as FileSystem from "expo-file-system";
import base, { APIDetail, APIList, APISearchParams, config } from "./base";

export interface APICampaign {
  id: number;
  organizer: number;
  organizerName: string;
  organizerPhoto: string | null;
  created: string;
  modified: string;
  endsIn: number | null;
  name: string;
  purpose: string;
  category: number;
  categoryName: string;
  location: string | null;
  endDate: string | null;
  cover: string | null;
  content: string;
  isDraft: boolean;
  videoUrl: string | null;
  webUrl: string;
  isApproved: boolean | null;
  reason: string | null;
  valoraQrCode: string | null;

  contractAddress: string | null;
}

export type APICampaignInput = Partial<
  Omit<
    APICampaign,
    | "id"
    | "organizer"
    | "organizerName"
    | "organizerPhoto"
    | "created"
    | "modified"
    | "categoryName"
    | "category"
  >
> & {
  category?: number | null;
  goal?: BigNumber;
};

export interface APICampaignDetail extends APIDetail<APICampaign> {}
export const detail = (campaignId: number | string) =>
  base.get<APICampaignDetail>(`campaigns/${campaignId}/`);

export interface APICampaignList extends APIList<APICampaign> {}
export interface APICampaignListSearchParams extends APISearchParams {
  dropdown?: true;
}
export const list = (
  page = 1,
  limit = config.defaultLimit,
  searchParams?: APICampaignListSearchParams
) =>
  base.list<APICampaignList, APICampaignListSearchParams>("campaigns/", page, limit, searchParams);
export const listMine = (
  page = 1,
  limit = config.defaultLimit,
  searchParams?: APICampaignListSearchParams
) =>
  base.list<APICampaignList, APICampaignListSearchParams>(
    "campaigns/mine/",
    page,
    limit,
    searchParams
  );

export const listForReview = (
  page = 1,
  limit = config.defaultLimit,
  searchParams?: APICampaignListSearchParams
) =>
  base.list<APICampaignList, APICampaignListSearchParams>(
    "campaigns/for-review/",
    page,
    limit,
    searchParams
  );

export const approval = (id: number, json: Partial<APICampaignInput>) =>
  base.patch<APICampaign>(`campaigns/${id}/approve/`, { json });

async function createUpdateKy(input: APICampaignInput, campaignId?: number) {
  const json = { ...input };

  // If input contains the old photo, remove it from the request body.
  // Otherwise, set it to `null` so that API knows to delete the photo.
  if (json.cover) {
    delete json.cover;
  } else {
    json.cover = null;
  }

  if (campaignId) {
    return base.put<APICampaignUpdate>(`campaigns/${campaignId}/`, {
      json,
    });
  }
  return base.post<APICampaignUpdate>("campaigns/", { json });
}

async function createUpdateFS(input: APICampaignInput, campaignId?: number) {
  const formData = new FormData();
  formData.append("contractAddress", input.contractAddress || "");
  formData.append("name", input.name || "");
  formData.append("purpose", input.purpose || "");
  formData.append("content", input.content || "");
  formData.append("location", input.location || "");
  formData.append("category", `${input.category!}`);
  formData.append("endDate", input.endDate || "");
  formData.append("isDraft", input.isDraft ? "true" : "false");

  const extension = input.cover!.substring("data:image/".length, input.cover!.indexOf(";base64"));
  const res = await fetch(input.cover!);
  const blob = await res.blob();
  formData.append("cover", blob, `cover.${extension}`);

  if (campaignId) {
    return base.put<APICampaignUpdate>(`campaigns/${campaignId}/`, {
      body: formData,
    });
  }

  return base.post<APICampaignUpdate>("campaigns/", { body: formData });
}

export interface APICampaignCreate extends APIDetail<APICampaignInput> {}
export const create = (input: Partial<APICampaignInput>) => {
  if (!input.cover || !input.cover.startsWith("data:image/")) {
    return createUpdateKy(input);
  }
  return createUpdateFS(input);
};

export interface APICampaignUpdate extends APIDetail<APICampaign> {}
export const update = (id: number, input: Partial<APICampaignInput>) => {
  if (!input.cover || !input.cover.startsWith("data:image/")) {
    return createUpdateKy(input, id);
  }
  return createUpdateFS(input, id);
};

import React, { useState } from "react";
import { useNavigation } from "@react-navigation/native";
import { Pressable, StyleSheet, View } from "react-native";
import { Icon, Text as TextBase } from "@ui-kitten/components";
import { Menu, MenuOption, MenuOptions, MenuTrigger } from "react-native-popup-menu";
import { observer } from "mobx-react-lite";
import styled from "rn-css";
import MainLayoutBase, { MainLayoutProps } from "../layouts/MainLayout";
import { APICampaignListSearchParams } from "../api/campaigns";
import Campaign from "../store/models/Campaign";
import LogoGray from "../components/svgs/LogoGray";
import Button from "../components/Button";
import { FlexCol, FlexRow, placeCenter, propFlexRow } from "../styles/containers";
import useListener from "../hooks/useListener";
import NoCampaign from "../components/svgs/NoCampaign";
import useWindowSize from "../hooks/useWindowSize";
import useUserCampaignList from "../hooks/useUserCampaignList";
import CampaignQRCodeDialog from "../components/CampaignQRCodeDialog";
import { responsive } from "../utils/responsive";
import CampaignGallery from "../components/CampaignGallery";
import { useStore } from "../store";

const styles = StyleSheet.create({
  button: {
    width: 240,
    height: 40,
  },
  buttonMobile: {
    width: "100%",
    maxWidth: 343,
    height: 40,
  },
  noCampaignButton: {
    width: 343,
    height: 40,
  },
  lightBlueText: {
    color: "#3488ec",
  },
  loadMore: {
    width: 240,
    alignSelf: "center",
    height: 40,
  },
  loadMoreMobile: {
    width: "100%",
  },
});

const filtersDropdownStyles = StyleSheet.create({
  optionsWrapper: {
    backgroundColor: "transparent",
    boxShadow: "unset",
  },
  optionsContainer: {
    backgroundColor: "#transparent",
    boxShadow: "unset",
  },
  optionWrapper: {
    padding: 0,
  },
});

const MainLayout = styled(MainLayoutBase)<{ isMobile: boolean }>`
  padding: ${responsive("24px 20px", "36px 128px")};
  gap: ${responsive(16, 36)}px;
`;

const Header = styled(View)`
  display: flex;
  flex-direction: ${responsive("column", "row")};
  align-items: stretch;
  justify-content: space-between;
  gap: 8px;
`;

const Text = styled(TextBase)`
  font-family: "Jost_400Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  font-feature-settings: "pnum" on, "lnum" on;
  color: #2e3338;
`;

const Heading = styled(Text)<{ isMobile: boolean }>`
  ${responsive(
    `
    font-family: "Jost_500Medium";
    font-weight: 500;
    font-size: 20px;
    line-height: 29px;
    color: "#2e3338";
    `,
    `
    font-family: "Jost_400Regular";
    font-weight: 400;
    font-size: 18px;
    line-height: 26px;
    color: "#757f87";
    `
  )}
`;

const Info = styled(FlexRow)`
  align-items: center;
  gap: 16px;
`;

const FiltersContainer = styled(FlexCol)`
  background: #ffffff;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  overflow: hidden;
`;

const FiltersLabel = styled(Text)<{ isMobile: boolean }>`
  text-transform: capitalize;
  font-family: "Jost_500Medium";
  font-weight: 500;
  font-size: ${responsive(14, 16)}px;
  line-height: ${responsive(20, 23)}px;
  display: flex;
  align-items: center;
  font-feature-settings: "pnum" on, "lnum" on;
  color: #115fbb;
`;

const OptionContainer = styled(FlexRow)`
  align-items: center;
  padding: 16px 20px;
  border-left-width: 4px;
  border-left-color: transparent;

  &:hover {
    border-left-color: #3488ec;
    background-color: #eaf3fc;
  }
`;

const OptionText = styled(Text)<{ isMobile: boolean }>`
  font-family: "Jost_500Medium";
  font-weight: 500;
  font-size: ${responsive(14, 15)}px;
  line-height: ${responsive(20, 22)}px;
  color: #2e3338;
`;

const DropdownWrapper = styled(Pressable)`
  ${propFlexRow};
  align-items: center;
  gap: 12px;
  padding: ${responsive("8px 0px", 0)}px;
  border-radius: 999999px;
`;

const EmptyGallery = styled(FlexCol)`
  align-items: center;
  padding: 0px 64px 64px;
  gap: 48px;
`;

const NoCampaignText = styled(Text)<{ isMobile: boolean }>`
  ${responsive(
    `
    max-width: 335px;
    font-size: 20px;
    line-height: 29px;
    `,
    `
    max-width: 540px;
    font-size: 36px;
    line-height: 52px;
    `
  )}
  font-family: "Jost_500Medium";
  font-style: normal;
  font-weight: 500;
  text-align: center;
  font-feature-settings: "pnum" on, "lnum" on;
  color: #2e3338;
`;

const LogoContainer = styled(FlexRow)`
  justify-content: center;
  padding: 24px;
`;

const DropdownIcon = styled(Icon)`
  width: 16px;
  height: 16px;
  fill: #115fbb;
  transform: rotate(-90deg);
`;

const StickyFooter = styled(View)`
  z-index: 10;
  position: sticky;
  bottom: 0px;
  width: 100%;
  padding: 20px;
  background: #ffffff;
  border: 1px solid #edeeef;
  box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.05);
`;

interface Props extends MainLayoutProps {}

const Dashboard: React.FC<Props> = () => {
  const navigation = useNavigation();
  const { me } = useStore();
  const { isMobile } = useWindowSize();
  const [page, setPage] = useState(1);
  const [searchParams, setSearchParams] = useState<APICampaignListSearchParams>({});
  const { results: filteredCampaigns, loading, next, count } = useUserCampaignList({
    page,
    searchParams,
  });
  const [contextCampaign, setContextCampaign] = useState<Campaign>();
  const [qrCodeModalVisible, setQrCodeModalVisible] = useState(false);
  const [showFiltersDropdown, setShowFiltersDropdown] = useState(false);
  const [filter, setFilter] = useState("all");

  const onFilterSelect = useListener((value) => {
    setShowFiltersDropdown(false);
    setPage(1);
    setFilter(value);

    switch (value) {
      case "all":
        setSearchParams({});
        break;

      case "active":
        setSearchParams({ is_draft: false });
        break;

      case "draft":
        setSearchParams({ is_draft: true });
        break;

      default:
        break;
    }
  });

  const onFiltersBackdropPress = useListener(() => {
    setShowFiltersDropdown(false);
  });

  const onFilterDropdownPress = useListener(() => {
    setShowFiltersDropdown((flag) => !flag);
  });

  const onStartCampaignPress = useListener(() => {
    if (me) {
      navigation.navigate("CampaignCreate");
    } else {
      navigation.navigate("SignIn", { next: "campaign-create" });
    }
  });

  const onCampaignManagePress = useListener((campaign) => {
    navigation.navigate("CampaignEdit", { id: campaign.id });
  });

  const onCampaignSharePress = useListener((campaign) => {
    setContextCampaign(campaign);
    setQrCodeModalVisible(true);
  });

  const onQrCodeModalHide = useListener(() => {
    setQrCodeModalVisible(false);
  });

  return (
    <MainLayout
      isMobile={isMobile}
      loading={loading && page === 1}
      loadingText="Loading Campaigns..."
      footer={
        isMobile && (
          <StickyFooter style={placeCenter}>
            <Button
              text="Start a Campaign"
              type="greenPrimary"
              containerStyle={styles.buttonMobile}
              onPress={onStartCampaignPress}
            />
          </StickyFooter>
        )
      }
      modalDialog={
        <CampaignQRCodeDialog campaign={contextCampaign as Campaign} onClose={onQrCodeModalHide} />
      }
      showModalDialog={qrCodeModalVisible}
      onModalBackdropPress={onQrCodeModalHide}
    >
      <Header isMobile={isMobile}>
        <Heading isMobile={isMobile}>My Campaigns</Heading>
        {isMobile ? (
          <Text>
            You have <Text style={styles.lightBlueText}>{count}</Text>{" "}
            {filter !== "all" ? `${filter} ` : ""}
            campaigns
          </Text>
        ) : (
          <Button
            text="Start a Campaign"
            type="greenPrimary"
            containerStyle={styles.button}
            onPress={onStartCampaignPress}
          />
        )}
      </Header>
      <Info>
        {!isMobile && (
          <Text>
            You have <Text style={styles.lightBlueText}>{count}</Text>{" "}
            {filter !== "all" ? `${filter} ` : ""}
            campaigns
          </Text>
        )}
        <Menu
          opened={showFiltersDropdown}
          onSelect={onFilterSelect}
          onBackdropPress={onFiltersBackdropPress}
        >
          <MenuTrigger>
            <DropdownWrapper isMobile={isMobile} onPress={onFilterDropdownPress}>
              <FiltersLabel isMobile={isMobile}>{filter}</FiltersLabel>
              <DropdownIcon name="arrow-ios-back" />
            </DropdownWrapper>
          </MenuTrigger>
          <MenuOptions customStyles={filtersDropdownStyles}>
            <FiltersContainer>
              <MenuOption value="all">
                <OptionContainer>
                  <OptionText isMobile={isMobile}>All</OptionText>
                </OptionContainer>
              </MenuOption>
              <MenuOption value="active">
                <OptionContainer>
                  <OptionText isMobile={isMobile}>Active</OptionText>
                </OptionContainer>
              </MenuOption>
              <MenuOption value="draft">
                <OptionContainer>
                  <OptionText isMobile={isMobile}>Draft</OptionText>
                </OptionContainer>
              </MenuOption>
            </FiltersContainer>
          </MenuOptions>
        </Menu>
      </Info>
      {filteredCampaigns.length > 0 ? (
        <CampaignGallery
          campaigns={filteredCampaigns}
          showFundingMeters
          showDeadlines
          onCampaignManagePress={onCampaignManagePress}
          onCampaignSharePress={onCampaignSharePress}
        />
      ) : (
        <EmptyGallery>
          <NoCampaign />
          <NoCampaignText isMobile={isMobile}>
            We&rsquo;re looking forward to seeing your first campaign!
          </NoCampaignText>
          {!isMobile && (
            <Button
              text="Start a Campaign"
              type="greenPrimary"
              containerStyle={styles.noCampaignButton}
              onPress={onStartCampaignPress}
            />
          )}
        </EmptyGallery>
      )}
      {next ? (
        <Button
          text="Load more..."
          disabled={loading}
          onPress={() => setPage(page + 1)}
          containerStyle={[styles.loadMore, isMobile && styles.loadMoreMobile]}
        />
      ) : (
        <LogoContainer>
          <LogoGray width={35} height={35} />
        </LogoContainer>
      )}
    </MainLayout>
  );
};

export default observer(Dashboard);

import base from "./base";

export const register = (deviceToken: string, os: string) =>
  base.patchEmpty("push_notifications/register/", {
    json: { deviceToken, os },
  });

export const unregister = (deviceToken: string) =>
  base.patchEmpty("push_notifications/unregister/", {
    json: { deviceToken },
  });

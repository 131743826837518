import { View } from "react-native";
import styled from "rn-css";

const HorizontalRule = styled(View)`
  width: 100%;
  height: 1px;
  background-color: #edeeef;
`;

export default HorizontalRule;

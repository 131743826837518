import { useNavigation, useRoute } from "@react-navigation/native";
import { Divider, Layout, Text } from "@ui-kitten/components";
import { isValidPhoneNumber } from "react-phone-number-input";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { ImageBackground, StyleSheet, TouchableOpacity } from "react-native";
import { E164Number } from "libphonenumber-js/types";
import InlineSVG from "svg-inline-react";
import styled from "rn-css";
import api from "../api";
import MainLayout, { MainLayoutProps } from "../layouts/MainLayout";
import PhoneInput from "../components/PhoneInput";
import LogoWithText from "../components/svgs/LogoWithText";
import useWindowSize from "../hooks/useWindowSize";
import SignInHero from "../assets/signin-hero.png";
import LogoWithTextWhite from "../assets/logo_with_text_white.svg";
import Button from "../components/Button";
import GreenDividerBase from "../components/Divider";
import Toast, { SHORT } from "../components/Toast";
import LoginDialog from "../components/LoginDialog";
import { useStore } from "../store";
import useListener from "../hooks/useListener";
import ValoraSignupDialog from "../components/ValoraSignupDialog";

interface Props extends MainLayoutProps {}

const GreenDivider = styled(GreenDividerBase)`
  margin-bottom: 24px;
`;

const SignInCard = styled(Layout)`
  padding: 64px 56px;
  display: flex;
  box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.06);

  @media (max-width: 768px) {
    width: 100%;
    box-shadow: none;
    flex: 1;
  }
`;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    display: "flex",
  },
  row: {
    flex: 1,
    display: "flex",
    flexDirection: "row",
  },
  hero: {
    backgroundColor: "transparent",
    justifyContent: "center",
  },
  heroImage: {
    flex: 1,
  },
  heroContainer: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgba(15, 153, 81, 0.6)",
    paddingHorizontal: 64,
  },
  layout: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#F8F9F9",
    height: "100%",
  },
  mobileLayout: {
    alignSelf: "stretch",
  },
  heroText: {
    fontFamily: "Jost_500Medium",
    fontSize: 52,
    color: "#FFFFFF",
    marginBottom: 58,
  },
  logo: {
    width: 200,
    height: 75,
    marginBottom: 48,
    alignSelf: "center",
  },
  signInTitle: {
    fontFamily: "Jost_500Medium",
    fontSize: 24,
    marginBottom: 24,
  },
  signInDescription: {
    fontFamily: "Jost_400Regular",
    color: "#757F87",
    marginBottom: 24,
  },
  signInDivider: {
    marginVertical: 24,
  },
  valoraSignupText: {
    alignSelf: "center",
  },
  valoraSignup: {
    color: "#35D07F",
    fontFamily: "Jost_700Bold",
    fontSize: 15,
  },
});

const fail = (errorMessage: string) => {
  Toast.show({
    type: "error",
    text1: errorMessage,
    visibilityTime: SHORT,
  });
};

const SignIn: React.FC<Props> = () => {
  const store = useStore();
  const navigation = useNavigation();
  const route = useRoute();
  const [phoneNumber, setPhoneNumber] = useState<E164Number>("");
  const [showLoginDialog, setShowLoginDialog] = useState(false);
  const [showValoraDialog, setShowValoraDialog] = useState(false);
  const { isMobile, isTablet } = useWindowSize();
  const { next = "dashboard", campaignId = "" } = (route?.params || {}) as {
    next: string;
    campaignId: string;
  };

  const [loading, setLoading] = useState(false);

  const handlePress = async () => {
    if (!isValidPhoneNumber(phoneNumber)) {
      fail("Not a valid phone number");
      return;
    }

    try {
      store.setPhoneNumber(phoneNumber);
    } catch (e) {
      await api.handleError(e);
      fail("Sign in failed");
      return;
    }

    try {
      setLoading(true);
      await store.callbackToken();
      setLoading(false);
      setShowLoginDialog(true);
    } catch (e) {
      await api.handleError(e);
      setLoading(false);
    }
  };

  const handleCloseLoginDialog = () => setShowLoginDialog(false);

  const handleFinish = () => {
    const address = store.me?.address;
    if (store.me && store.me.name.length === 0) {
      navigation.navigate("EditProfile");
    } else if (address == null || address === "") {
      navigation.navigate("WalletConnect");
    } else {
      switch (next) {
        case "campaign-create":
          navigation.navigate("CampaignCreate");
          break;
        case "campaign-donate":
          navigation.navigate("CampaignDonate", { id: campaignId });
          break;
        case "dashboard":
        default:
          navigation.navigate("Dashboard");
          break;
      }
    }
  };

  const onValoraSignupPress = useListener(() => {
    setShowValoraDialog(true);
  });

  const onValoraDialogClose = useListener(() => {
    setShowValoraDialog(false);
  });

  return (
    <MainLayout
      showFooter={false}
      modalDialog={
        showLoginDialog ? (
          <LoginDialog
            phoneNumber={phoneNumber}
            onClose={handleCloseLoginDialog}
            showError={fail}
            onFinish={handleFinish}
          />
        ) : (
          <ValoraSignupDialog onClose={onValoraDialogClose} />
        )
      }
      showModalDialog={showLoginDialog || showValoraDialog}
      onModalBackdropPress={onValoraDialogClose}
    >
      <Layout style={styles.row}>
        {!isTablet && (
          <ImageBackground
            source={{
              uri: SignInHero || "",
              cache: "force-cache",
            }}
            style={styles.heroImage}
          >
            <Layout style={styles.heroContainer}>
              <Layout style={styles.hero}>
                <Text category="h2" style={styles.heroText}>
                  Give yourself the power to change lives and fund what&apos;s special to you.
                </Text>
                <InlineSVG src={LogoWithTextWhite} raw />
              </Layout>
            </Layout>
          </ImageBackground>
        )}
        <Layout style={[styles.layout, isMobile && styles.mobileLayout]}>
          <SignInCard>
            {!isMobile && (
              <Layout style={styles.logo}>
                <LogoWithText height={44} />
              </Layout>
            )}
            <Text style={styles.signInTitle}>Login</Text>
            <Text style={styles.signInDescription}>
              Please enter the phone number connected to your Valora account
            </Text>
            <GreenDivider />
            <PhoneInput value={phoneNumber} onChange={setPhoneNumber} />
            <Button disabled={loading} onPress={handlePress} text="Sign In" />
            <Divider style={styles.signInDivider} />
            <Text style={styles.valoraSignupText}>
              Don&apos;t have a Valora account?{" "}
              <TouchableOpacity onPress={onValoraSignupPress}>
                <Text style={styles.valoraSignup}>Sign up</Text>
              </TouchableOpacity>
            </Text>
          </SignInCard>
        </Layout>
      </Layout>
    </MainLayout>
  );
};

export default observer(SignIn);

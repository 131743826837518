import React from "react";
import { Image as ImageBase, ImageSourcePropType } from "react-native";
import { Icon as IconBase, Text as TextBase } from "@ui-kitten/components";
import { MenuOption as MenuOptionBase, MenuOptionProps } from "react-native-popup-menu";
import styled from "styled-components/native";
import { propFlexRow } from "../styles/containers";

const iconStyle = {
  width: 20,
  height: 20,
  fill: "#35d07f",
  color: "#35d07f",
};

const MenuOption = styled(MenuOptionBase)`
  ${propFlexRow};
  padding: 12px 24px;
  gap: 8px;
  background: #ffffff;
`;

const Icon = styled(IconBase)`
  width: 20px;
  height: 20px;
  fill: #35d07f;
  border-radius: 2.8px;
`;

const Image = styled(ImageBase)`
  width: 20px;
  height: 20px;
  fill: #35d07f;
  border-radius: 2.8px;
`;

const Text = styled(TextBase)`
  font-family: "Jost_500Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 23px;
  font-feature-settings: "pnum" on, "lnum" on;
  color: #2e3338;
`;

export interface CampaignShareOptionItemProps extends MenuOptionProps {
  icon?: JSX.Element;
  iconName?: string;
  iconSource?: ImageSourcePropType;
  text: string;
}

const CampaignShareOptionItem: React.FC<CampaignShareOptionItemProps> = ({
  icon,
  iconName,
  iconSource,
  text,
  ...props
}) => {
  let iconElement: JSX.Element = <></>;
  if (icon) {
    iconElement = React.cloneElement(icon, {
      ...icon.props,
      style: { ...iconStyle, ...icon.props?.style },
    });
  } else if (iconName) {
    iconElement = <Icon name={iconName} />;
  } else if (iconSource) {
    iconElement = <Image source={iconSource} />;
  }

  return (
    <MenuOption {...props}>
      {iconElement}
      <Text>{text}</Text>
    </MenuOption>
  );
};

export default CampaignShareOptionItem;

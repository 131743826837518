import React from "react";
import { View, ViewProps } from "react-native";
import styled from "styled-components/native";

const Container = styled(View)`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  overflow: hidden;
  border-radius: 999999px;
`;

const Blank = styled(View)<{ percentage: number }>`
  width: ${({ percentage }) => percentage}%;
  transition: width 0.15s;
`;

const Fill = styled(Blank)<{ fillColor: string }>`
  background-color: ${({ fillColor }) => fillColor};
`;

export interface ProgressBarProps extends Omit<ViewProps, "children"> {
  progress: number;
  progressFillColor: string;
}

const ProgressBar: React.FC<ProgressBarProps> = ({ progress, progressFillColor, ...props }) => {
  return (
    <Container {...props}>
      <Fill percentage={100 * progress} fillColor={progressFillColor} />
      <Blank percentage={100 * (1 - progress)} />
    </Container>
  );
};

export default ProgressBar;

import CampaignJSON from "@doni/contracts/build/contracts/Campaign.json";
import BigNumber from "bignumber.js";
import {
  getRootStore,
  model,
  Model,
  modelFlow,
  prop,
  _async,
  _await,
  modelAction,
} from "mobx-keystone";
import { computed } from "mobx";
import kit from "../../utils/kit";
import { APICampaign, APICampaignInput } from "../../api/campaigns";

function convertToContractDecimals(balance: BigNumber, decimals: number) {
  return balance.dividedBy(new BigNumber(10).pow(decimals)).decimalPlaces(2);
}

const BIGNUMBER_ZERO = new BigNumber("0.00");

@model("doni/Campaign")
class Campaign extends Model({
  id: prop<number | null>(() => null),
  organizer: prop<number | null>(() => null),
  organizerName: prop<string | null>(() => null),
  organizerPhoto: prop<string | null>(() => null),
  created: prop<string | null>(() => null),
  modified: prop<string | null>(() => null),
  endsIn: prop<number | null>(() => null),
  name: prop<string | null>(() => null),
  purpose: prop<string | null>(() => null),
  category: prop<number | null>(() => null),
  categoryName: prop<string | null>(() => null),
  location: prop<string | null>(() => null),
  endDate: prop<string | null>(() => null),
  cover: prop<string | null>(() => null),
  content: prop<string | null>(() => null),
  isDraft: prop<boolean>(() => false),
  videoUrl: prop<string | null>(() => null),
  webUrl: prop<string | null>(() => null),
  isApproved: prop<boolean | null>(() => null),
  reason: prop<string | null>(() => null),
  revision: prop<number | null>(() => null),
  valoraQrCode: prop<string | null>(() => null),

  contractAddress: prop<string | null>(() => null),

  goalWei: prop<string>(() => "0"),
  balanceWei: prop<string>(() => "0"),
  totalReceivedWei: prop<string>(() => "0"),
}) {
  private monitor?: number | NodeJS.Timeout;

  private cUSDDecimals = 18;

  onAttachedToRootStore() {
    this.watch();
    this.monitor = setInterval(() => {
      this.watch();
    }, 10000);
    return () => {
      if (this.monitor) {
        clearInterval(this.monitor as number);
      }
    };
  }

  @modelAction
  update(apiData: APICampaign) {
    this.organizer = apiData.organizer;
    this.organizerName = apiData.organizerName;
    this.modified = apiData.modified;
    this.endsIn = apiData.endsIn;
    this.name = apiData.name;
    this.purpose = apiData.purpose;
    this.category = apiData.category;
    this.categoryName = apiData.categoryName;
    this.location = apiData.location;
    this.endDate = apiData.endDate;
    this.cover = apiData.cover;
    this.content = apiData.content;
    this.isDraft = apiData.isDraft;
    this.videoUrl = apiData.videoUrl;
    this.valoraQrCode = apiData.valoraQrCode;
    this.contractAddress = apiData.contractAddress;
  }

  @modelFlow
  watch = _async(function* (this: Campaign) {
    const rootStore = getRootStore<any>(this);

    if (!this.contractAddress) {
      return;
    }

    const contract = new kit.web3.eth.Contract(CampaignJSON.abi as any[], this.contractAddress);
    contract.options.from = rootStore!.address;

    try {
      const resultGoal = (yield* _await(contract.methods.getGoal().call())) as string;
      if (resultGoal) {
        this.goalWei = resultGoal;
      }
    } catch (e) {
      console.error(e);
    }

    try {
      const resultBalance = (yield* _await(contract.methods.getBalanceCUSD().call())) as string;
      if (resultBalance) {
        this.balanceWei = resultBalance;
      }
    } catch (e) {
      console.error(e);
    }

    try {
      const resultTotalReceived = (yield* _await(
        contract.methods.getTotalReceived().call()
      )) as string;
      if (resultTotalReceived) {
        this.totalReceivedWei = resultTotalReceived;
      }
    } catch (e) {
      console.error(e);
    }
  });

  @computed
  get goal() {
    return new BigNumber(this.goalWei);
  }

  @computed
  get balance() {
    return new BigNumber(this.balanceWei);
  }

  @computed
  get totalReceived() {
    return new BigNumber(this.totalReceivedWei);
  }

  @computed
  get goalCUSD() {
    return convertToContractDecimals(this.goal, this.cUSDDecimals!);
  }

  @computed
  get goalDecimal() {
    return convertToContractDecimals(this.goal, this.cUSDDecimals!).toFormat();
  }

  @computed
  get balanceDecimal() {
    return convertToContractDecimals(this.balance, this.cUSDDecimals!).toFormat();
  }

  @computed
  get totalReceivedDecimal() {
    return convertToContractDecimals(this.totalReceived, this.cUSDDecimals!).toFormat();
  }

  @computed
  get percentFunding() {
    if (this.goal.isEqualTo(BIGNUMBER_ZERO)) {
      return 0.0;
    }
    return this.totalReceived.div(this.goal).toNumber();
  }

  @computed
  get asAPI(): APICampaignInput {
    return {
      endsIn: this.endsIn,
      name: this.name || undefined,
      purpose: this.purpose || undefined,
      category: this.category,
      location: this.location,
      endDate: this.endDate,
      cover: this.cover,
      content: this.content || undefined,
      isDraft: this.isDraft,
      videoUrl: this.videoUrl,
      contractAddress: this.contractAddress,
      goal: convertToContractDecimals(this.goal, this.cUSDDecimals),
    };
  }
}

export default Campaign;

const all: { [key: string]: string } = {
  "Midway Islands – UTC-11:00": "Pacific/Midway",
  "Samoa – UTC-11:00": "Pacific/Samoa",
  "Hawaii – UTC-10:00": "Pacific/Honolulu",
  "Alaska – UTC-09:00": "US/Alaska",
  "Pacific Time (US & Canada) – UTC-08:00": "America/Los_Angeles",
  "Tijuana – UTC-08:00": "America/Tijuana",
  "Arizona – UTC-07:00": "US/Arizona",
  "Chihuahua – UTC-07:00": "America/Chihuahua",
  "Mazatlan – UTC-07:00": "America/Mazatlan",
  "Mountain Time (US & Canada) – UTC-07:00": "US/Mountain",
  "Central America – UTC-06:00": "America/Managua",
  "Central Time (US & Canada) – UTC-06:00": "US/Central",
  "Mexico City – UTC-06:00": "America/Mexico_City",
  "Monterrey – UTC-06:00": "America/Monterrey",
  "Saskatchewan – UTC-06:00": "Canada/Saskatchewan",
  "Bogota – UTC-05:00": "America/Bogota",
  "Eastern Time (US & Canada) – UTC-05:00": "US/Eastern",
  "Indiana (East) – UTC-05:00": "US/East-Indiana",
  "Lima – UTC-05:00": "America/Lima",
  "Quito – UTC-05:00": "America/Bogota",
  "Atlantic Time (Canada) – UTC-04:00": "Canada/Atlantic",
  "Caracas – UTC-04:30": "America/Caracas",
  "La Paz – UTC-04:00": "America/La_Paz",
  "Santiago – UTC-04:00": "America/Santiago",
  "Newfoundland – UTC-03:30": "Canada/Newfoundland",
  "Brasilia – UTC-03:00": "America/Sao_Paulo",
  "Buenos Aires – UTC-03:00": "America/Argentina/Buenos_Aires",
  "Greenland – UTC-03:00": "America/Godthab",
  "Atlantic Islands – UTC-02:00": "America/Noronha",
  "Azores – UTC-01:00": "Atlantic/Azores",
  "Cape Verde – UTC-01:00": "Atlantic/Cape_Verde",
  "Casablanca – UTC+00:00": "Africa/Casablanca",
  "GMT / Dublin – UTC+00:00": "Etc/Greenwich",
  "Lisbon – UTC+00:00": "Europe/Lisbon",
  "London – UTC+00:00": "Europe/London",
  "Monrovia – UTC+00:00": "Africa/Monrovia",
  "UTC – UTC+00:00": "UTC",
  "Amsterdam – UTC+01:00": "Europe/Amsterdam",
  "Belgrade – UTC+01:00": "Europe/Belgrade",
  "Berlin – UTC+01:00": "Europe/Berlin",
  "Bratislava – UTC+01:00": "Europe/Bratislava",
  "Brussels – UTC+01:00": "Europe/Brussels",
  "Budapest – UTC+01:00": "Europe/Budapest",
  "Copenhagen – UTC+01:00": "Europe/Copenhagen",
  "Ljubljana – UTC+01:00": "Europe/Ljubljana",
  "Madrid – UTC+01:00": "Europe/Madrid",
  "Paris – UTC+01:00": "Europe/Paris",
  "Prague – UTC+01:00": "Europe/Prague",
  "Rome – UTC+01:00": "Europe/Rome",
  "Sarajevo – UTC+01:00": "Europe/Sarajevo",
  "Skopje – UTC+01:00": "Europe/Skopje",
  "Stockholm – UTC+01:00": "Europe/Stockholm",
  "Vienna – UTC+01:00": "Europe/Vienna",
  "Warsaw – UTC+01:00": "Europe/Warsaw",
  "West Africa – UTC+01:00": "Africa/Lagos",
  "Zagreb – UTC+01:00": "Europe/Zagreb",
  "Athens – UTC+02:00": "Europe/Athens",
  "Bucharest – UTC+02:00": "Europe/Bucharest",
  "Cairo – UTC+02:00": "Africa/Cairo",
  "Harare – UTC+02:00": "Africa/Harare",
  "Helsinki – UTC+02:00": "Europe/Helsinki",
  "Istanbul – UTC+02:00": "Europe/Istanbul",
  "Jerusalem – UTC+02:00": "Asia/Jerusalem",
  "Kyiv – UTC+02:00": "Europe/Helsinki",
  "Pretoria – UTC+02:00": "Africa/Johannesburg",
  "Riga – UTC+02:00": "Europe/Riga",
  "Sofia – UTC+02:00": "Europe/Sofia",
  "Tallinn – UTC+02:00": "Europe/Tallinn",
  "Vilnius – UTC+02:00": "Europe/Vilnius",
  "Baghdad – UTC+03:00": "Asia/Baghdad",
  "Kuwait – UTC+03:00": "Asia/Kuwait",
  "Minsk – UTC+03:00": "Europe/Minsk",
  "Nairobi – UTC+03:00": "Africa/Nairobi",
  "Riyadh – UTC+03:00": "Asia/Riyadh",
  "Volgograd – UTC+03:00": "Europe/Volgograd",
  "Tehran – UTC+03:30": "Asia/Tehran",
  "Abu Dhabi – UTC+04:00": "Asia/Muscat",
  "Baku – UTC+04:00": "Asia/Baku",
  "Moscow – UTC+04:00": "Europe/Moscow",
  "Muscat – UTC+04:00": "Asia/Muscat",
  "Tbilisi – UTC+04:00": "Asia/Tbilisi",
  "Yerevan – UTC+04:00": "Asia/Yerevan",
  "Kabul – UTC+04:30": "Asia/Kabul",
  "Karachi – UTC+05:00": "Asia/Karachi",
  "Tashkent – UTC+05:00": "Asia/Tashkent",
  "Kolkata – UTC+05:30": "Asia/Kolkata",
  "Kathmandu – UTC+05:45": "Asia/Katmandu",
  "Dhaka – UTC+06:00": "Asia/Dhaka",
  "Ekaterinburg – UTC+06:00": "Asia/Yekaterinburg",
  "Rangoon – UTC+06:30": "Asia/Rangoon",
  "Bangkok – UTC+07:00": "Asia/Bangkok",
  "Jakarta – UTC+07:00": "Asia/Jakarta",
  "Novosibirsk – UTC+07:00": "Asia/Novosibirsk",
  "Chongqing – UTC+08:00": "Asia/Chongqing",
  "Hong Kong – UTC+08:00": "Asia/Hong_Kong",
  "Krasnoyarsk – UTC+08:00": "Asia/Krasnoyarsk",
  "Kuala Lumpur – UTC+08:00": "Asia/Kuala_Lumpur",
  "Manila – UTC+08:00": "Asia/Manila",
  "Perth – UTC+08:00": "Australia/Perth",
  "Singapore – UTC+08:00": "Asia/Singapore",
  "Taipei – UTC+08:00": "Asia/Taipei",
  "Ulaan Bataar – UTC+08:00": "Asia/Ulan_Bator",
  "Urumqi – UTC+08:00": "Asia/Urumqi",
  "Irkutsk – UTC+09:00": "Asia/Irkutsk",
  "Seoul – UTC+09:00": "Asia/Seoul",
  "Tokyo – UTC+09:00": "Asia/Tokyo",
  "Adelaide – UTC+09:30": "Australia/Adelaide",
  "Darwin – UTC+09:30": "Australia/Darwin",
  "Brisbane – UTC+10:00": "Australia/Brisbane",
  "Canberra – UTC+10:00": "Australia/Canberra",
  "Guam – UTC+10:00": "Pacific/Guam",
  "Hobart – UTC+10:00": "Australia/Hobart",
  "Melbourne – UTC+10:00": "Australia/Melbourne",
  "Port Moresby – UTC+10:00": "Pacific/Port_Moresby",
  "Sydney – UTC+10:00": "Australia/Sydney",
  "Yakutsk – UTC+10:00": "Asia/Yakutsk",
  "Vladivostok – UTC+11:00": "Asia/Vladivostok",
  "Auckland – UTC+12:00": "Pacific/Auckland",
  "Fiji – UTC+12:00": "Pacific/Fiji",
  "International Date Line West – UTC+12:00": "Pacific/Kwajalein",
  "Kamchatka – UTC+12:00": "Asia/Kamchatka",
  "Magadan – UTC+12:00": "Asia/Magadan",
  "Marshall Island – UTC+12:00": "Pacific/Fiji",
  "Wellington – UTC+12:00": "Pacific/Auckland",
  "Nuku'alofa – UTC+13:00": "Pacific/Tongatapu",
};

export default {
  all,
};

import { useEffect, useState } from "react";
import Toast, { SHORT } from "../components/Toast";
import { useStore } from "../store";

export default function useCampaign(id: string | number) {
  const store = useStore();
  const [actualId, setActualId] = useState<number>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<any>();

  let idNum = typeof id === "string" ? parseInt(id, 10) : id;
  // If this is not the latest revision of this campaign, use the id of the latest revision instead
  if (actualId) {
    idNum = actualId;
  }

  let campaign = idNum === 0 ? store.campaigns.preview : store.campaigns.listItems.get(idNum);
  if (campaign && campaign.revision && campaign?.organizer === store.me?.id) {
    const revisionId =
      typeof campaign.revision === "string" ? parseInt(campaign.revision, 10) : campaign.revision;
    campaign = store.campaigns.listItems.get(revisionId);
  }
  useEffect(() => {
    if ((!campaign && idNum > 0) || (campaign && campaign.revision)) {
      setLoading(true);
      setError(undefined);
      store.campaigns
        .get(idNum)
        .then((fetchedCampaign) => {
          // A later revision was fetched for this campaign
          if (fetchedCampaign && fetchedCampaign.id !== idNum) {
            setActualId(fetchedCampaign.id as number);
          }
        })
        .catch((e) => {
          Toast.show({ type: "error", text1: "Campaign not found", visibilityTime: SHORT });
          setError(e);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [campaign, store, idNum]);

  return { campaign, error, loading };
}

import {
  Avatar,
  Button,
  EvaProp,
  Icon,
  Input,
  Layout,
  Text,
  withStyles,
} from "@ui-kitten/components";
import * as ImagePicker from "expo-image-picker";
import { observer } from "mobx-react-lite";
import React, { useCallback, useState } from "react";
import { Control, Controller, DeepMap, FieldError, useFormContext } from "react-hook-form";
import { Pressable, View } from "react-native";
import { APIUserInput } from "../../api/users";
import LocationPin from "../svgs/LocationPin";
import TimezoneAutocomplete from "./TimezoneAutocomplete";
// import RoundedButton from "../Button";
import timezones from "../../utils/timezones";

interface Props {
  control: Control;
  eva?: EvaProp;
  errors: DeepMap<APIUserInput, FieldError>;
  isLoading?: boolean;
}

const ProfileForm: React.FC<Props> = ({ eva, control, errors, isLoading = false }) => {
  const { getValues, setValue, register } = useFormContext();
  const [canUseCamera, setCanUseCamera] = useState<boolean | null>(null);

  const handleSetImagePress = useCallback(async (onChange: (...event: any[]) => void) => {
    const { status } = await ImagePicker.requestMediaLibraryPermissionsAsync();
    setCanUseCamera(status === "granted");
    if (status === "granted") {
      const result = await ImagePicker.launchImageLibraryAsync({
        mediaTypes: ImagePicker.MediaTypeOptions.Images,
        allowsEditing: true,
        aspect: [1, 1],
        quality: 1,
      });
      if (!result.cancelled) {
        onChange(result.uri);
      }
    }
  }, []);

  const handleClearImagePress = useCallback((onChange: (...event: any[]) => void) => {
    onChange(null);
  }, []);

  /* Special handling for timezone picker */

  // This creates a "hidden" form parameter for the IANA timezone
  register("timezone", { required: "Timezone is required." });

  // helper function: IANA -> UTC
  const ianaToUtc = (iana: string) => {
    const tz = Object.entries(timezones.all).filter(([, ianaCode]) =>
      ianaCode.toLowerCase().includes((iana || "").toLowerCase())
    );
    return tz.length > 0 ? tz[0][0] : "";
  };

  // helper function: UTC -> IANA
  const utcToIana = (utc: string) => {
    const tz = Object.entries(timezones.all).filter(([utcName]) =>
      utcName.toLowerCase().includes((utc || "").toLowerCase())
    );
    return tz.length > 0 ? tz[0][1] : "";
  };

  // state storing the timezone picker's current value
  const [utcTimezone, setUtcTimezone] = useState<string>(ianaToUtc(getValues("timezone")));

  // handler function: picking/typing a new value
  const handleTimezoneChange = useCallback(
    (value: string) => {
      setUtcTimezone(value);
      setValue("timezone", utcToIana(value));
    },
    [setValue]
  );

  // handler function: blurring the picker
  const handleTimezoneBlur = useCallback(() => {
    setUtcTimezone(ianaToUtc(getValues("timezone")));
  }, [getValues]);
  /* End of timezone picker handling */

  // TODO: Connect Twitter Account to data store
  // const handleConnectTwitter = useCallback(() => {}, []);

  return (
    <Layout style={eva?.style?.container}>
      <Controller
        name="photo"
        control={control}
        render={({ field: { onChange, value } }) => (
          <>
            <Pressable
              style={eva?.style?.avatarWrapper}
              disabled={canUseCamera === false || isLoading}
              onPress={() => handleSetImagePress(onChange)}
            >
              <Avatar
                height={90}
                source={{ uri: value, cache: "force-cache" }}
                size="giant"
                style={eva?.style?.avatar}
              />
              {value ? <View style={eva?.style?.avatarTint} /> : null}
              {!value && (
                <Icon
                  width={24}
                  height={24}
                  fill="#abadaf"
                  name="camera"
                  style={eva?.style?.avatarIcon}
                />
              )}
            </Pressable>

            {value && (
              <Button
                appearance="ghost"
                status="basic"
                disabled={canUseCamera === false || isLoading}
                onPress={() => handleClearImagePress(onChange)}
                style={eva?.style?.avatarRemove}
              >
                Remove photo
              </Button>
            )}

            {canUseCamera === false && (
              <Text appearance="hint" status="danger" category="c1">
                Permission to access media library was not granted.
              </Text>
            )}
          </>
        )}
      />
      <Controller
        name="name"
        control={control}
        rules={{
          required: "This field is required.",
        }}
        render={({ field: { onChange, onBlur, value } }) => (
          <Input
            caption={errors.name?.message}
            disabled={isLoading}
            onBlur={onBlur}
            onChangeText={(next) => onChange(next)}
            placeholder="Name*"
            size="large"
            status={errors.name ? "danger" : "basic"}
            style={eva?.style?.input}
            value={value || ""}
            textStyle={eva?.style?.inputInner}
          />
        )}
      />
      <Controller
        name="location"
        control={control}
        render={({ field: { onChange, onBlur, value } }) => (
          <Input
            caption={errors.location?.message}
            disabled={isLoading}
            onBlur={onBlur}
            onChangeText={(next) => onChange(next)}
            placeholder="Location"
            size="large"
            status={errors.location ? "danger" : "basic"}
            style={eva?.style?.input}
            value={value || ""}
            accessoryRight={() => <LocationPin style={eva?.style?.locationPin} />}
            textStyle={eva?.style?.inputInner}
          />
        )}
      />
      <Layout style={eva?.style?.fullWidth}>
        <TimezoneAutocomplete
          caption={errors.timezone?.message}
          disabled={isLoading}
          onBlur={handleTimezoneBlur}
          onFormChange={handleTimezoneChange}
          placeholder="Timezone"
          size="large"
          status={errors.timezone ? "danger" : "basic"}
          style={eva?.style?.input}
          value={utcTimezone}
          textStyle={eva?.style?.inputInner}
        />
      </Layout>
      <Controller
        name="bio"
        control={control}
        render={({ field: { onChange, onBlur, value } }) => (
          <Input
            textAlignVertical="top"
            caption={errors.bio?.message}
            disabled={isLoading}
            onBlur={onBlur}
            onChangeText={(next) => onChange(next)}
            multiline
            numberOfLines={7}
            placeholder="About Yourself"
            size="large"
            status={errors.bio ? "danger" : "basic"}
            style={eva?.style?.bioInput}
            textStyle={eva?.style?.bio}
            value={value || ""}
          />
        )}
      />

      {/*
      <RoundedButton
        onPress={() => handleConnectTwitter()}
        status="basic"
        containerStyle={eva?.style?.button}
        backgroundColor="rgba(52, 136, 236, 0.2)"
        type="blueSecondary"
      >
        {(evaProps) => (
          <>
            <Icon name="twitter" fill="#3488EC" style={eva?.style?.socialsIcon} />
            <Text {...evaProps} style={eva?.style?.twitterButtonText}>
              Connect Twitter Account
            </Text>
          </>
        )}
      </RoundedButton>
      */}
    </Layout>
  );
};

export default withStyles(observer(ProfileForm), (theme) => ({
  container: {
    display: "flex",
    alignItems: "center",
    rowGap: 8,
  },
  avatarWrapper: {
    width: 90,
    height: 90,
    position: "relative",
    backgroundColor: theme["background-basic-color-3"],
    borderRadius: 45,
  },
  avatar: {
    width: 90,
    height: 90,
    position: "absolute",
  },
  avatarIcon: {
    position: "absolute",
    top: 32,
    left: 32,
  },
  avatarTint: {
    backgroundColor: "rgba(0, 0, 0, 0.35)",
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    borderRadius: 45,
  },
  avatarRemove: {
    marginBottom: 24,
  },
  h1: {
    fontFamily: "Jost_500Medium",
    fontWeight: "500",
    fontSize: 16,
    lineHeight: 32,
    marginTop: 16,
    marginBottom: 16,
    width: "100%",
  },
  input: {
    width: "100%",
    borderRadius: 100,
    paddingVertical: 0,
    paddingHorizontal: 0,
    backgroundColor: "#ffffff",
  },
  inputInner: {
    marginLeft: 16,
    marginRight: 16,
    marginTop: 6,
    marginBottom: 6,
    height: 22,
    fontSize: 15,
    fontFamily: "Jost_500Medium",
  },
  bioInput: {
    marginTop: 12,
    marginBottom: 12,
    width: "100%",
    borderRadius: 10,
    paddingVertical: 0,
    paddingHorizontal: 0,
    backgroundColor: "#ffffff",
  },
  bio: {
    textAlign: "auto",
    marginBottom: 9,
    marginTop: 9,
    marginLeft: 8,
    marginRight: 8,
    height: 150,
    fontSize: 16,
    fontFamily: "Jost_400Regular",
  },
  tag: {
    marginBottom: 8,
    width: "100%",
    display: "flex",
    flexDirection: "row",
  },
  socialsIcon: {
    width: 20,
    height: 20,
    marginRight: 12,
  },
  socialsInput: {
    alignItems: "center",
    flex: 1,
  },
  locationPin: {
    marginRight: 16,
    marginTop: 5,
    marginBottom: 5,
  },
  button: {
    flex: 1,
    height: 40,
    width: "100%",
  },
  defaultButtonText: {
    fontSize: 15,
    fontFamily: "Jost_500Medium",
  },
  twitterButtonText: {
    fontSize: 15,
    fontFamily: "Jost_500Medium",
    color: "#3488ec",
  },
  fullWidth: {
    width: "100%",
  },
}));

import { useNavigation } from "@react-navigation/native";
import { Layout, withStyles, EvaProp, Text, Divider } from "@ui-kitten/components";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { View } from "react-native";
import StyledButton from "../components/Button";
import BuildingBlocks from "../components/svgs/BuildingBlocks";
import useListener from "../hooks/useListener";
import useWindowSize from "../hooks/useWindowSize";
import MainLayout from "../layouts/MainLayout";
import showErrorPage from "../utils/showErrorPage";

interface Props {
  eva?: EvaProp;
}

const FundraiserScreen: React.FC<Props> = ({ eva }) => {
  const navigation = useNavigation();
  const { isTablet } = useWindowSize();
  const [key, setKey] = useState(true);

  const onPressRefresh = useListener(() => {
    setKey(!key);
    showErrorPage(navigation);
  });

  return (
    <MainLayout>
      <Layout style={eva?.style?.mainContent}>
        <Layout style={isTablet ? eva?.style?.mainMobile : eva?.style?.mainCard}>
          <View style={eva?.style?.graphicContainer}>
            <BuildingBlocks style={eva?.style?.graphic} />
          </View>
          <Text style={eva?.style?.title}>Something went wrong!</Text>
          <Divider style={eva?.style?.miniDivider} />
          <Text style={eva?.style?.message}>
            But dont worry, we’re currently working on this! Try to reload the page again
          </Text>
          <StyledButton
            type="greenPrimary"
            status="primary"
            onPress={onPressRefresh}
            containerStyle={eva?.style?.refreshButton}
            text="Refresh"
          />
        </Layout>
      </Layout>
    </MainLayout>
  );
};

export default withStyles(observer(FundraiserScreen), () => ({
  mainContent: {
    backgroundColor: "#f8f9f9",
    flex: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  mainCard: {
    flexShrink: 1,
    width: "80%",
    maxWidth: 540,
    paddingHorizontal: 64,
    paddingVertical: 64,
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: 64,
    marginBottom: 64,
    boxShadow: "0px 4px 11px 0px #0000000F",
    border: 0,
    borderRadius: 10,
    overflow: "hidden",
    rowGap: 24,
  },
  mainMobile: {
    flex: 1,
    width: "100%",
    paddingHorizontal: 20,
    paddingVertical: 48,
    rowGap: 24,
  },
  graphicContainer: {
    marginHorizontal: "auto",
  },
  graphic: {
    maxWidth: "100%",
    height: "auto",
  },
  title: {
    fontSize: 24,
    fontFamily: "Jost_500Medium",
    fontWeight: "500",
  },
  miniDivider: {
    backgroundColor: "#35d07f",
    height: 4,
    width: 57,
    radius: 100,
  },
  message: {
    fontFamily: "Jost_400Regular",
    fontWeight: "400",
    fontSize: 16,
    lineHeight: 23,
    color: "#757f87",
  },
  refreshButton: {
    height: 40,
    width: "100%",
    maxWidth: 600,
    marginHorizontal: "auto",
  },
}));

import { useNavigation } from "@react-navigation/native";
import { EvaProp, withStyles } from "@ui-kitten/components";
import React, { useCallback } from "react";
import { StyleSheet, View, ViewProps } from "react-native";
import useWindowSize from "../hooks/useWindowSize";
import Button from "./Button";

interface Props extends ViewProps {
  eva?: EvaProp;
  disableConfirm?: boolean;
  disableCancel?: boolean;
  onCancelPress?: () => void;
  onConfirmPress: () => void;
  cancelText: string;
  confirmText: string;
}

const CancelConfirmButtons: React.FC<Props> = ({
  eva,
  style,
  disableCancel = false,
  disableConfirm = false,
  onCancelPress,
  onConfirmPress,
  cancelText,
  confirmText,
}) => {
  const { isTablet, width } = useWindowSize();
  const navigation = useNavigation();

  const fullWidth = StyleSheet.create({
    stickyButtons: {
      width,
    },
  });

  const handleCancelPress = useCallback(async () => {
    if (navigation.canGoBack()) {
      navigation.goBack();
    } else {
      navigation.navigate("Home");
    }
  }, [navigation]);

  return (
    <View
      style={[
        eva?.style?.buttons,
        isTablet && eva?.style?.stickyButtons,
        isTablet && fullWidth.stickyButtons,
        style,
      ]}
    >
      <Button
        disabled={disableCancel}
        onPress={onCancelPress || handleCancelPress}
        containerStyle={[eva?.style?.back, isTablet && eva?.style?.stickyBack]}
        text={cancelText}
        type="blackGhost"
        removePadding
      />
      <Button
        disabled={disableConfirm}
        status="primary"
        onPress={onConfirmPress}
        containerStyle={[eva?.style?.save, isTablet && eva?.style?.stickySave]}
        text={confirmText}
        type="greenPrimary"
      />
    </View>
  );
};

export default withStyles(CancelConfirmButtons, () => ({
  buttons: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    columnGap: 12,
  },
  stickyButtons: {
    position: "sticky",
    bottom: 0,
    borderTop: "1px solid #EDEEEF",
    backgroundColor: "#FFFFFF",
    padding: 20,
    marginBottom: -25,
    marginLeft: -20,
    marginRight: -20,
    boxShadow: "0px -2px 10px 0px #0000000D",
    display: "flex",
  },
  save: {
    height: 40,
    maxWidth: 240,
    flexGrow: 1,
    flexShrink: 0,
  },
  back: {
    height: 40,
    flexGrow: 0,
    flexShrink: 1,
  },
  stickySave: {
    maxWidth: 400,
  },
  stickyBack: {},
}));

import React, { useEffect, useState } from "react";
import { Pressable, StyleSheet, ViewProps } from "react-native";
import styled from "rn-css";
import { Icon, Text as TextBase } from "@ui-kitten/components";
import { Menu, MenuOption, MenuOptions, MenuTrigger } from "react-native-popup-menu";
import { FlexCol, FlexRow, propFlexRow } from "../styles/containers";
import useWindowSize from "../hooks/useWindowSize";
import useListener from "../hooks/useListener";
import TextWithHighlights from "./TextWithHighlights";

const responsive = <A, B = A>(ifMobile: A, ifDesktop: B) => ({ isMobile }: { isMobile: boolean }) =>
  isMobile ? ifMobile : ifDesktop;

const styles = StyleSheet.create({
  highlights: {
    color: "#3488ec",
  },
});

const filtersDropdownStyles = StyleSheet.create({
  optionsWrapper: {
    backgroundColor: "transparent",
    boxShadow: "unset",
  },
  optionsContainer: {
    backgroundColor: "#transparent",
    boxShadow: "unset",
  },
  optionWrapper: {
    padding: 0,
  },
});

const DropdownWrapper = styled(Pressable)<{ isMobile: boolean }>`
  ${propFlexRow};
  align-items: center;
  justify-content: center;
  gap: 12px;
  padding: 8px 0px;
  border-radius: 999999px;
  transition: all 0.3s ease-in-out;

  &:hover {
    background: rgba(52, 136, 236, 0.2);
    padding: 8px 12px;
  }
`;

const Text = styled(TextBase)`
  font-family: "Jost_400Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  font-feature-settings: "pnum" on, "lnum" on;
  color: #2e3338;
`;

const DropdownIcon = styled(Icon)`
  width: 16px;
  height: 16px;
  fill: #115fbb;
  transform: rotate(-90deg);
`;

const OptionsContainer = styled(FlexCol)`
  width: max-content;
  background: #ffffff;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  overflow: hidden;
`;

const OptionContainer = styled(FlexRow)`
  align-items: center;
  padding: 16px 20px;
  border-left-width: 4px;
  border-left-color: transparent;

  &:hover {
    border-left-color: #3488ec;
    background-color: #eaf3fc;
  }
`;

const OptionText = styled(Text)<{ isMobile: boolean }>`
  font-family: "Jost_500Medium";
  font-weight: 500;
  font-size: ${responsive(14, 15)}px;
  line-height: ${responsive(20, 22)}px;
  color: #2e3338;
`;

const FiltersLabel = styled(Text)<{ isMobile: boolean }>`
  text-transform: capitalize;
  font-family: "Jost_500Medium";
  font-weight: 500;
  font-size: ${responsive(14, 16)}px;
  line-height: ${responsive(20, 23)}px;
  display: flex;
  align-items: center;
  font-feature-settings: "pnum" on, "lnum" on;
  color: #115fbb;
`;

const Container = styled(FlexRow)`
  align-items: center;
  gap: 16px;
`;

export interface CampaignFilter {
  key: string;
  name: string;
  onSelect?: () => void;
}

export interface CampaignFilterBarProps extends ViewProps {
  infoTemplate: string;
  filters?: CampaignFilter[];
}

const CampaignFilterBar: React.FC<CampaignFilterBarProps> = ({
  infoTemplate,
  filters = [],
  ...props
}) => {
  const { isMobile } = useWindowSize();
  const [currentFilter, setCurrentFilter] = useState<CampaignFilter>();
  const [menuOpenFlag, setMenuOpenFlag] = useState(false);
  const filter = currentFilter?.name ?? "";

  const onDropdownPress = useListener(() => {
    setMenuOpenFlag((flag) => !flag);
  });

  const onFilterSelect = useListener((selectedKey: string) => {
    const selected = filters.find(({ key }) => key === selectedKey);
    const success = selected?.onSelect?.() ?? true;

    if (success) {
      setCurrentFilter(selected);
      setMenuOpenFlag(false);
    }
  });

  const onMenuBackdropPress = useListener(() => {
    setMenuOpenFlag(false);
  });

  useEffect(() => {
    setCurrentFilter(filters[0]);
  }, [filters]);

  return (
    <Container {...props}>
      {infoTemplate && (
        <TextWithHighlights customStyles={styles}>{infoTemplate}</TextWithHighlights>
      )}
      <Menu opened={menuOpenFlag} onSelect={onFilterSelect} onBackdropPress={onMenuBackdropPress}>
        <MenuTrigger>
          <DropdownWrapper isMobile={isMobile} onPress={onDropdownPress}>
            <FiltersLabel isMobile={isMobile}>{filter}</FiltersLabel>
            <DropdownIcon name="arrow-ios-back" />
          </DropdownWrapper>
        </MenuTrigger>
        <MenuOptions customStyles={filtersDropdownStyles}>
          <OptionsContainer>
            {filters.map(({ key, name }) => (
              <MenuOption key={key} value={key}>
                <OptionContainer>
                  <OptionText isMobile={isMobile}>{name}</OptionText>
                </OptionContainer>
              </MenuOption>
            ))}
          </OptionsContainer>
        </MenuOptions>
      </Menu>
    </Container>
  );
};

export default CampaignFilterBar;

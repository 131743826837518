import i18next from "i18next";
import ky from "ky";
import * as auth from "./auth";
import * as campaigns from "./campaigns";
import * as categories from "./categories";
import * as users from "./users";
import * as notification from "./notification";
import { config } from "./base";

export interface APIErrorDetail {
  [key: string]: APIErrorDetail | string[];
}

export type APIValidationErrors<T> = Partial<{ [key in keyof T]: string[] }> & {
  nonFieldErrors?: string[];
} & { [key: string]: string[] };

export interface APINestedErrorDetail<T> {
  [key: string]: string[] | APIValidationErrors<T> | APIValidationErrors<T>[];
}

export type APINestedValidationErrors<T> = Partial<
  { [key in keyof T]: string[] | APINestedErrorDetail<T> | APINestedErrorDetail<T>[] }
> & {
  nonFieldErrors?: string[];
} & { [key: string]: string[] | APINestedErrorDetail<T> | APINestedErrorDetail<T>[] };

export type APIErrorCode =
  | "authentication_failed"
  | "auth/auth0_management"
  | "auth/missing_user"
  | "generic/400"
  | "generic/authentication"
  | "generic/deletion"
  | "generic/missing"
  | "generic/network"
  | "generic/permission"
  | "generic/syntax"
  | "generic/throttled"
  | "generic/timeout"
  | "generic/validation"
  | "generic/web"
  | "token_not_valid";

export interface APIErrorResponse<T = APIErrorDetail> {
  code: APIErrorCode;
  description: string;
  detail?: T | null;
  message?: string;
}

async function handleError<T = APIErrorDetail>(
  error: Error | unknown
): Promise<APIErrorResponse<T>> {
  let errorResponse: APIErrorResponse<T>;

  if (error instanceof ky.HTTPError) {
    const data = await error.response.json();

    errorResponse = {
      code: "generic/400",
      description: error.message,
      detail: data,
      message: i18next.t("errors.generic/400"),
    };
  } else if (error instanceof ky.TimeoutError) {
    errorResponse = {
      code: "generic/timeout",
      description: error.message,
      message: i18next.t("errors.generic/timeout"),
    };
  } else if (error instanceof TypeError) {
    errorResponse = {
      code: "generic/network",
      description: error.message,
      message: i18next.t("errors.generic/network"),
    };
  } else if (error instanceof Error) {
    errorResponse = {
      code: "generic/syntax",
      description: error.message,
      message: i18next.t("errors.generic/web"),
    };
  } else {
    errorResponse = {
      code: "generic/syntax",
      description: "An error has occurred",
      message: i18next.t("errors.generic/web"),
    };
  }

  console.log({ errorResponse });
  return errorResponse;
}

export default {
  auth,
  campaigns,
  categories,
  config,
  users,
  notification,
  handleError,
};

import React, { useEffect, useRef, useState } from "react";
import { ImageSourcePropType, Pressable, PressableProps } from "react-native";
import { Text as TextBase } from "@ui-kitten/components";
import styled from "rn-css";
import { propFlexRow } from "../styles/containers";
import PressableIcon from "./PressableIcon";
import useListener from "../hooks/useListener";

const Text = styled(TextBase)<{ active: boolean }>`
  font-family: "Jost_500Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  display: flex;
  align-items: center;
  letter-spacing: 0.02em;
  color: ${({ active }) => (active ? "#3488ec" : "inherit")};
  user-select: none;

  @media (max-width: 768px) {
    font-size: 14px;
    line-height: 20px;
  }
`;

const Icon = styled(PressableIcon)`
  width: 24px;
  height: 24px;
`;

const Container = styled(Pressable)<{ active: boolean }>`
  ${propFlexRow}
  align-items: center;
  padding: 12px 20px;
  gap: 10px;
  background: ${({ active }) => (active ? "rgba(52, 136, 236, 0.2)" : "transparent")};
  color: #abadaf;
  border: 1px solid ${({ active }) => (active ? "#3488ec" : "#abadaf")};
  border-radius: 999999px;
  max-height: fit-content;
  cursor: pointer;

  &:hover {
    color: #3488ec;
    border-color: #3488ec;
  }

  @media (max-width: 768px) {
    padding: 6px 16px;
  }
`;

export interface CategoryLabelProps extends Omit<PressableProps, "onPress"> {
  text: string;
  icon?: ImageSourcePropType;
  active?: boolean;
  onToggle?: () => (() => void) | void;
}

const CategoryLabel: React.FC<CategoryLabelProps> = ({
  text,
  icon,
  active = false,
  onToggle,
  ...props
}) => {
  const [isActive, setIsActive] = useState<boolean>(active);
  const cleanupRef = useRef<(() => void) | void>();

  const onPress = useListener(() => {
    if (isActive) {
      cleanupRef.current?.();
      cleanupRef.current = undefined;
    } else {
      cleanupRef.current = onToggle?.();
    }
    setIsActive(!isActive);
  });

  useEffect(() => {
    setIsActive(active);
  }, [active]);

  return (
    <Container active={active} onPress={onPress} {...props}>
      <Text active={active}>{text}</Text>
      {icon && active && <Icon iconSource={icon} fill="#3488ec" />}
    </Container>
  );
};

export default CategoryLabel;

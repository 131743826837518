import { EvaProp, Input, Text, withStyles } from "@ui-kitten/components";
import React from "react";
import { Control, Controller, DeepMap, FieldError } from "react-hook-form";
import { APICampaignInput } from "../../api/campaigns";

interface Props {
  control: Control;
  disabled?: boolean;
  errors: DeepMap<Record<string, any>, FieldError>;
  eva?: EvaProp;
  state: APICampaignInput;
  showLabels?: boolean;
}

const CampaignStep3Form: React.FC<Props> = ({
  control,
  disabled = false,
  errors,
  eva,
  state,
  showLabels = false,
}) => {
  return (
    <Controller
      name="content"
      control={control}
      defaultValue={state.content || undefined}
      rules={{
        required: "This field is required.",
      }}
      render={({ field: { onChange, onBlur, value } }) => (
        <Input
          textAlignVertical="top"
          caption={errors.content?.message}
          disabled={disabled}
          onBlur={onBlur}
          onChangeText={(next) => onChange(next)}
          multiline
          numberOfLines={10}
          placeholder="Tell your campaign story here..."
          size="large"
          status={errors.content ? "danger" : "basic"}
          style={eva?.style?.input}
          textStyle={eva?.style?.bio}
          value={value || ""}
          label={() => (
            <>{showLabels && <Text style={eva?.style?.labelText}>Campaign Story</Text>}</>
          )}
        />
      )}
    />
  );
};

export default withStyles(CampaignStep3Form, () => ({
  input: {
    marginBottom: 12,
    width: "100%",
    borderRadius: 10,
    paddingVertical: 0,
    paddingHorizontal: 0,
    backgroundColor: "#ffffff",
  },
  bio: {
    textAlign: "auto",
    marginBottom: 9,
    marginTop: 9,
    marginLeft: 8,
    marginRight: 8,
    height: 265,
    fontSize: 16,
    fontFamily: "Jost_400Regular",
  },
  h1: {
    fontFamily: "Jost_500Medium",
    fontWeight: "500",
    fontSize: 22,
    lineHeight: 32,
    marginBottom: 16,
  },
  description: {
    marginBottom: 16,
  },
  labelText: {
    fontFamily: "Jost_400Regular",
    fontWeight: "400",
    fontSize: 14,
    lineHeight: 20,
    color: "#757f87",
    marginBottom: 8,
  },
}));
